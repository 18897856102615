import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './style.styl';

const propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

const PREFIX_CLASS = 'wrapper';

const Wrapper = ({
	className,
	children,
}) => (
	<section className={cx(PREFIX_CLASS, className)}>
		{children}
	</section>
);

Wrapper.propTypes = propTypes;

export default Wrapper;
