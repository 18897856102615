import { Map, List } from 'immutable';
import { LoadingStatusEnum } from '../../../../../lib/enums';
import { actionTypes } from '../../../../controller';

const {
	START_FETCH_VIDEO_CATEGORIES,
	FETCH_VIDEO_CATEGORIES_SUCCESS,
	FETCH_VIDEO_CATEGORIES_FAILED,
} = actionTypes;

const {
	NONE,
	LOADING,
	SUCCESS,
	FAILED,
} = LoadingStatusEnum;

// Example
// Map({
// 	"videoCategories": List([
// 		{
// 			"id": "5b73ede833d3cdc4e42c94f0",
// 			"categoryId": "5b73eb8886d00574944a9dc1",
// 			"name": "美乳",
// 			"order": 1,
// 			"marketingVideoCategoryCodes": [
// 				"submenu",		//首頁列標籤
// 				"selected",		//精選標籤
// 			],
// 		},
// 	]),
// });

const initalState = Map({
	videoCategories: List([]),
	videoCategoriesLoadingStatus: NONE,
	videoCategoriesLoadingStatusMessage: '',
});

export default function videoCategory(state = initalState, action) {
	switch (action.type) {
		case START_FETCH_VIDEO_CATEGORIES:
			return state.set('videoCategoriesLoadingStatus', LOADING);

		case FETCH_VIDEO_CATEGORIES_SUCCESS:
			return state
				.set('videoCategories', List(action.categories))
				.set('videoCategoriesLoadingStatus', SUCCESS);

		case FETCH_VIDEO_CATEGORIES_FAILED:
			return state
				.set('videoCategoriesLoadingStatus', FAILED)
				.set('videoCategoriesLoadingStatusMessage', action.errorMessage);

		default:
			return state;
	}
}
