module.exports = {
	MODE: 'production',
	CLIENT: {
		HOSTNAME: '',
		PORT: 3003,
		API_VERSION: 'v1',
	},
	MANAGEMENT: {
		HOSTNAME: '',
		PORT: 3003,
		API_VERSION: 'v1',
	},
};
