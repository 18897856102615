import useVideoDetail from './video-detail/use-video-detail';
import useVideoTags from './video-tag/use-video-tag';
import useCDNEndpoints from './cdn-endpoint/use-cdn-endpoint';
import useVideoEpisode from './video-episode/use-video-episode';
import useBanners from './banner/use-banner';
import useVideoCategories from './video-category/use-video-category';
import useFavoriteVideos from './user/use-favorite-video';

export {
	useVideoDetail,
	useVideoTags,
	useCDNEndpoints,
	useVideoEpisode,
	useBanners,
	useVideoCategories,
	useFavoriteVideos,
};
