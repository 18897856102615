import actionTypes from './actions/action-types';
import * as notifyActions from './actions/notify-actions';

import * as authActions from './actions/auth-actions';
import * as userActions from './actions/user-actions';
import * as homeActions from './actions/home-actions';
import * as adultLongVideoActions from './actions/adult-long-video-actions';
import * as adultShortVideoActions from './actions/adult-short-video-actions';
import * as comicActions from './actions/comic-actions';
import * as tvActions from './actions/tv-actions';
import * as movieActions from './actions/movie-actions';
import * as videoTagsActions from './actions/video-tag-actions';
import * as areasActions from './actions/area-actions';
import * as videoDetailActions from './actions/video-detail-actions';
import * as cdnEndpointActions from './actions/cdn-endpoint-actions';
import * as videoEpisodeActions from './actions/video-episode-actions';
import * as bannerActions from './actions/banner-actions';
import * as videoCategoryActions from './actions/video-category-actions';
import * as searchActions from './actions/search-actions';

export {
	actionTypes,
	notifyActions,

	authActions,
	userActions,
	homeActions,
	adultLongVideoActions,
	adultShortVideoActions,
	comicActions,
	tvActions,
	movieActions,
	videoTagsActions,
	areasActions,
	videoDetailActions,
	cdnEndpointActions,
	videoEpisodeActions,
	bannerActions,
	videoCategoryActions,
	searchActions,
};
