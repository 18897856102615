import { ofType } from 'redux-observable';
import {
	map,
	switchMap,
	catchError,
} from 'rxjs/operators';

import { actionTypes, homeActions, notifyActions } from '../../../controller';
import { rxjsApiFetcher } from '../../../lib/api-utils';
import { catchErrorForEpics } from '../../../../lib/epic-utils';

const {
	START_FETCH_HOME_LONG_VIDEOS,
	START_FETCH_HOME_SHORT_VIDEOS,
	START_FETCH_HOME_COMICS,
	START_FETCH_HOME_TVS,
	START_FETCH_HOME_MOVIES,
} = actionTypes;
const {
	fetchHomeLongVideosSuccessAction, fetchHomeLongVideosFailedAction,
	fetchHomeShortVideosSuccessAction, fetchHomeShortVideosFailedAction,
	fetchHomeComicsSuccessAction, fetchHomeComicsFailedAction,
	fetchHomeTvsSuccessAction, fetchHomeTvsFailedAction,
	fetchHomeMoviesSuccessAction, fetchHomeMoviesFailedAction,
} = homeActions;
const {
	notifyErrorAction,
} = notifyActions;

export function fetchHomeLongVideosEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_HOME_LONG_VIDEOS),
		switchMap(({ categoryId, limit }) =>
			rxjsApiFetcher
				.get('/videos', { queries: {
					categoryId,
					limit,
				} })
				.pipe(
					map(payload => payload.response),
					map(({ data }) => fetchHomeLongVideosSuccessAction(data)),
					catchError(error => catchErrorForEpics(error, fetchHomeLongVideosFailedAction, notifyErrorAction)),
				),
		),
	);
}

export function fetchHomeShortVideosEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_HOME_SHORT_VIDEOS),
		switchMap(({ categoryId, limit }) =>
			rxjsApiFetcher
				.get('/videos', { queries: {
					categoryId,
					limit,
				} })
				.pipe(
					map(payload => payload.response),
					map(({ data }) => fetchHomeShortVideosSuccessAction(data)),
					catchError(error => catchErrorForEpics(error, fetchHomeShortVideosFailedAction, notifyErrorAction)),
				),
		),
	);
}

export function fetchHomeComicsEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_HOME_COMICS),
		switchMap(({ categoryId, limit }) =>
			rxjsApiFetcher
				.get('/videos', { queries: {
					categoryId,
					limit,
				} })
				.pipe(
					map(payload => payload.response),
					map(({ data }) => fetchHomeComicsSuccessAction(data)),
					catchError(error => catchErrorForEpics(error, fetchHomeComicsFailedAction, notifyErrorAction)),
				),
		),
	);
}

export function fetchHomeTvsEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_HOME_TVS),
		switchMap(({ categoryId, limit }) =>
			rxjsApiFetcher
				.get('/videos', { queries: {
					categoryId,
					limit,
				} })
				.pipe(
					map(payload => payload.response),
					map(({ data }) => fetchHomeTvsSuccessAction(data)),
					catchError(error => catchErrorForEpics(error, fetchHomeTvsFailedAction, notifyErrorAction)),
				),
		),
	);
}

export function fetchHomeMoviesEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_HOME_MOVIES),
		switchMap(({ categoryId, limit }) =>
			rxjsApiFetcher
				.get('/videos', { queries: {
					categoryId,
					limit,
				} })
				.pipe(
					map(payload => payload.response),
					map(({ data }) => fetchHomeMoviesSuccessAction(data)),
					catchError(error => catchErrorForEpics(error, fetchHomeMoviesFailedAction, notifyErrorAction)),
				),
		),
	);
}
