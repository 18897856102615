import { AjaxError, GeneralError } from './notifications/error';
import { Success } from './notifications/success';
import { Info } from './notifications/info';
import { createNotification, NotifyTypeEnum } from './utils';
import { CATCH_NOTIFY_HANDLING } from './notify-handling-actions';

const NOTIFY_DELAY_TIME = 5000;

const notifyHandlingMiddleware = () => next => action => {
	const { notification, type } = action;

	if (type === CATCH_NOTIFY_HANDLING) {
		handleNotify(notification);
	}

	return next(action);
};

export default notifyHandlingMiddleware;

const handleNotify = notification => {
	if (notification instanceof AjaxError) {
		createNotification(NotifyTypeEnum.ERROR, notification.message, NOTIFY_DELAY_TIME);
	}

	if (notification instanceof GeneralError) {
		createNotification(NotifyTypeEnum.ERROR, notification.message, NOTIFY_DELAY_TIME);
	}

	if (notification instanceof Success) {
		createNotification(NotifyTypeEnum.SUCCESS_FILL, notification.message, NOTIFY_DELAY_TIME);
	}

	if (notification instanceof Info) {
		createNotification(NotifyTypeEnum.INFO, notification.message, NOTIFY_DELAY_TIME);
	}
};
