/* eslint-disable import/no-cycle */
import { loadComponent } from '../../lib/react-utils';
import withWatchCountLimit from '../features/with-watch-count-limit';

const Home = loadComponent({ loader: () => import('../pages/home') });
const SearchKeywordPage = loadComponent({ loader: () => import('../pages/home/search/keyword') });
const AdultLongVideo = loadComponent({ loader: () => import('../pages/adult-long-video') });
const AdultLongVideoMainPage = loadComponent({ loader: () => import('../pages/adult-long-video/main') });
const AdultLongVideoPlayPage = loadComponent({ loader: () => import('../pages/adult-long-video/play') });
const AdultLongVideoSearchPage = loadComponent({ loader: () => import('../pages/adult-long-video/search') });
const AdultLongVideoSearchKeywordPage = loadComponent({ loader: () => import('../pages/adult-long-video/search/keyword') });
const AdultLongVideoSearchConditionalTagPage = loadComponent({ loader: () => import('../pages/adult-long-video/search/conditional-tag') });
const AdultLongVideoRecommendPage = loadComponent({ loader: () => import('../pages/adult-long-video/recommend') });
const AdultShortVideo = loadComponent({ loader: () => import('../pages/adult-short-video') });
const AdultShortVideoMainPage = loadComponent({ loader: () => import('../pages/adult-short-video/main') });
const AdultShortVideoPlayPage = loadComponent({ loader: () => import('../pages/adult-short-video/play') });
const AdultShortVideoSearchPage = loadComponent({ loader: () => import('../pages/adult-short-video/search') });
const AdultShortVideoSearchKeywordPage = loadComponent({ loader: () => import('../pages/adult-short-video/search/keyword') });
const AdultShortVideoSearchConditionalTagPage = loadComponent({ loader: () => import('../pages/adult-short-video/search/conditional-tag') });
const AdultShortVideoRecommendPage = loadComponent({ loader: () => import('../pages/adult-short-video/recommend') });
const Comic = loadComponent({ loader: () => import('../pages/comic') });
const ComicMainPage = loadComponent({ loader: () => import('../pages/comic/main') });
const ComicSearchPage = loadComponent({ loader: () => import('../pages/comic/search') });
const ComicSearchKeywordPage = loadComponent({ loader: () => import('../pages/comic/search/keyword') });
const ComicSearchConditionalTagPage = loadComponent({ loader: () => import('../pages/comic/search/conditional-tag') });
const ComicContentsPage = loadComponent({ loader: () => import('../pages/comic/contents') });
const ComicReadPage = loadComponent({ loader: () => import('../pages/comic/read') });
const Tv = loadComponent({ loader: () => import('../pages/tv') });
const TvMainPage = loadComponent({ loader: () => import('../pages/tv/main') });
const TvPlayPage = loadComponent({ loader: () => import('../pages/tv/play') });
const TvSearchPage = loadComponent({ loader: () => import('../pages/tv/search') });
const TvSearchKeywordPage = loadComponent({ loader: () => import('../pages/tv/search/keyword') });
const TvSearchConditionalTagPage = loadComponent({ loader: () => import('../pages/tv/search/conditional-tag') });
const TvRecommendPage = loadComponent({ loader: () => import('../pages/tv/recommend') });
const Movie = loadComponent({ loader: () => import('../pages/movie') });
const MovieMainPage = loadComponent({ loader: () => import('../pages/movie/main') });
const MoviePlayPage = loadComponent({ loader: () => import('../pages/movie/play') });
const MovieSearchPage = loadComponent({ loader: () => import('../pages/movie/search') });
const MovieSearchKeywordPage = loadComponent({ loader: () => import('../pages/movie/search/keyword') });
const MovieSearchConditionalTagPage = loadComponent({ loader: () => import('../pages/movie/search/conditional-tag') });
const MovieRecommendPage = loadComponent({ loader: () => import('../pages/movie/recommend') });
const UserPage = loadComponent({ loader: () => import('../pages/user') });
const UserFavoritePage = loadComponent({ loader: () => import('../pages/user/favorite') });
const UserHistoryPage = loadComponent({ loader: () => import('../pages/user/history') });

const RouteKeyEnums = {
	ROOT: '/',

	LONG_VIDEO: '/adult-long-video',
	LONG_VIDEO_PLAY: '/adult-long-video/play',
	LONG_VIDEO_SEARCH: '/adult-long-video/search',
	LONG_VIDEO_RECOMMEND: '/adult-long-video/recommend',
	SHORT_VIDEO: '/adult-short-video',
	SHORT_VIDEO_PLAY: '/adult-short-video/play',
	SHORT_VIDEO_SEARCH: '/adult-short-video/search',
	SHORT_VIDEO_RECOMMEND: '/adult-short-video/recommend',
	MOVIE: '/movie',
	MOVIE_PLAY: '/movie/play',
	MOVIE_SEARCH: '/movie/search',
	MOVIE_RECOMMEND: '/movie/recommend',
	TV: '/tv',
	TV_PLAY: '/tv/play',
	TV_SEARCH: '/tv/search',
	TV_RECOMMEND: '/tv/recommend',
	COMIC: '/comic',
	COMIC_SEARCH: '/comic/search',
	COMIC_CONTENTS: '/comic/contents',
	COMIC_READ: '/comic/read',

	EPISODE: '/episode',
	FAVORITE: '/user/favorite',
	HISTORY: '/user/history',
	SEARCH: '/search',
};

const {
	ROOT,
	LONG_VIDEO,
	LONG_VIDEO_PLAY,
	LONG_VIDEO_SEARCH,
	LONG_VIDEO_RECOMMEND,
	SHORT_VIDEO,
	SHORT_VIDEO_PLAY,
	SHORT_VIDEO_SEARCH,
	SHORT_VIDEO_RECOMMEND,
	MOVIE,
	MOVIE_PLAY,
	MOVIE_SEARCH,
	MOVIE_RECOMMEND,
	TV,
	TV_PLAY,
	TV_SEARCH,
	TV_RECOMMEND,
	COMIC,
	COMIC_SEARCH,
	COMIC_CONTENTS,
	COMIC_READ,
	SEARCH,
	FAVORITE,
	HISTORY,
} = RouteKeyEnums;

const routes = [
	{
		path: ROOT,
		component: Home,
		exact: true,
	},
	{
		path: SEARCH,
		component: SearchKeywordPage,
	},
	{
		path: LONG_VIDEO,
		component: AdultLongVideo,
		routes: [
			{
				path: LONG_VIDEO,
				component: AdultLongVideoMainPage,
				exact: true,
			},
			{
				path: `${LONG_VIDEO_PLAY}/:videoId`,
				component: withWatchCountLimit(AdultLongVideoPlayPage),
				exact: true,
				paramProps: {
					videoId: ':videoId',
				},
			},
			{
				path: LONG_VIDEO_SEARCH,
				component: AdultLongVideoSearchPage,
				routes: [
					{
						path: LONG_VIDEO_SEARCH,
						component: AdultLongVideoSearchKeywordPage,
						exact: true,
					},
					{
						path: `${LONG_VIDEO_SEARCH}/:sort`,
						component: AdultLongVideoSearchConditionalTagPage,
						exact: true,
						paramProps: {
							sort: ':sort',
						},
					},
				],
			},
			{
				path: `${LONG_VIDEO_RECOMMEND}/:videoId`,
				component: AdultLongVideoRecommendPage,
				exact: true,
				paramProps: {
					videoId: ':videoId',
				},
			},
		],
	},
	{
		path: SHORT_VIDEO,
		component: AdultShortVideo,
		routes: [
			{
				path: SHORT_VIDEO,
				component: AdultShortVideoMainPage,
				exact: true,
			},
			{
				path: `${SHORT_VIDEO_PLAY}/:videoId`,
				component: withWatchCountLimit(AdultShortVideoPlayPage),
				exact: true,
				paramProps: {
					videoId: ':videoId',
				},
			},
			{
				path: SHORT_VIDEO_SEARCH,
				component: AdultShortVideoSearchPage,
				routes: [
					{
						path: SHORT_VIDEO_SEARCH,
						component: AdultShortVideoSearchKeywordPage,
						exact: true,
					},
					{
						path: `${SHORT_VIDEO_SEARCH}/:sort`,
						component: AdultShortVideoSearchConditionalTagPage,
						exact: true,
						paramProps: {
							sort: ':sort',
						},
					},
				],
			},
			{
				path: `${SHORT_VIDEO_RECOMMEND}/:videoId`,
				component: AdultShortVideoRecommendPage,
				exact: true,
				paramProps: {
					videoId: ':videoId',
				},
			},
		],
	},
	{
		path: COMIC,
		component: Comic,
		routes: [
			{
				path: COMIC,
				component: ComicMainPage,
				exact: true,
			},
			{
				path: COMIC_SEARCH,
				component: ComicSearchPage,
				routes: [
					{
						path: COMIC_SEARCH,
						component: ComicSearchKeywordPage,
						exact: true,
					},
					{
						path: `${COMIC_SEARCH}/:sort`,
						component: ComicSearchConditionalTagPage,
						exact: true,
						paramProps: {
							sort: ':sort',
						},
					},
				],
			},
			{
				path: `${COMIC_CONTENTS}/:comicId`,
				component: ComicContentsPage,
				exact: true,
				paramProps: {
					comicId: ':comicId',
				},
			},
			{
				path: `${COMIC_READ}/:comicId/:episodeId`,
				component: withWatchCountLimit(ComicReadPage),
				exact: true,
				paramProps: {
					comicId: ':comicId',
					episodeId: ':episodeId',
				},
			},
		],
	},
	{
		path: TV,
		component: Tv,
		routes: [
			{
				path: TV,
				component: TvMainPage,
				exact: true,
			},
			{
				path: `${TV_PLAY}/:videoId/:episodeId`,
				component: withWatchCountLimit(TvPlayPage),
				exact: true,
				paramProps: {
					videoId: ':videoId',
					episodeId: ':episodeId',
				},
			},
			{
				path: TV_SEARCH,
				component: TvSearchPage,
				routes: [
					{
						path: TV_SEARCH,
						component: TvSearchKeywordPage,
						exact: true,
					},
					{
						path: `${TV_SEARCH}/:sort`,
						component: TvSearchConditionalTagPage,
						exact: true,
						paramProps: {
							sort: ':sort',
						},
					},
				],
			},
			{
				path: `${TV_RECOMMEND}/:videoId`,
				component: TvRecommendPage,
				exact: true,
				paramProps: {
					videoId: ':videoId',
				},
			},
		],
	},
	{
		path: MOVIE,
		component: Movie,
		routes: [
			{
				path: MOVIE,
				component: MovieMainPage,
				exact: true,
			},
			{
				path: MOVIE_SEARCH,
				component: MovieSearchPage,
				routes: [
					{
						path: MOVIE_SEARCH,
						component: MovieSearchKeywordPage,
						exact: true,
					},
					{
						path: `${MOVIE_SEARCH}/:sort`,
						component: MovieSearchConditionalTagPage,
						exact: true,
						paramProps: {
							sort: ':sort',
						},
					},
				],
			},
			{
				path: `${MOVIE_PLAY}/:videoId`,
				component: withWatchCountLimit(MoviePlayPage),
				exact: true,
				paramProps: {
					videoId: ':videoId',
				},
			},
			{
				path: `${MOVIE_RECOMMEND}/:videoId`,
				component: MovieRecommendPage,
				exact: true,
				paramProps: {
					videoId: ':videoId',
				},
			},
		],
	},
	{
		path: '/user',
		component: UserPage,
		routes: [
			{
				path: FAVORITE,
				component: UserFavoritePage,
				exact: true,
			},
			{
				path: HISTORY,
				component: UserHistoryPage,
				exact: true,
			},
		],
	},
];

export default routes;
export { RouteKeyEnums };
