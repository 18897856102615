import actionTypes from './action-types';

const {
	START_FETCH_VIDEOS_SEARCHED,
	FETCH_VIDEOS_SEARCHED_SUCCESS,
	FETCH_VIDEOS_SEARCHED_FAILED,
} = actionTypes;

export function fetchVideosSearchedAction(categoryIds, queries) {
	return {
		type: START_FETCH_VIDEOS_SEARCHED,
		categoryIds,
		queries,
	};
}

export function fetchVideosSearchedSuccessAction(videos, numOfItems) {
	return {
		type: FETCH_VIDEOS_SEARCHED_SUCCESS,
		videos,
		numOfItems,
	};
}

export function fetchVideosSearchedFailedAction(error, errorMessage) {
	return {
		type: FETCH_VIDEOS_SEARCHED_FAILED,
		error,
		errorMessage,
	};
}
