import { Map, List } from 'immutable';
import { LoadingStatusEnum } from '../../../../../lib/enums';
import { actionTypes } from '../../../../controller';

const {
	START_FETCH_AREAS,
	FETCH_AREAS_SUCCESS,
	FETCH_AREAS_FAILED,
} = actionTypes;

const {
	NONE,
	LOADING,
	SUCCESS,
	FAILED,
} = LoadingStatusEnum;

// Example
// Map({
// 	"areas": List([
// 		{
// 			"id": "5f5b360ef499e6227a05ca41",
// 			"name": "大陆",
// 		},
// 		{
// 			"id": "5f5b360ef499e6227a05ca42",
// 			"name": "香港",
// 		},
// 		{
// 			"id": "5f5b360ef499e6227a05ca45",
// 			"name": "日本",
// 		},
// 		{
// 			"id": "5f5b360ef499e6227a05ca46",
// 			"name": "欧美",
// 		},
// 	]),
// });

const initalState = Map({
	areas: List([]),
	areasLoadingStatus: NONE,
	areasLoadingStatusMessage: '',
});

export default function area(state = initalState, action) {
	switch (action.type) {
		case START_FETCH_AREAS:
			return state.set('areasLoadingStatus', LOADING);

		case FETCH_AREAS_SUCCESS:
			return state
				.set('areas', List(action.areas))
				.set('areasLoadingStatus', SUCCESS);

		case FETCH_AREAS_FAILED:
			return state
				.set('areasLoadingStatus', FAILED)
				.set('areasLoadingStatusMessage', action.errorMessage);

		default:
			return state;
	}
}
