import { ofType } from 'redux-observable';
import {
	map,
	switchMap,
	catchError,
} from 'rxjs/operators';

import { actionTypes, videoDetailActions, notifyActions } from '../../../controller';
import { rxjsApiFetcher } from '../../../lib/api-utils';
import { catchErrorForEpics } from '../../../../lib/epic-utils';

const {
	START_FETCH_VIDEO_DETAIL,
} = actionTypes;
const {
	fetchVideoDetailSuccessAction, fetchVideoDetailFailedAction,
} = videoDetailActions;
const {
	notifyErrorAction,
} = notifyActions;

// eslint-disable-next-line import/prefer-default-export
export function fetchVideoDetailEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_VIDEO_DETAIL),
		switchMap(({ videoId }) =>
			rxjsApiFetcher
				.get(`/videos/id=${videoId}`)
				.pipe(
					map(payload => fetchVideoDetailSuccessAction(payload.response)),
					catchError(error => catchErrorForEpics(error, fetchVideoDetailFailedAction, notifyErrorAction)),
				),
		),
	);
}
