class Info {
	constructor(message) {
		this.message = message;
		this.name = 'Info';
	}
}

module.exports = {
	Info,
};
