import { combineReducers } from 'redux';
import { actionTypes } from '../../../controller';

import auth from './singles/auth';
import user from './user';
import home from './home';
import adultLongVideo from './adult-long-video';
import adultShortVideo from './adult-short-video';
import comic from './comic';
import tv from './tv';
import movie from './movie';
import videoTag from './singles/video-tag';
import area from './singles/area';
import videoDetail from './singles/video-detail';
import cdnEndpoint from './singles/cdn-endpoint';
import videoEpisode from './singles/video-episode';
import banner from './singles/banner';
import videoCategory from './singles/video-category';
import search from './search';

const { START_LOGOUT } = actionTypes;
const reducer = combineReducers({
	auth,
	user,
	home,
	adultLongVideo,
	adultShortVideo,
	comic,
	tv,
	movie,
	videoTag,
	area,
	videoDetail,
	cdnEndpoint,
	videoEpisode,
	banner,
	videoCategory,
	search,
});

const appReducer = (state, action) => {
	if (action.type === START_LOGOUT) {
		state = undefined;
	}

	return reducer(state, action);
};

export default appReducer;
