import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Loading } from 'ljit-react-components';
import { connect } from 'ljit-store-connecter';
import { LoadingStatusEnum } from '../../lib/enums';
import { initApiFetcher } from '../lib/api-utils';
import { authActions, userActions } from '../controller';
import { getJWT } from '../lib/jwt';

const {
	NONE,
	LOADING,
	SUCCESS,
} = LoadingStatusEnum;
const {
	checkAuthAction,
	checkAuthSuccessAction,
} = authActions;
const { fetchMeAction } = userActions;

const propTypes = {
	location: PropTypes.shape({
		pathname: PropTypes.string.isRequired,
		search: PropTypes.string,
	}).isRequired,
	render: PropTypes.func.isRequired,
	token: PropTypes.string.isRequired,
	loadingStatus: PropTypes.oneOf(Object.values(LoadingStatusEnum)).isRequired,
	meLoadingStatus: PropTypes.oneOf(Object.values(LoadingStatusEnum)).isRequired,
	checkAuthAction: PropTypes.func.isRequired,
	checkAuthSuccessAction: PropTypes.func.isRequired,
	fetchMeAction: PropTypes.func.isRequired,
};

const AuthRoute = ({
	location,
	render,
	token,
	loadingStatus,
	meLoadingStatus,
	checkAuthAction,
	checkAuthSuccessAction,
	fetchMeAction,
}) => {
	const { search } = location;
	const isAuthed = loadingStatus === SUCCESS;
	const hasMe = meLoadingStatus === SUCCESS;
	const isAuthChecking
		= loadingStatus === NONE
		|| loadingStatus === LOADING
		|| meLoadingStatus === NONE
		|| meLoadingStatus === LOADING;

	useEffect(() => {
		if (search) {
			const searchParams = new URLSearchParams(search);
			const searchParamsObj = {
				userProfile: searchParams.get('u'),
				username: searchParams.get('n'),
				signature: searchParams.get('s'),
			};

			checkAuthAction(searchParamsObj);
		} else {
			const jwt = getJWT();

			if (jwt) {
				checkAuthSuccessAction(jwt);
			}
		}
	}, []);

	useEffect(() => {
		if (token) {
			initApiFetcher(token);
			fetchMeAction();
		}
	}, [token]);

	if (isAuthChecking) {
		return <Loading/>;
	}

	return hasMe && render({ isAuthed });
};

AuthRoute.propTypes = propTypes;

function mapStateToProps(state) {
	return {
		token: state.auth.get('token'),
		loadingStatus: state.auth.get('loadingStatus'),
		meLoadingStatus: state.user.get('meLoadingStatus'),
	};
}

function mapDispatchToProps(dispatch) {
	return {
		checkAuthAction: (...args) => dispatch(checkAuthAction(...args)),
		checkAuthSuccessAction: (...args) => dispatch(checkAuthSuccessAction(...args)),
		fetchMeAction: () => dispatch(fetchMeAction()),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AuthRoute));
