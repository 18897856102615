import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { videoEpisodeActions } from '../../../controller';

const { fetchVideoEpisodeAction, clearVideoEpisodeAction } = videoEpisodeActions;

const useVideoEpisode = (videoId, episodeId) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchVideoEpisodeAction(videoId, episodeId));

		return () => dispatch(clearVideoEpisodeAction());
	}, [videoId, episodeId]);

	const videoEpisodeReducer = useSelector(state => state.videoEpisode);
	const videoEpisode = videoEpisodeReducer.get('videoEpisode').toJS();

	return videoEpisode;
};

export default useVideoEpisode;
