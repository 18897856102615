import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { FavoriteSvg } from '../../../assets/icons';

import './style.styl';

const propTypes = {
	className: PropTypes.string,
	text: PropTypes.string,
	isActive: PropTypes.bool,
	onClick: PropTypes.func,
};

const PREFIX_CLASS = 'favorite-button';

const FavoriteButton = ({
	className,
	text,
	isActive,
	onClick,
}) => (
	<button
		type="button"
		className={cx(PREFIX_CLASS, className, {
			[`${PREFIX_CLASS}--active`]: isActive,
		})}
		onClick={onClick}
	>
		<FavoriteSvg/>
		{text && <span className={`${PREFIX_CLASS}__text`}>{isActive ? '已收藏' : text}</span>}
	</button>
);

FavoriteButton.propTypes = propTypes;

export default FavoriteButton;
