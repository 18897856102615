const TOKEN_KEY = 'video_hub_jwt_token';

const getJWT = () => {
	try {
		return JSON.parse(localStorage.getItem(TOKEN_KEY));
	} catch (error) {
		console.log(error);
		return undefined;
	}
};

const setJWT = value => {
	try {
		return localStorage.setItem(TOKEN_KEY, JSON.stringify(value));
	} catch (error) {
		console.log(error);
		return undefined;
	}
};

export {
	getJWT,
	setJWT,
};
