import { Map, List } from 'immutable';
import { LoadingStatusEnum } from '../../../../lib/enums';
import { actionTypes } from '../../../controller';

const {
	START_FETCH_HOME_LONG_VIDEOS,
	FETCH_HOME_LONG_VIDEOS_SUCCESS,
	FETCH_HOME_LONG_VIDEOS_FAILED,

	START_FETCH_HOME_SHORT_VIDEOS,
	FETCH_HOME_SHORT_VIDEOS_SUCCESS,
	FETCH_HOME_SHORT_VIDEOS_FAILED,

	START_FETCH_HOME_COMICS,
	FETCH_HOME_COMICS_SUCCESS,
	FETCH_HOME_COMICS_FAILED,

	START_FETCH_HOME_TVS,
	FETCH_HOME_TVS_SUCCESS,
	FETCH_HOME_TVS_FAILED,

	START_FETCH_HOME_MOVIES,
	FETCH_HOME_MOVIES_SUCCESS,
	FETCH_HOME_MOVIES_FAILED,
} = actionTypes;

const {
	NONE,
	LOADING,
	SUCCESS,
	FAILED,
} = LoadingStatusEnum;

// Example
// Map({
// 	"longVideos": List([
// 		{
// 			"id": "5b73f6c133d3cdc4e42c94f0",
// 			"categoryId": "5b73eb8886d00574944a9dc1",
// 			"name": "美人妻が通贩で买った精力剤を饮んだら脳みそとろけるようなSEXで快楽トリップした话",
// 			"actorNames": ["森沢かな（饭冈かなこ）"],
// 			"coverImage1": "http://cdn3.aiz2t.cn/AV/A65773/A65773-A.jpg?xxxx"
// 		},
// 	]),
// 	"comics": List([
// 		{
// 			"id": "5b73f6c133d3cdc4e42c94fb",
// 			"categoryId": "5b73eb8886d00574944a9dc5",
// 			"name": "航海王", //片名
// 			"actorNames": ["魯夫"], //主演
// 			"coverImage1" : "http://cdn3.aiz2t.cn/develop/comic/航海王/航海王.png?xxx", //封面路徑
// 			"finishStatus": "finish",
// 			//(漫画/电视剧)集数资讯
// 			"videoEpisodeOrder": [
// 				"5c0e28661ee1f01bfb794ac2","5c0e28661ee1f01bfb794ac3",
// 			],
// 		},
// 	]),
// });

const initalState = Map({
	longVideos: List([]),
	longVideosLoadingStatus: NONE,
	longVideosLoadingStatusMessage: '',

	shortVideos: List([]),
	shortVideosLoadingStatus: NONE,
	shortVideosLoadingStatusMessage: '',

	comics: List([]),
	comicsLoadingStatus: NONE,
	comicsLoadingStatusMessage: '',

	tvs: List([]),
	tvsLoadingStatus: NONE,
	tvsLoadingStatusMessage: '',

	movies: List([]),
	moviesLoadingStatus: NONE,
	moviesLoadingStatusMessage: '',

});

export default function home(state = initalState, action) {
	switch (action.type) {
		// Long videos
		case START_FETCH_HOME_LONG_VIDEOS:
			return state.set('longVideosLoadingStatus', LOADING);

		case FETCH_HOME_LONG_VIDEOS_SUCCESS:
			return state
				.set('longVideos', List(action.videos))
				.set('longVideosLoadingStatus', SUCCESS);

		case FETCH_HOME_LONG_VIDEOS_FAILED:
			return state
				.set('longVideosLoadingStatus', FAILED)
				.set('longVideosLoadingStatusMessage', action.errorMessage);

		// Short video
		case START_FETCH_HOME_SHORT_VIDEOS:
			return state.set('shortVideosLoadingStatus', LOADING);

		case FETCH_HOME_SHORT_VIDEOS_SUCCESS:
			return state
				.set('shortVideos', List(action.videos))
				.set('shortVideosLoadingStatus', SUCCESS);

		case FETCH_HOME_SHORT_VIDEOS_FAILED:
			return state
				.set('shortVideosLoadingStatus', FAILED)
				.set('shortVideosLoadingStatusMessage', action.errorMessage);

		// Comic
		case START_FETCH_HOME_COMICS:
			return state.set('comicsLoadingStatus', LOADING);

		case FETCH_HOME_COMICS_SUCCESS:
			return state
				.set('comics', List(action.comics))
				.set('comicsLoadingStatus', SUCCESS);

		case FETCH_HOME_COMICS_FAILED:
			return state
				.set('comicsLoadingStatus', FAILED)
				.set('comicsLoadingStatusMessage', action.errorMessage);

		// TV
		case START_FETCH_HOME_TVS:
			return state.set('tvsLoadingStatus', LOADING);

		case FETCH_HOME_TVS_SUCCESS:
			return state
				.set('tvs', List(action.tvs))
				.set('tvsLoadingStatus', SUCCESS);

		case FETCH_HOME_TVS_FAILED:
			return state
				.set('tvsLoadingStatus', FAILED)
				.set('tvsLoadingStatusMessage', action.errorMessage);

		// Movie
		case START_FETCH_HOME_MOVIES:
			return state.set('moviesLoadingStatus', LOADING);

		case FETCH_HOME_MOVIES_SUCCESS:
			return state
				.set('movies', List(action.movies))
				.set('moviesLoadingStatus', SUCCESS);

		case FETCH_HOME_MOVIES_FAILED:
			return state
				.set('moviesLoadingStatus', FAILED)
				.set('moviesLoadingStatusMessage', action.errorMessage);

		default:
			return state;
	}
}
