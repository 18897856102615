import { ofType } from 'redux-observable';
import {
	map,
	switchMap,
	catchError,
} from 'rxjs/operators';

import { actionTypes, adultShortVideoActions, notifyActions } from '../../../controller';
import { rxjsApiFetcher } from '../../../lib/api-utils';
import { catchErrorForEpics } from '../../../../lib/epic-utils';
import { objectFilter, objectFilterOptionEnums } from '../../../../lib/object-utils';

const { SKIP_EMPTY_STRING } = objectFilterOptionEnums;

const {
	START_FETCH_ADULT_SHORT_VIDEOS_RECENTLY,
	START_FETCH_HOT_ADULT_SHORT_VIDEOS,
	START_FETCH_FAVORITE_ADULT_SHORT_VIDEOS,
	START_FETCH_ADULT_SHORT_VIDEOS_SEARCHED,
	START_FETCH_ADULT_SHORT_VIDEOS_RECOMMENDED,
} = actionTypes;
const {
	fetchAdultShortVideosRecentlySuccessAction, fetchAdultShortVideosRecentlyFailedAction,
	fetchHotAdultShortVideosSuccessAction, fetchHotAdultShortVideosFailedAction,
	fetchFavoriteAdultShortVideosSuccessAction, fetchFavoriteAdultShortVideosFailedAction,
	fetchAdultShortVideosSearchedSuccessAction, fetchAdultShortVideosSearchedFailedAction,
	fetchAdultShortVideosRecommendedSuccessAction, fetchAdultShortVideosRecommendedFailedAction,
} = adultShortVideoActions;
const {
	notifyErrorAction,
} = notifyActions;

export function fetchAdultShortVideosRecentlyEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_ADULT_SHORT_VIDEOS_RECENTLY),
		switchMap(({ categoryId, limit }) =>
			rxjsApiFetcher
				.get('/videos', { queries: {
					categoryId,
					limit,
				} })
				.pipe(
					map(payload => payload.response),
					map(({ data }) => fetchAdultShortVideosRecentlySuccessAction(data)),
					catchError(error => catchErrorForEpics(error, fetchAdultShortVideosRecentlyFailedAction, notifyErrorAction)),
				),
		),
	);
}

export function fetchHotAdultShortVideosEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_HOT_ADULT_SHORT_VIDEOS),
		switchMap(({ categoryId, sort, limit }) =>
			rxjsApiFetcher
				.get('/videos', { queries: {
					categoryId,
					sort,
					limit,
				} })
				.pipe(
					map(payload => payload.response),
					map(({ data }) => fetchHotAdultShortVideosSuccessAction(data)),
					catchError(error => catchErrorForEpics(error, fetchHotAdultShortVideosFailedAction, notifyErrorAction)),
				),
		),
	);
}

export function fetchFavoriteAdultShortVideosEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_FAVORITE_ADULT_SHORT_VIDEOS),
		switchMap(({ categoryId }) =>
			rxjsApiFetcher
				.get('/users/id=me/favorite-videos', { queries: {
					details: 1,
					categoryId,
				} })
				.pipe(
					map(payload => fetchFavoriteAdultShortVideosSuccessAction(payload.response)),
					catchError(error => catchErrorForEpics(error, fetchFavoriteAdultShortVideosFailedAction, notifyErrorAction)),
				),
		),
	);
}

export function fetchAdultShortVideosSearchedEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_ADULT_SHORT_VIDEOS_SEARCHED),
		switchMap(({ categoryId, query, limit }) => {
			const { sort, tagId, page } = query;

			return rxjsApiFetcher
				.get('/videos', {
					queries: objectFilter(
						{
							categoryId,
							sort,
							tagId,
							page,
							limit,
						},
						[SKIP_EMPTY_STRING],
					),
				})
				.pipe(
					map(payload => payload.response),
					map(({ data, numOfItems }) => fetchAdultShortVideosSearchedSuccessAction(data, numOfItems)),
					catchError(error => catchErrorForEpics(error, fetchAdultShortVideosSearchedFailedAction, notifyErrorAction)),
				);
		}),
	);
}

export function fetchAdultShortVideosRecommendedEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_ADULT_SHORT_VIDEOS_RECOMMENDED),
		switchMap(({ videoId, limit, page }) =>
			rxjsApiFetcher
				.get(`/videos/id=${videoId}/recommends`, { queries: {
					limit,
					page,
				} })
				.pipe(
					map(payload => payload.response),
					map(({ data, numOfItems }) => fetchAdultShortVideosRecommendedSuccessAction(data, numOfItems)),
					catchError(error => catchErrorForEpics(error, fetchAdultShortVideosRecommendedFailedAction, notifyErrorAction)),
				)),
	);
}
