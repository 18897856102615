const unAuthStatusCodeList = [401, 500];

function checkIsStatusGone(error) {
	return unAuthStatusCodeList.includes(error.status);
}

export function getMessageFromResponse(payload, defaultMessage = '系统发生错误，请稍后再试．') {
	try {
		return payload.response.message;
	} catch (e) {
		console.log(e);
	}

	return defaultMessage;
}

export function catchErrorForEpics(error, ...callbacks) {
	if (checkIsStatusGone(error)) {
		if (process.env.MODE !== 'local') {
			window.location.reload();
		}

		return;
	}

	return [
		...catchErrorMessageForEpics(error, ...callbacks),
	];
}

export function catchErrorMessageForEpics(error, ...callbacks) {
	const errorMessage = getMessageFromResponse(error, '系统发生错误，请稍后再试．');

	return callbacks.map(callback => callback(error, errorMessage));
}
