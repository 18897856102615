const config = require('../../config/default.fe');

module.exports = {
	get() {
		return config.CLIENT;
	},
	getMode() {
		return config.MODE;
	},
};
