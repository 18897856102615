import { Map, List } from 'immutable';
import { LoadingStatusEnum } from '../../../../lib/enums';
import { actionTypes } from '../../../controller';

const {
	START_FETCH_COMICS_RECENTLY,
	FETCH_COMICS_RECENTLY_SUCCESS,
	FETCH_COMICS_RECENTLY_FAILED,

	START_FETCH_HOT_COMICS,
	FETCH_HOT_COMICS_SUCCESS,
	FETCH_HOT_COMICS_FAILED,

	START_FETCH_FAVORITE_COMICS,
	FETCH_FAVORITE_COMICS_SUCCESS,
	FETCH_FAVORITE_COMICS_FAILED,

	START_FETCH_COMICS_SEARCHED,
	FETCH_COMICS_SEARCHED_SUCCESS,
	FETCH_COMICS_SEARCHED_FAILED,
} = actionTypes;

const {
	NONE,
	LOADING,
	SUCCESS,
	FAILED,
} = LoadingStatusEnum;

// Example
// Map({
// 	"recentComics": List([
// 		{
// 			"id": "5b73f6c133d3cdc4e42c94fb",
// 			"categoryId": "5b73eb8886d00574944a9dc5",
// 			"name": "航海王", //片名
// 			"actorNames": ["魯夫"], //主演
// 			"coverImage1" : "http://cdn3.aiz2t.cn/develop/comic/航海王/航海王.png?xxx", //封面路徑
// 			"finishStatus": "finish",
// 			//(漫画/电视剧)集数资讯
// 			"videoEpisodeOrder": [
// 				"5c0e28661ee1f01bfb794ac2","5c0e28661ee1f01bfb794ac3",
// 			],
// 		},
// 	]),
// });

const initalState = Map({
	recentComics: List([]),
	recentComicsLoadingStatus: NONE,
	recentComicsLoadingStatusMessage: '',

	hotComics: List([]),
	hotComicsLoadingStatus: NONE,
	hotComicsLoadingStatusMessage: '',

	favoriteComics: List([]),
	favoriteComicsLoadingStatus: NONE,
	favoriteComicsLoadingStatusMessage: '',

	searchedComics: List([]),
	searchedComicsLoadingStatus: NONE,
	searchedComicsLoadingStatusMessage: '',
	numOfItems: 0,
});

export default function comic(state = initalState, action) {
	switch (action.type) {
		// Recent
		case START_FETCH_COMICS_RECENTLY:
			return state.set('recentComicsLoadingStatus', LOADING);

		case FETCH_COMICS_RECENTLY_SUCCESS:
			return state
				.set('recentComics', List(action.comics))
				.set('recentComicsLoadingStatus', SUCCESS);

		case FETCH_COMICS_RECENTLY_FAILED:
			return state
				.set('recentComicsLoadingStatus', FAILED)
				.set('recentComicsLoadingStatusMessage', action.errorMessage);

		// Hot
		case START_FETCH_HOT_COMICS:
			return state.set('hotComicsLoadingStatus', LOADING);

		case FETCH_HOT_COMICS_SUCCESS:
			return state
				.set('hotComics', List(action.comics))
				.set('hotComicsLoadingStatus', SUCCESS);

		case FETCH_HOT_COMICS_FAILED:
			return state
				.set('hotComicsLoadingStatus', FAILED)
				.set('hotComicsLoadingStatusMessage', action.errorMessage);

		//	Favorite
		case START_FETCH_FAVORITE_COMICS:
			return state.set('favoriteComicsLoadingStatus', LOADING);

		case FETCH_FAVORITE_COMICS_SUCCESS:
			return state
				.set('favoriteComics', List(action.comics))
				.set('favoriteComicsLoadingStatus', SUCCESS);

		case FETCH_FAVORITE_COMICS_FAILED:
			return state
				.set('favoriteComicsLoadingStatus', FAILED)
				.set('favoriteComicsLoadingStatusMessage', action.errorMessage);

		//	Searched
		case START_FETCH_COMICS_SEARCHED:
			return state.set('searchedComicsLoadingStatus', LOADING);

		case FETCH_COMICS_SEARCHED_SUCCESS:
			return state
				.set('searchedComics', List(action.comics))
				.set('numOfItems', action.numOfItems)
				.set('searchedComicsLoadingStatus', SUCCESS);

		case FETCH_COMICS_SEARCHED_FAILED:
			return state
				.set('searchedComicsLoadingStatus', FAILED)
				.set('searchedComicsLoadingStatusMessage', action.errorMessage);

		default:
			return state;
	}
}
