import { ofType } from 'redux-observable';
import {
	map,
	switchMap,
	catchError,
} from 'rxjs/operators';

import { actionTypes, areasActions, notifyActions } from '../../../controller';
import { rxjsApiFetcher } from '../../../lib/api-utils';
import { catchErrorForEpics } from '../../../../lib/epic-utils';

const {
	START_FETCH_AREAS,
} = actionTypes;
const {
	fetchAreasSuccessAction, fetchAreasFailedAction,
} = areasActions;
const {
	notifyErrorAction,
} = notifyActions;

// eslint-disable-next-line import/prefer-default-export
export function fetchAreasEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_AREAS),
		switchMap(() =>
			rxjsApiFetcher
				.get('/areas')
				.pipe(
					map(payload => fetchAreasSuccessAction(payload.response)),
					catchError(error => catchErrorForEpics(error, fetchAreasFailedAction, notifyErrorAction)),
				),
		),
	);
}
