import { ofType } from 'redux-observable';
import {
	map,
	switchMap,
	catchError,
} from 'rxjs/operators';

import { actionTypes, searchActions, notifyActions } from '../../../controller';
import { rxjsApiFetcher } from '../../../lib/api-utils';
import { catchErrorForEpics } from '../../../../lib/epic-utils';
import { objectFilter, objectFilterOptionEnums } from '../../../../lib/object-utils';

const { SKIP_EMPTY_STRING } = objectFilterOptionEnums;
const {
	START_FETCH_VIDEOS_SEARCHED,
} = actionTypes;
const {
	fetchVideosSearchedSuccessAction, fetchVideosSearchedFailedAction,
} = searchActions;
const {
	notifyErrorAction,
} = notifyActions;

// eslint-disable-next-line import/prefer-default-export
export function fetchVideosSearchedEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_VIDEOS_SEARCHED),
		switchMap(({ categoryIds, queries }) => {
			const categoryQueryString = categoryIds.map(id => `categoryId=${id}`).join('&');

			return rxjsApiFetcher
				.get(`/videos?${categoryQueryString}`, {
					queries: objectFilter(queries, [SKIP_EMPTY_STRING]),
				})
				.pipe(
					map(payload => payload.response),
					map(({ data, numOfItems }) => fetchVideosSearchedSuccessAction(data, numOfItems)),
					catchError(error => catchErrorForEpics(error, fetchVideosSearchedFailedAction, notifyErrorAction)),
				);
		},
		),
	);
}
