import { notifyHandlingAction } from '../../../lib/notify-handler/notify-handling-actions';
import {
	errorNotifications,
	successNotifications,
	infoNotifications,
} from '../../../lib/notify-handler/notifications';

const { AjaxError } = errorNotifications;
const { Success } = successNotifications;
const { Info } = infoNotifications;

export function notifyErrorAction(error, errorMessage) {
	return notifyHandlingAction(new AjaxError(errorMessage));
}

export function notifySuccessAction(successMessage) {
	return notifyHandlingAction(new Success(successMessage));
}

export function notifyInfoAction(message) {
	return notifyHandlingAction(new Info(message));
}
