import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { videoCategoryActions } from '../../../controller';

const { fetchVideoCategoriesAction } = videoCategoryActions;

const useVideoCategories = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchVideoCategoriesAction());
	}, []);

	const videoCategoryReducer = useSelector(state => state.videoCategory);
	const videoCategories = videoCategoryReducer.get('videoCategories').toJS();

	return videoCategories;
};

export default useVideoCategories;
