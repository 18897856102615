export default {
	// Auth
	RESET_AUTH: 'RESET_AUTH',

	START_CHECK_AUTH: 'START_CHECK_AUTH',
	CHECK_AUTH_SUCCESS: 'CHECK_AUTH_SUCCESS',
	CHECK_AUTH_FAILED: 'CHECK_AUTH_FAILED',

	SET_JWT: 'SET_JWT',

	// User
	START_FETCH_ME: 'START_FETCH_ME',
	FETCH_ME_SUCCESS: 'FETCH_ME_SUCCESS',
	FETCH_ME_FAILED: 'FETCH_ME_FAILED',

	START_PATCH_CDN_ENDPOINTS: 'START_PATCH_CDN_ENDPOINTS',
	PATCH_CDN_ENDPOINTS_SUCCESS: 'PATCH_CDN_ENDPOINTS_SUCCESS',
	PATCH_CDN_ENDPOINTS_FAILED: 'PATCH_CDN_ENDPOINTS_FAILED',

	START_FETCH_FAVORITE_VIDEOS: 'START_FETCH_FAVORITE_VIDEOS',
	FETCH_FAVORITE_VIDEOS_SUCCESS: 'FETCH_FAVORITE_VIDEOS_SUCCESS',
	FETCH_FAVORITE_VIDEOS_FAILED: 'FETCH_FAVORITE_VIDEOS_FAILED',

	START_ADD_FAVORITE_VIDEO: 'START_ADD_FAVORITE_VIDEO',
	ADD_FAVORITE_VIDEO_SUCCESS: 'ADD_FAVORITE_VIDEO_SUCCESS',
	ADD_FAVORITE_VIDEO_FAILED: 'ADD_FAVORITE_VIDEO_FAILED',

	START_REMOVE_FAVORITE_VIDEO: 'START_REMOVE_FAVORITE_VIDEO',
	REMOVE_FAVORITE_VIDEO_SUCCESS: 'REMOVE_FAVORITE_VIDEO_SUCCESS',
	REMOVE_FAVORITE_VIDEO_FAILED: 'REMOVE_FAVORITE_VIDEO_FAILED',

	START_REMOVE_ALL_FAVORITE_VIDEO: 'START_REMOVE_ALL_FAVORITE_VIDEO',
	REMOVE_ALL_FAVORITE_VIDEO_SUCCESS: 'REMOVE_ALL_FAVORITE_VIDEO_SUCCESS',
	REMOVE_ALL_FAVORITE_VIDEO_FAILED: 'REMOVE_ALL_FAVORITE_VIDEO_FAILED',

	START_FETCH_FAVORITE_DETAILED_VIDEOS: 'START_FETCH_FAVORITE_DETAILED_VIDEOS',
	FETCH_FAVORITE_DETAILED_VIDEOS_SUCCESS: 'FETCH_FAVORITE_DETAILED_VIDEOS_SUCCESS',
	FETCH_FAVORITE_DETAILED_VIDEOS_FAILED: 'FETCH_FAVORITE_DETAILED_VIDEOS_FAILED',

	START_FETCH_VIEWED_VIDEO_HISTORY: 'START_FETCH_VIEWED_VIDEO_HISTORY',
	FETCH_VIEWED_VIDEO_HISTORY_SUCCESS: 'FETCH_VIEWED_VIDEO_HISTORY_SUCCESS',
	FETCH_VIEWED_VIDEO_HISTORY_FAILED: 'FETCH_VIEWED_VIDEO_HISTORY_FAILED',

	START_FETCH_WATCH_COUNT_REMAINING: 'START_FETCH_WATCH_COUNT_REMAINING',
	FETCH_WATCH_COUNT_REMAINING_SUCCESS: 'FETCH_WATCH_COUNT_REMAINING_SUCCESS',
	FETCH_WATCH_COUNT_REMAINING_FAILED: 'FETCH_WATCH_COUNT_REMAINING_FAILED',

	START_PATCH_VIDEO_WATCH_TIMES: 'START_PATCH_VIDEO_WATCH_TIMES',
	PATCH_VIDEO_WATCH_TIMES_SUCCESS: 'PATCH_VIDEO_WATCH_TIMES_SUCCESS',
	PATCH_VIDEO_WATCH_TIMES_FAILED: 'PATCH_VIDEO_WATCH_TIMES_FAILED',

	// Home Page
	START_FETCH_HOME_LONG_VIDEOS: 'START_FETCH_HOME_LONG_VIDEOS',
	FETCH_HOME_LONG_VIDEOS_SUCCESS: 'FETCH_HOME_LONG_VIDEOS_SUCCESS',
	FETCH_HOME_LONG_VIDEOS_FAILED: 'FETCH_HOME_LONG_VIDEOS_FAILED',

	START_FETCH_HOME_SHORT_VIDEOS: 'START_FETCH_HOME_SHORT_VIDEOS',
	FETCH_HOME_SHORT_VIDEOS_SUCCESS: 'FETCH_HOME_SHORT_VIDEOS_SUCCESS',
	FETCH_HOME_SHORT_VIDEOS_FAILED: 'FETCH_HOME_SHORT_VIDEOS_FAILED',

	START_FETCH_HOME_COMICS: 'START_FETCH_HOME_COMICS',
	FETCH_HOME_COMICS_SUCCESS: 'FETCH_HOME_COMICS_SUCCESS',
	FETCH_HOME_COMICS_FAILED: 'FETCH_HOME_COMICS_FAILED',

	START_FETCH_HOME_TVS: 'START_FETCH_HOME_TVS',
	FETCH_HOME_TVS_SUCCESS: 'FETCH_HOME_TVS_SUCCESS',
	FETCH_HOME_TVS_FAILED: 'FETCH_HOME_TVS_FAILED',

	START_FETCH_HOME_MOVIES: 'START_FETCH_HOME_MOVIES',
	FETCH_HOME_MOVIES_SUCCESS: 'FETCH_HOME_MOVIES_SUCCESS',
	FETCH_HOME_MOVIES_FAILED: 'FETCH_HOME_MOVIES_FAILED',

	// Adult-long-video page
	START_FETCH_ADULT_LONG_VIDEOS_RECENTLY: 'START_FETCH_ADULT_LONG_VIDEOS_RECENTLY',
	FETCH_ADULT_LONG_VIDEOS_RECENTLY_SUCCESS: 'FETCH_ADULT_LONG_VIDEOS_RECENTLY_SUCCESS',
	FETCH_ADULT_LONG_VIDEOS_RECENTLY_FAILED: 'FETCH_ADULT_LONG_VIDEOS_RECENTLY_FAILED',

	START_FETCH_HOT_ADULT_LONG_VIDEOS: 'START_FETCH_HOT_ADULT_LONG_VIDEOS',
	FETCH_HOT_ADULT_LONG_VIDEOS_SUCCESS: 'FETCH_HOT_ADULT_LONG_VIDEOS_SUCCESS',
	FETCH_HOT_ADULT_LONG_VIDEOS_FAILED: 'FETCH_HOT_ADULT_LONG_VIDEOS_FAILED',

	START_FETCH_FAVORITE_ADULT_LONG_VIDEOS: 'START_FETCH_FAVORITE_ADULT_LONG_VIDEOS',
	FETCH_FAVORITE_ADULT_LONG_VIDEOS_SUCCESS: 'FETCH_FAVORITE_ADULT_LONG_VIDEOS_SUCCESS',
	FETCH_FAVORITE_ADULT_LONG_VIDEOS_FAILED: 'FETCH_FAVORITE_ADULT_LONG_VIDEOS_FAILED',

	START_FETCH_ADULT_LONG_VIDEOS_SEARCHED: 'START_FETCH_ADULT_LONG_VIDEOS_SEARCHED',
	FETCH_ADULT_LONG_VIDEOS_SEARCHED_SUCCESS: 'FETCH_ADULT_LONG_VIDEOS_SEARCHED_SUCCESS',
	FETCH_ADULT_LONG_VIDEOS_SEARCHED_FAILED: 'FETCH_ADULT_LONG_VIDEOS_SEARCHED_FAILED',

	START_FETCH_ADULT_LONG_VIDEOS_RELATED: 'START_FETCH_ADULT_LONG_VIDEOS_RELATED',
	FETCH_ADULT_LONG_VIDEOS_RELATED_SUCCESS: 'FETCH_ADULT_LONG_VIDEOS_RELATED_SUCCESS',
	FETCH_ADULT_LONG_VIDEOS_RELATED_FAILED: 'FETCH_ADULT_LONG_VIDEOS_RELATED_FAILED',

	START_FETCH_ADULT_LONG_VIDEOS_RECOMMENDED: 'START_FETCH_ADULT_LONG_VIDEOS_RECOMMENDED',
	FETCH_ADULT_LONG_VIDEOS_RECOMMENDED_SUCCESS: 'FETCH_ADULT_LONG_VIDEOS_RECOMMENDED_SUCCESS',
	FETCH_ADULT_LONG_VIDEOS_RECOMMENDED_FAILED: 'FETCH_ADULT_LONG_VIDEOS_RECOMMENDED_FAILED',

	// Adult-short-video page
	START_FETCH_ADULT_SHORT_VIDEOS_RECENTLY: 'START_FETCH_ADULT_SHORT_VIDEOS_RECENTLY',
	FETCH_ADULT_SHORT_VIDEOS_RECENTLY_SUCCESS: 'FETCH_ADULT_SHORT_VIDEOS_RECENTLY_SUCCESS',
	FETCH_ADULT_SHORT_VIDEOS_RECENTLY_FAILED: 'FETCH_ADULT_SHORT_VIDEOS_RECENTLY_FAILED',

	START_FETCH_HOT_ADULT_SHORT_VIDEOS: 'START_FETCH_HOT_ADULT_SHORT_VIDEOS',
	FETCH_HOT_ADULT_SHORT_VIDEOS_SUCCESS: 'FETCH_HOT_ADULT_SHORT_VIDEOS_SUCCESS',
	FETCH_HOT_ADULT_SHORT_VIDEOS_FAILED: 'FETCH_HOT_ADULT_SHORT_VIDEOS_FAILED',

	START_FETCH_FAVORITE_ADULT_SHORT_VIDEOS: 'START_FETCH_FAVORITE_ADULT_SHORT_VIDEOS',
	FETCH_FAVORITE_ADULT_SHORT_VIDEOS_SUCCESS: 'FETCH_FAVORITE_ADULT_SHORT_VIDEOS_SUCCESS',
	FETCH_FAVORITE_ADULT_SHORT_VIDEOS_FAILED: 'FETCH_FAVORITE_ADULT_SHORT_VIDEOS_FAILED',

	START_FETCH_ADULT_SHORT_VIDEOS_SEARCHED: 'START_FETCH_ADULT_SHORT_VIDEOS_SEARCHED',
	FETCH_ADULT_SHORT_VIDEOS_SEARCHED_SUCCESS: 'FETCH_ADULT_SHORT_VIDEOS_SEARCHED_SUCCESS',
	FETCH_ADULT_SHORT_VIDEOS_SEARCHED_FAILED: 'FETCH_ADULT_SHORT_VIDEOS_SEARCHED_FAILED',

	START_FETCH_ADULT_SHORT_VIDEOS_RECOMMENDED: 'START_FETCH_ADULT_SHORT_VIDEOS_RECOMMENDED',
	FETCH_ADULT_SHORT_VIDEOS_RECOMMENDED_SUCCESS: 'FETCH_ADULT_SHORT_VIDEOS_RECOMMENDED_SUCCESS',
	FETCH_ADULT_SHORT_VIDEOS_RECOMMENDED_FAILED: 'FETCH_ADULT_SHORT_VIDEOS_RECOMMENDED_FAILED',

	// Comic page
	START_FETCH_COMICS_RECENTLY: 'START_FETCH_COMICS_RECENTLY',
	FETCH_COMICS_RECENTLY_SUCCESS: 'FETCH_COMICS_RECENTLY_SUCCESS',
	FETCH_COMICS_RECENTLY_FAILED: 'FETCH_COMICS_RECENTLY_FAILED',

	START_FETCH_HOT_COMICS: 'START_FETCH_HOT_COMICS',
	FETCH_HOT_COMICS_SUCCESS: 'FETCH_HOT_COMICS_SUCCESS',
	FETCH_HOT_COMICS_FAILED: 'FETCH_HOT_COMICS_FAILED',

	START_FETCH_FAVORITE_COMICS: 'START_FETCH_FAVORITE_COMICS',
	FETCH_FAVORITE_COMICS_SUCCESS: 'FETCH_FAVORITE_COMICS_SUCCESS',
	FETCH_FAVORITE_COMICS_FAILED: 'FETCH_FAVORITE_COMICS_FAILED',

	START_FETCH_COMICS_SEARCHED: 'START_FETCH_COMICS_SEARCHED',
	FETCH_COMICS_SEARCHED_SUCCESS: 'FETCH_COMICS_SEARCHED_SUCCESS',
	FETCH_COMICS_SEARCHED_FAILED: 'FETCH_COMICS_SEARCHED_FAILED',

	// TV page
	START_FETCH_TVS_RECENTLY: 'START_FETCH_TVS_RECENTLY',
	FETCH_TVS_RECENTLY_SUCCESS: 'FETCH_TVS_RECENTLY_SUCCESS',
	FETCH_TVS_RECENTLY_FAILED: 'FETCH_TVS_RECENTLY_FAILED',

	START_FETCH_HOT_TVS: 'START_FETCH_HOT_TVS',
	FETCH_HOT_TVS_SUCCESS: 'FETCH_HOT_TVS_SUCCESS',
	FETCH_HOT_TVS_FAILED: 'FETCH_HOT_TVS_FAILED',

	START_FETCH_FAVORITE_TVS: 'START_FETCH_FAVORITE_TVS',
	FETCH_FAVORITE_TVS_SUCCESS: 'FETCH_FAVORITE_TVS_SUCCESS',
	FETCH_FAVORITE_TVS_FAILED: 'FETCH_FAVORITE_TVS_FAILED',

	START_FETCH_TVS_SEARCHED: 'START_FETCH_TVS_SEARCHED',
	FETCH_TVS_SEARCHED_SUCCESS: 'FETCH_TVS_SEARCHED_SUCCESS',
	FETCH_TVS_SEARCHED_FAILED: 'FETCH_TVS_SEARCHED_FAILED',

	START_FETCH_TVS_RECOMMENDED: 'START_FETCH_TVS_RECOMMENDED',
	FETCH_TVS_RECOMMENDED_SUCCESS: 'FETCH_TVS_RECOMMENDED_SUCCESS',
	FETCH_TVS_RECOMMENDED_FAILED: 'FETCH_TVS_RECOMMENDED_FAILED',

	// Movie page
	START_FETCH_MOVIES_RECENTLY: 'START_FETCH_MOVIES_RECENTLY',
	FETCH_MOVIES_RECENTLY_SUCCESS: 'FETCH_MOVIES_RECENTLY_SUCCESS',
	FETCH_MOVIES_RECENTLY_FAILED: 'FETCH_MOVIES_RECENTLY_FAILED',

	START_FETCH_HOT_MOVIES: 'START_FETCH_HOT_MOVIES',
	FETCH_HOT_MOVIES_SUCCESS: 'FETCH_HOT_MOVIES_SUCCESS',
	FETCH_HOT_MOVIES_FAILED: 'FETCH_HOT_MOVIES_FAILED',

	START_FETCH_FAVORITE_MOVIES: 'START_FETCH_FAVORITE_MOVIES',
	FETCH_FAVORITE_MOVIES_SUCCESS: 'FETCH_FAVORITE_MOVIES_SUCCESS',
	FETCH_FAVORITE_MOVIES_FAILED: 'FETCH_FAVORITE_MOVIES_FAILED',

	START_FETCH_MOVIES_SEARCHED: 'START_FETCH_MOVIES_SEARCHED',
	FETCH_MOVIES_SEARCHED_SUCCESS: 'FETCH_MOVIES_SEARCHED_SUCCESS',
	FETCH_MOVIES_SEARCHED_FAILED: 'FETCH_MOVIES_SEARCHED_FAILED',

	START_FETCH_MOVIES_RECOMMENDED: 'START_FETCH_MOVIES_RECOMMENDED',
	FETCH_MOVIES_RECOMMENDED_SUCCESS: 'FETCH_MOVIES_RECOMMENDED_SUCCESS',
	FETCH_MOVIES_RECOMMENDED_FAILED: 'FETCH_MOVIES_RECOMMENDED_FAILED',

	// Search page
	START_FETCH_VIDEOS_SEARCHED: 'START_FETCH_VIDEOS_SEARCHED',
	FETCH_VIDEOS_SEARCHED_SUCCESS: 'FETCH_VIDEOS_SEARCHED_SUCCESS',
	FETCH_VIDEOS_SEARCHED_FAILED: 'FETCH_VIDEOS_SEARCHED_FAILED',

	// Video tags
	START_FETCH_VIDEO_TAGS: 'START_FETCH_VIDEO_TAGS',
	FETCH_VIDEO_TAGS_SUCCESS: 'FETCH_VIDEO_TAGS_SUCCESS',
	FETCH_VIDEO_TAGS_FAILED: 'FETCH_VIDEO_TAGS_FAILED',

	// Areas
	START_FETCH_AREAS: 'START_FETCH_AREAS',
	FETCH_AREAS_SUCCESS: 'FETCH_AREAS_SUCCESS',
	FETCH_AREAS_FAILED: 'FETCH_AREAS_FAILED',

	// Video Detail
	START_FETCH_VIDEO_DETAIL: 'START_FETCH_VIDEO_DETAIL',
	FETCH_VIDEO_DETAIL_SUCCESS: 'FETCH_VIDEO_DETAIL_SUCCESS',
	FETCH_VIDEO_DETAIL_FAILED: 'FETCH_VIDEO_DETAIL_FAILED',

	CLEAR_VIDEO_DETAIL: 'CLEAR_VIDEO_DETAIL',

	// CDN Endpoints
	START_FETCH_CDN_ENDPOINTS: 'START_FETCH_CDN_ENDPOINTS',
	FETCH_CDN_ENDPOINTS_SUCCESS: 'FETCH_CDN_ENDPOINTS_SUCCESS',
	FETCH_CDN_ENDPOINTS_FAILED: 'FETCH_CDN_ENDPOINTS_FAILED',

	// Video Episode
	START_FETCH_VIDEO_EPISODE: 'START_FETCH_VIDEO_EPISODE',
	FETCH_VIDEO_EPISODE_SUCCESS: 'FETCH_VIDEO_EPISODE_SUCCESS',
	FETCH_VIDEO_EPISODE_FAILED: 'FETCH_VIDEO_EPISODE_FAILED',

	CLEAR_VIDEO_EPISODE: 'CLEAR_VIDEO_EPISODE',

	// Banner
	START_FETCH_BANNERS: 'START_FETCH_BANNERS',
	FETCH_BANNERS_SUCCESS: 'FETCH_BANNERS_SUCCESS',
	FETCH_BANNERS_FAILED: 'FETCH_BANNERS_FAILED',

	// Video Category
	START_FETCH_VIDEO_CATEGORIES: 'START_FETCH_VIDEO_CATEGORIES',
	FETCH_VIDEO_CATEGORIES_SUCCESS: 'FETCH_VIDEO_CATEGORIES_SUCCESS',
	FETCH_VIDEO_CATEGORIES_FAILED: 'FETCH_VIDEO_CATEGORIES_FAILED',
};
