import { Map } from 'immutable';
import { LoadingStatusEnum } from '../../../../../lib/enums';
import { actionTypes } from '../../../../controller';

const {
	START_FETCH_VIDEO_EPISODE,
	FETCH_VIDEO_EPISODE_SUCCESS,
	FETCH_VIDEO_EPISODE_FAILED,
	CLEAR_VIDEO_EPISODE,
} = actionTypes;

const {
	NONE,
	LOADING,
	SUCCESS,
	FAILED,
} = LoadingStatusEnum;

// Example
// Map({
// 	"videoEpisode": Map({
// 		"id": "5c0e28661ee1f01bfb794ac2",
// 		"video": {
// 			"Id": "5b73f6c133d3cdc4e42c94fb",
// 			"name": "航海王",
// 			"categoryId": "5b73eb8886d00574944a9dc5",
// 			"actorNames": ["魯夫"], //主演
// 			"tagIds": ["5b73f2cc33d3cdc4e42c9519"],
// 			"releasedAt": "2018-07-24 05:36:30.957Z", //上架時間
// 			//簡介
// 			"description": "財富、名聲、勢力、擁有世界上的一切的「海賊王」哥爾·D·羅傑在即將遭到處刑前說了一段話：「想要我的財寶嗎？想要的話可以全部給你。去找吧！我把所有的財寶都放在那裡」！後來世界上的人們將這個寶藏稱作「大秘寶」（ONE PIECE）",
// 			//導演
// 			"directosr": ["李不安"],
// 			//(漫画/电视剧)集数资讯
// 			"videoEpisodeOrder": [{
// 				"id": "5c0e28661ee1f01bfb794ac2",
// 				"name": "第一集",
// 			}, {
// 				"id": "5c0e28661ee1f01bfb794ac3",
// 				"name": "第二集",
// 			}],
// 			"finishStatus": "finished",
// 		},
// 		"name": "第一集",
// 		//(電視劇)影片路徑
// 		"videoPath": "http://cdn3.aiz2t.cn/drama/dramaFixture1/002.mp4?sign=xxxx&t=xxxx",
// 		//(漫畫)圖片路徑
// 		"imagePaths": [
// 			"http://cdn3.aiz2t.cn/develop/comic/航海王/航海王01_001.jpg?sign=xxxx&t=xxxx",
// 			"http://cdn3.aiz2t.cn/develop/comic/航海王/航海王01_002.jpg?sign=xxxx&t=xxxx",
// 			"http://cdn3.aiz2t.cn/develop/comic/航海王/航海王01_003.jpg?sign=xxxx&t=xxxx",
// 		],
// 		// 觀看時間(秒)
// 		"watchTime": 10,
// 	}),
// });

const initalState = Map({
	videoEpisode: Map({}),
	videoEpisodeLoadingStatus: NONE,
	videoEpisodeLoadingStatusMessage: '',
});

export default function episode(state = initalState, action) {
	switch (action.type) {
		case START_FETCH_VIDEO_EPISODE:
			return state.set('videoEpisodeLoadingStatus', LOADING);

		case FETCH_VIDEO_EPISODE_SUCCESS:
			return state
				.set('videoEpisode', Map(action.episode))
				.set('videoEpisodeLoadingStatus', SUCCESS);

		case FETCH_VIDEO_EPISODE_FAILED:
			return state
				.set('videoEpisodeLoadingStatus', FAILED)
				.set('videoEpisodeLoadingStatusMessage', action.errorMessage);

		case CLEAR_VIDEO_EPISODE:
			return state
				.set('videoEpisode', Map({}))
				.set('videoEpisodeLoadingStatus', NONE)
				.set('videoEpisodeLoadingStatusMessage', '');

		default:
			return state;
	}
}
