import { ofType } from 'redux-observable';
import {
	map,
	switchMap,
	catchError,
} from 'rxjs/operators';

import { actionTypes, adultLongVideoActions, notifyActions } from '../../../controller';
import { rxjsApiFetcher } from '../../../lib/api-utils';
import { catchErrorForEpics } from '../../../../lib/epic-utils';
import { objectFilter, objectFilterOptionEnums } from '../../../../lib/object-utils';

const { SKIP_EMPTY_STRING } = objectFilterOptionEnums;
const {
	START_FETCH_ADULT_LONG_VIDEOS_RECENTLY,
	START_FETCH_HOT_ADULT_LONG_VIDEOS,
	START_FETCH_FAVORITE_ADULT_LONG_VIDEOS,
	START_FETCH_ADULT_LONG_VIDEOS_SEARCHED,
	START_FETCH_ADULT_LONG_VIDEOS_RELATED,
	START_FETCH_ADULT_LONG_VIDEOS_RECOMMENDED,
} = actionTypes;
const {
	fetchAdultLongVideosRecentlySuccessAction, fetchAdultLongVideosRecentlyFailedAction,
	fetchHotAdultLongVideosSuccessAction, fetchHotAdultLongVideosFailedAction,
	fetchFavoriteAdultLongVideosSuccessAction, fetchFavoriteAdultLongVideosFailedAction,
	fetchAdultLongVideosSearchedSuccessAction, fetchAdultLongVideosSearchedFailedAction,
	fetchAdultLongVideosRelatedSuccessAction, fetchAdultLongVideosRelatedFailedAction,
	fetchAdultLongVideosRecommendedSuccessAction, fetchAdultLongVideosRecommendedFailedAction,
} = adultLongVideoActions;
const { notifyErrorAction } = notifyActions;

export function fetchAdultLongVideosRecentlyEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_ADULT_LONG_VIDEOS_RECENTLY),
		switchMap(({ categoryId, limit }) =>
			rxjsApiFetcher
				.get('/videos', { queries: {
					categoryId,
					limit,
				} })
				.pipe(
					map(payload => payload.response),
					map(({ data }) => fetchAdultLongVideosRecentlySuccessAction(data)),
					catchError(error => catchErrorForEpics(error, fetchAdultLongVideosRecentlyFailedAction, notifyErrorAction)),
				),
		),
	);
}

export function fetchHotAdultLongVideosEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_HOT_ADULT_LONG_VIDEOS),
		switchMap(({ categoryId, sort, limit }) =>
			rxjsApiFetcher
				.get('/videos', { queries: {
					categoryId,
					sort,
					limit,
				} })
				.pipe(
					map(payload => payload.response),
					map(({ data }) => fetchHotAdultLongVideosSuccessAction(data)),
					catchError(error => catchErrorForEpics(error, fetchHotAdultLongVideosFailedAction, notifyErrorAction)),
				),
		),
	);
}

export function fetchFavoriteAdultLongVideosEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_FAVORITE_ADULT_LONG_VIDEOS),
		switchMap(({ categoryId }) =>
			rxjsApiFetcher
				.get('/users/id=me/favorite-videos', { queries: {
					details: 1,
					categoryId,
				} })
				.pipe(
					map(payload => fetchFavoriteAdultLongVideosSuccessAction(payload.response)),
					catchError(error => catchErrorForEpics(error, fetchFavoriteAdultLongVideosFailedAction, notifyErrorAction)),
				),
		),
	);
}

export function fetchAdultLongVideosSearchedEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_ADULT_LONG_VIDEOS_SEARCHED),
		switchMap(({ categoryId, query, limit }) => {
			const { sort, videoCategoryId, page } = query;

			return rxjsApiFetcher
				.get('/videos', {
					queries: objectFilter(
						{
							categoryId,
							sort,
							videoCategoryId,
							page,
							limit,
						},
						[SKIP_EMPTY_STRING],
					),
				})
				.pipe(
					map(payload => payload.response),
					map(({ data, numOfItems }) => fetchAdultLongVideosSearchedSuccessAction(data, numOfItems)),
					catchError(error => catchErrorForEpics(error, fetchAdultLongVideosSearchedFailedAction, notifyErrorAction)),
				);
		}),
	);
}

export function fetchAdultLongVideosRelatedEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_ADULT_LONG_VIDEOS_RELATED),
		switchMap(({ categoryId, limit, actorName }) =>
			rxjsApiFetcher
				.get('/videos', { queries: {
					categoryId,
					limit,
					actorName,
				} })
				.pipe(
					map(payload => payload.response),
					map(({ data }) => fetchAdultLongVideosRelatedSuccessAction(data)),
					catchError(error => catchErrorForEpics(error, fetchAdultLongVideosRelatedFailedAction, notifyErrorAction)),
				),
		),
	);
}

export function fetchAdultLongVideosRecommendedEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_ADULT_LONG_VIDEOS_RECOMMENDED),
		switchMap(({ videoId, limit, page }) =>
			rxjsApiFetcher
				.get(`/videos/id=${videoId}/recommends`, { queries: {
					limit,
					page,
				} })
				.pipe(
					map(payload => payload.response),
					map(({ data, numOfItems }) => fetchAdultLongVideosRecommendedSuccessAction(data, numOfItems)),
					catchError(error => catchErrorForEpics(error, fetchAdultLongVideosRecommendedFailedAction, notifyErrorAction)),
				),
		),
	);
}
