import { Map, List } from 'immutable';
import { LoadingStatusEnum } from '../../../../../lib/enums';
import { actionTypes } from '../../../../controller';

const {
	START_FETCH_VIDEO_TAGS,
	FETCH_VIDEO_TAGS_SUCCESS,
	FETCH_VIDEO_TAGS_FAILED,
} = actionTypes;

const {
	NONE,
	LOADING,
	SUCCESS,
	FAILED,
} = LoadingStatusEnum;

// Example
// Map({
// 	"videoTags": List([
// 		{
// 			"id": "5b73f6c133d3cdc4e42c94a7",
// 			"categoryId": "5b73eb8886d00574944a9dc1",
// 			"name": "美乳",
// 		},
// 		{
// 			"id": "5b73f6c133d3cdc4e42c94a7",
// 			"categoryId": "5b73eb8886d00574944a9dc2",
// 			"name": "贫乳",
// 		},
// 		{
// 			"id": "5b73f6c133d3cdc4e42c94a7",
// 			"categoryId": "5b73eb8886d00574944a9dc3",
// 			"name": "科幻",
// 		},
// 		{
// 			"id": "5b73f6c133d3cdc4e42c94a7",
// 			"categoryId": "5b73eb8886d00574944a9dc5",
// 			"name": "熱血",
// 		},
// 	]),
// });

const initalState = Map({
	videoTags: List([]),
	videoTagsLoadingStatus: NONE,
	videoTagsLoadingStatusMessage: '',
});

export default function videoTag(state = initalState, action) {
	switch (action.type) {
		case START_FETCH_VIDEO_TAGS:
			return state.set('videoTagsLoadingStatus', LOADING);

		case FETCH_VIDEO_TAGS_SUCCESS:
			return state
				.set('videoTags', List(action.tags))
				.set('videoTagsLoadingStatus', SUCCESS);

		case FETCH_VIDEO_TAGS_FAILED:
			return state
				.set('videoTagsLoadingStatus', FAILED)
				.set('videoTagsLoadingStatusMessage', action.errorMessage);

		default:
			return state;
	}
}
