import { Notify, Modal } from 'ljit-react-components';

const { Success: success } = Modal;

export const NotifyTypeEnum = {
	INFO: 'info',
	ERROR: 'error',
	SUCCESS: 'success',
	SUCCESS_FILL: 'successFill',
};

export const createNotification = (type, message, delay) => Notify[type](message, delay);

export const reLoginNotify = ({ text, iconType = null, nextStep = () => {} }) => {
	success({
		title: '成功提示',
		content: `${text}成功，请重新登入`,
		icon: iconType,
		okText: '确 定',
		centered: true,
		onOk: () => nextStep(),
	});
};
