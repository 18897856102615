import {
	fetchers,
	generateApiFetcher,
	generateFileUploadFetcher,
} from '../../lib/api-fetcher';
import { get as getConfig, getMode } from '../config';

const {
	rxAjaxFetcher,
} = fetchers;

const _generateApiFetcher = {};
const _generateFileUploadFetcher = {};
const _generateApiFetcherWithoutAuthorization = {};

export const rxjsApiFetcher = _generateApiFetcher;
export const rxjsFileUploadFetcher = _generateFileUploadFetcher;
export const rxjsApiFetcherWithoutAuthorization = _generateApiFetcherWithoutAuthorization;

export function initApiFetcher(jwtToken, isCORS = false) {
	const defaultConfig = {
		fetcher: rxAjaxFetcher,
		baseUrl: getAPIBaseUrl(),
		headers: {
			Authorization: `Bearer ${jwtToken}`,
		},
	};

	if (isCORS) {
		Object.assign(_generateApiFetcher, generateApiFetcher({
			...defaultConfig,
			crossDomain: true,
			withCredentials: true,
		}));
	} else {
		Object.assign(_generateApiFetcher, generateApiFetcher(defaultConfig));
	}
}

export function initApiFetcherWithoutAuthorization(isCORS = false) {
	const defaultConfig = {
		fetcher: rxAjaxFetcher,
		baseUrl: getAPIBaseUrl(),
	};

	if (isCORS) {
		Object.assign(_generateApiFetcherWithoutAuthorization, generateApiFetcher({
			...defaultConfig,
			crossDomain: true,
			withCredentials: true,
		}));
	} else {
		Object.assign(_generateApiFetcherWithoutAuthorization, generateApiFetcher(defaultConfig));
	}
}

export function initFileUploadFetcher(jwtToken, isCORS = false) {
	const defaultConfig = {
		fetcher: rxAjaxFetcher,
		baseUrl: getAPIBaseUrl(),
		headers: {
			Authorization: `Bearer ${jwtToken}`,
		},
	};

	if (isCORS) {
		Object.assign(_generateFileUploadFetcher, generateFileUploadFetcher({
			...defaultConfig,
			crossDomain: true,
			withCredentials: true,
		}));
	} else {
		Object.assign(_generateFileUploadFetcher, generateFileUploadFetcher(defaultConfig));
	}
}

function getAPIBaseUrl() {
	const { BASE, API_VERSION } = getConfig();
	const mode = getMode();

	if (mode === 'local' && process.env.NODE_ENV === 'development') {
		return `/api/${BASE}/${API_VERSION}`;
	}

	return `/api/${API_VERSION}`;
}
