import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Wrapper from '../wrapper';
import { ArrowToUpSvg } from '../../../assets/icons';

import './style.styl';

const propTypes = {
	className: PropTypes.string,
};

const PREFIX_CLASS = 'go-to-top';

const GoToTop = ({
	className,
}) => {
	const _handleClickGoToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	return (
		<div className={cx(PREFIX_CLASS, className)}>
			<Wrapper className={`${PREFIX_CLASS}__wrapper`}>
				<div className={`${PREFIX_CLASS}__circle`} onClick={_handleClickGoToTop}>
					<ArrowToUpSvg className={`${PREFIX_CLASS}__arrow`}/>
				</div>
			</Wrapper>
		</div>
	);
};

GoToTop.propTypes = propTypes;

export default GoToTop;
