import actionTypes from './action-types';

const {
	START_FETCH_VIDEO_TAGS,
	FETCH_VIDEO_TAGS_SUCCESS,
	FETCH_VIDEO_TAGS_FAILED,
} = actionTypes;

export function fetchVideoTagsAction() {
	return {
		type: START_FETCH_VIDEO_TAGS,
	};
}

export function fetchVideoTagsSuccessAction(tags) {
	return {
		type: FETCH_VIDEO_TAGS_SUCCESS,
		tags,
	};
}

export function fetchVideoTagsFailedAction(error, errorMessage) {
	return {
		type: FETCH_VIDEO_TAGS_FAILED,
		error,
		errorMessage,
	};
}
