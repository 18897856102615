import { Iterable } from 'immutable';
import { createLogger } from 'redux-logger';

const stateTransformer = state => {
	const transformState = {};

	for (const [key, value] of Object.entries(state)) {
		if (Iterable.isIterable(value)) {
			transformState[key] = value.toJS();
		} else {
			transformState[key] = value;
		}
	}

	return transformState;
};

// https://github.com/LogRocket/redux-logger#options
const loggerMiddleWare = createLogger({
	stateTransformer,
});

export default loggerMiddleWare;
