export const LoadingStatusEnum = {
	NONE: 0,
	SUCCESS: 1,
	FAILED: 2,
	LOADING: 3,
};

export const RoleEnums = {
	ADMIN: 'admin',
	MANAGER: 'manager',
};
