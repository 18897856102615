import actionTypes from './action-types';

const {
	START_FETCH_BANNERS,
	FETCH_BANNERS_SUCCESS,
	FETCH_BANNERS_FAILED,
} = actionTypes;

export function fetchBannersAction(categoryId) {
	return {
		type: START_FETCH_BANNERS,
		categoryId,
	};
}

export function fetchBannersSuccessAction(banners) {
	return {
		type: FETCH_BANNERS_SUCCESS,
		banners,
	};
}

export function fetchBannersFailedAction(error, errorMessage) {
	return {
		type: FETCH_BANNERS_FAILED,
		error,
		errorMessage,
	};
}
