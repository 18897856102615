import { ofType } from 'redux-observable';
import {
	map,
	switchMap,
	catchError,
} from 'rxjs/operators';

import { actionTypes, videoTagsActions, notifyActions } from '../../../controller';
import { rxjsApiFetcher } from '../../../lib/api-utils';
import { catchErrorForEpics } from '../../../../lib/epic-utils';

const {
	START_FETCH_VIDEO_TAGS,
} = actionTypes;
const {
	fetchVideoTagsSuccessAction, fetchVideoTagsFailedAction,
} = videoTagsActions;
const {
	notifyErrorAction,
} = notifyActions;

// eslint-disable-next-line import/prefer-default-export
export function fetchVideoTagsEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_VIDEO_TAGS),
		switchMap(() =>
			rxjsApiFetcher
				.get('/video-tags')
				.pipe(
					map(payload => fetchVideoTagsSuccessAction(payload.response)),
					catchError(error => catchErrorForEpics(error, fetchVideoTagsFailedAction, notifyErrorAction)),
				),
		),
	);
}
