import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Wrapper from '../../wrapper';
import NavLogo from '../nav-logo';
import FavoriteButton from '../../favorite-button';

import { useMediaQuery } from '../../../../lib/hooks';

import { ArrowToRightSvg, ArrowToLeftSvg, EpisodeListSvg } from '../../../../assets/icons';

import './style.styl';

const propTypes = {
	className: PropTypes.string,
	comicName: PropTypes.string,
	episodeId: PropTypes.string,
	episodeName: PropTypes.string,
	episodeList: PropTypes.array,
	isLike: PropTypes.bool,
	isEpisodeListOpen: PropTypes.bool,
	onClickEpisodeListMenu: PropTypes.func,
	onClickLogo: PropTypes.func,
	onClickComicName: PropTypes.func,
	onClickEpisode: PropTypes.func,
	onClickPrevEpisode: PropTypes.func,
	onClickNextEpisode: PropTypes.func,
	onClickFavorite: PropTypes.func,
};

const PREFIX_CLASS = 'nav-comic';

const NavComic = ({
	className,
	comicName,
	episodeId,
	episodeName,
	episodeList,
	isLike,
	isEpisodeListOpen,
	onClickEpisodeListMenu,
	onClickLogo,
	onClickComicName,
	onClickEpisode,
	onClickPrevEpisode,
	onClickNextEpisode,
	onClickFavorite,
}) => {
	const isTabletDevice = useMediaQuery('(max-width: 900px)');
	const hasPrevEpisode = typeof onClickPrevEpisode === 'function';
	const hasNextEpisode = typeof onClickNextEpisode === 'function';

	return (
		<div className={cx(PREFIX_CLASS, className)}>
			<Wrapper className={`${PREFIX_CLASS}__area`}>
				<div className={`${PREFIX_CLASS}__logo-area`}>
					{
						!isTabletDevice && (
							<>
								<NavLogo
									className={`${PREFIX_CLASS}__logo`}
									onClick={onClickLogo}
								/>
								<span
									className={`${PREFIX_CLASS}--text-overflow ${PREFIX_CLASS}__comic-name`}
									onClick={onClickComicName}
								>
									{comicName}
								</span>
								<span>{'>'}</span>
							</>
						)
					}
					{isTabletDevice && <span className={`${PREFIX_CLASS}__comic-name`} onClick={onClickComicName}>{'<'}</span>}
					<span className={`${PREFIX_CLASS}--text-overflow`}>{episodeName}</span>
				</div>
				{
					!isTabletDevice && (
						<div className={`${PREFIX_CLASS}__episode`}>
							<span
								className={cx(`${PREFIX_CLASS}__episode__prev`, {
									[`${PREFIX_CLASS}__episode--disable`]: !hasPrevEpisode,
								})}
								onClick={onClickPrevEpisode}
							>
								<ArrowToLeftSvg/>上一话
							</span>
							<span className={`${PREFIX_CLASS}--text-overflow`}>{episodeName}</span>
							<span
								className={cx(`${PREFIX_CLASS}__episode__next`, {
									[`${PREFIX_CLASS}__episode--disable`]: !hasNextEpisode,
								})}
								onClick={onClickNextEpisode}
							>
								下一话<ArrowToRightSvg/>
							</span>
						</div>
					)
				}
				<div className={`${PREFIX_CLASS}__menu`}>
					<FavoriteButton text="收藏漫画" isActive={isLike} onClick={onClickFavorite}/>
					<div
						className={cx(`${PREFIX_CLASS}__menu__toggle-list`, {
							[`${PREFIX_CLASS}__menu__toggle-list--active`]: isEpisodeListOpen,
						})}
						onClick={onClickEpisodeListMenu}
					>
						<EpisodeListSvg/>
					</div>
				</div>
			</Wrapper>
			<div
				className={cx(`${PREFIX_CLASS}__episode-list`, {
					[`${PREFIX_CLASS}__episode-list--open`]: isEpisodeListOpen,
				})}
			>
				{
					episodeList && episodeList.map(({ id, name }) => (
						<div
							key={id}
							className={cx(`${PREFIX_CLASS}__episode-list__item`, {
								[`${PREFIX_CLASS}__episode-list__item--active`]: episodeId === id,
							})}
							onClick={onClickEpisode(id)}
						>
							{name}
						</div>
					))
				}
			</div>
		</div>
	);
};

NavComic.propTypes = propTypes;

export default NavComic;
