import { Map, List } from 'immutable';
import { LoadingStatusEnum } from '../../../../lib/enums';
import { actionTypes } from '../../../controller';

const {
	START_FETCH_TVS_RECENTLY,
	FETCH_TVS_RECENTLY_SUCCESS,
	FETCH_TVS_RECENTLY_FAILED,

	START_FETCH_HOT_TVS,
	FETCH_HOT_TVS_SUCCESS,
	FETCH_HOT_TVS_FAILED,

	START_FETCH_FAVORITE_TVS,
	FETCH_FAVORITE_TVS_SUCCESS,
	FETCH_FAVORITE_TVS_FAILED,

	START_FETCH_TVS_SEARCHED,
	FETCH_TVS_SEARCHED_SUCCESS,
	FETCH_TVS_SEARCHED_FAILED,

	START_FETCH_TVS_RECOMMENDED,
	FETCH_TVS_RECOMMENDED_SUCCESS,
	FETCH_TVS_RECOMMENDED_FAILED,
} = actionTypes;

const {
	NONE,
	LOADING,
	SUCCESS,
	FAILED,
} = LoadingStatusEnum;

// Example
// Map({
// 	"recentVideos": List([
// 		{
// 			"id": "5b73f6c133d3cdc4e42c94a7",
// 			"categoryId": "5b73eb8886d00574944a9dc4",
// 			"name": "冰與火之歌：權力的遊戲 第7季.230",
// 			"actorNames": ["瑟曦·蘭尼斯特"],
// 			"areaId": "5f5b360ef499e6227a05ca46",
// 			"directors": ["David Benioff"],
// 			"isBanner": true,
// 			"coverImage1": "http://cdn3.aiz2t.cn/AV/A65773/A65773-A.jpg?xxxx"
// 			"videoEpisodeOrder": ["5c0e28661ee1f01bfb794ac4", "5c0e28661ee1f01bfb794ac5", "5c0e28661ee1f01bfb794ac6"]
// 			"lastViewedEpisodeId": "5c0e28661ee1f01bfb794ac3"
// 		},
// 	]),
// });

const initalState = Map({
	recentVideos: List([]),
	recentVideosLoadingStatus: NONE,
	recentVideosLoadingStatusMessage: '',

	hotVideos: List([]),
	hotVideosLoadingStatus: NONE,
	hotVideosLoadingStatusMessage: '',

	favoriteVideos: List([]),
	favoriteVideosLoadingStatus: NONE,
	favoriteVideosLoadingStatusMessage: '',

	searchedVideos: List([]),
	searchedVideosLoadingStatus: NONE,
	searchedVideosLoadingStatusMessage: '',
	numOfItems: 0,

	recommendedVideos: List([]),
	recommendedVideosLoadingStatus: NONE,
	recommendedVideosLoadingStatusMessage: '',
	recommendedVideosNumOfItems: 0,
});

export default function tv(state = initalState, action) {
	switch (action.type) {
		// Recent
		case START_FETCH_TVS_RECENTLY:
			return state.set('recentVideosLoadingStatus', LOADING);

		case FETCH_TVS_RECENTLY_SUCCESS:
			return state
				.set('recentVideos', List(action.videos))
				.set('recentVideosLoadingStatus', SUCCESS);

		case FETCH_TVS_RECENTLY_FAILED:
			return state
				.set('recentVideosLoadingStatus', FAILED)
				.set('recentVideosLoadingStatusMessage', action.errorMessage);

		// Hot
		case START_FETCH_HOT_TVS:
			return state.set('hotVideosLoadingStatus', LOADING);

		case FETCH_HOT_TVS_SUCCESS:
			return state
				.set('hotVideos', List(action.videos))
				.set('hotVideosLoadingStatus', SUCCESS);

		case FETCH_HOT_TVS_FAILED:
			return state
				.set('hotVideosLoadingStatus', FAILED)
				.set('hotVideosLoadingStatusMessage', action.errorMessage);

		//	Favorite
		case START_FETCH_FAVORITE_TVS:
			return state.set('favoriteVideosLoadingStatus', LOADING);

		case FETCH_FAVORITE_TVS_SUCCESS:
			return state
				.set('favoriteVideos', List(action.videos))
				.set('favoriteVideosLoadingStatus', SUCCESS);

		case FETCH_FAVORITE_TVS_FAILED:
			return state
				.set('favoriteVideosLoadingStatus', FAILED)
				.set('favoriteVideosLoadingStatusMessage', action.errorMessage);

		//	Searched
		case START_FETCH_TVS_SEARCHED:
			return state.set('searchedVideosLoadingStatus', LOADING);

		case FETCH_TVS_SEARCHED_SUCCESS:
			return state
				.set('searchedVideos', List(action.videos))
				.set('numOfItems', action.numOfItems)
				.set('searchedVideosLoadingStatus', SUCCESS);

		case FETCH_TVS_SEARCHED_FAILED:
			return state
				.set('searchedVideosLoadingStatus', FAILED)
				.set('searchedVideosLoadingStatusMessage', action.errorMessage);

		//	Recommended
		case START_FETCH_TVS_RECOMMENDED:
			return state.set('recommendedVideosLoadingStatus', LOADING);

		case FETCH_TVS_RECOMMENDED_SUCCESS:
			return state
				.set('recommendedVideos', List(action.videos))
				.set('recommendedVideosNumOfItems', action.numOfItems)
				.set('recommendedVideosLoadingStatus', SUCCESS);

		case FETCH_TVS_RECOMMENDED_FAILED:
			return state
				.set('recommendedVideosLoadingStatus', FAILED)
				.set('recommendedVideosLoadingStatusMessage', action.errorMessage);

		default:
			return state;
	}
}
