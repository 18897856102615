import { Map, List } from 'immutable';
import { LoadingStatusEnum } from '../../../../lib/enums';
import { actionTypes } from '../../../controller';

const {
	START_FETCH_VIDEOS_SEARCHED,
	FETCH_VIDEOS_SEARCHED_SUCCESS,
	FETCH_VIDEOS_SEARCHED_FAILED,
} = actionTypes;

const {
	NONE,
	LOADING,
	SUCCESS,
	FAILED,
} = LoadingStatusEnum;

// Example
// Map({
// 	"searchedVideos": List([
// 		{
// 			"id": "5b73f6c133d3cdc4e42c94f0",
// 			"categoryId": "5b73eb8886d00574944a9dc1",
// 			"name": "美人妻が通贩で买った精力剤を饮んだら脳みそとろけるようなSEXで快楽トリップした话",
// 			"actorNames": ["森沢かな（饭冈かなこ）"],
// 			"coverImage1": "http://cdn3.aiz2t.cn/AV/A65773/A65773-A.jpg?xxxx"
// 		},
// 	]),
// });

const initalState = Map({
	searchedVideos: List([]),
	searchedVideosLoadingStatus: NONE,
	searchedVideosLoadingStatusMessage: '',
	numOfItems: 0,
});

export default function search(state = initalState, action) {
	switch (action.type) {
		case START_FETCH_VIDEOS_SEARCHED:
			return state.set('searchedVideosLoadingStatus', LOADING);

		case FETCH_VIDEOS_SEARCHED_SUCCESS:
			return state
				.set('searchedVideos', List(action.videos))
				.set('numOfItems', action.numOfItems)
				.set('searchedVideosLoadingStatus', SUCCESS);

		case FETCH_VIDEOS_SEARCHED_FAILED:
			return state
				.set('searchedVideosLoadingStatus', FAILED)
				.set('searchedVideosLoadingStatusMessage', action.errorMessage);

		default:
			return state;
	}
}
