import { useEffect, useState } from 'react';

const supportMatchMedia = typeof window !== 'undefined' && typeof window.matchMedia !== 'undefined';

const useMediaQuery = query => {
	const mediaQuery = supportMatchMedia ? window.matchMedia(query) : {};
	const [match, setMatch] = useState(Boolean(mediaQuery.matches));

	useEffect(() => {
		if (supportMatchMedia) {
			const updateMatchHandler = () => setMatch(Boolean(mediaQuery.matches));

			mediaQuery.addEventListener('change', updateMatchHandler);
			return () => mediaQuery.removeEventListener('change', updateMatchHandler);
		}
	}, []);

	return match;
};

export {
	// eslint-disable-next-line import/prefer-default-export
	useMediaQuery,
};
