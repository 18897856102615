import { ReactComponent as HamburgerSvg } from './hamburger.svg';
import { ReactComponent as SearchSvg } from './search.svg';
import { ReactComponent as UserSvg } from './user.svg';
import { ReactComponent as FavoriteWhiteHollowSvg } from './favorite-white-hollow.svg';
import { ReactComponent as HistorySvg } from './history.svg';
import { ReactComponent as CloseSvg } from './close.svg';

import { ReactComponent as FavoriteCircleSvg } from './favorite-circle.svg';
import { ReactComponent as PlayCircleSvg } from './play-circle.svg';
import { ReactComponent as ReadBookSvg } from './read-book.svg';
import { ReactComponent as PlusCircleSvg } from './plus-circle.svg';

import { ReactComponent as AdultLongVideoSvg } from './adult-long-video.svg';
import { ReactComponent as AdultShortVideoSvg } from './adult-short-video.svg';
import { ReactComponent as ComicSvg } from './comic.svg';
import { ReactComponent as MovieSvg } from './movie.svg';
import { ReactComponent as TvSvg } from './tv.svg';
import { ReactComponent as HotSvg } from './hot.svg';
import { ReactComponent as FavoriteHollowSvg } from './favorite-hollow.svg';
import { ReactComponent as PlayHollowSvg } from './play-hollow.svg';

import { ReactComponent as SliderArrowLeftSvg } from './slider-arrow-left.svg';
import { ReactComponent as SliderArrowRightSvg } from './slider-arrow-right.svg';

import { ReactComponent as ArrowToLeftSvg } from './arrow-to-left.svg';
import { ReactComponent as ArrowToRightSvg } from './arrow-to-right.svg';
import { ReactComponent as ArrowToUpSvg } from './arrow-to-up.svg';

import { ReactComponent as PlaySvg } from './play.svg';
import { ReactComponent as PauseSvg } from './pause.svg';
import { ReactComponent as Forward10Svg } from './forward10.svg';
import { ReactComponent as Forward15Svg } from './forward15.svg';
import { ReactComponent as Backward10Svg } from './backward10.svg';
import { ReactComponent as Backward15Svg } from './backward15.svg';

import { ReactComponent as FavoriteSvg } from './favorite.svg';
import { ReactComponent as EpisodeListSvg } from './episode-list.svg';

import { ReactComponent as LongVideoMenuSvg } from './long-video-menu.svg';
import { ReactComponent as ShortVideoMenuSvg } from './short-video-menu.svg';
import { ReactComponent as MovieMenuSvg } from './movie-menu.svg';
import { ReactComponent as TvMenuSvg } from './tv-menu.svg';
import { ReactComponent as ComicMenuSvg } from './comic-menu.svg';
import { ReactComponent as StatsReportMenuSvg } from './report-menu.svg';
import { ReactComponent as CdnMenuSvg } from './cdn-menu.svg';

import { ReactComponent as CopySvg } from './copy.svg';
import { ReactComponent as QuestionSvg } from './question.svg';

export {
	HamburgerSvg,
	SearchSvg,
	UserSvg,
	FavoriteWhiteHollowSvg,
	HistorySvg,
	CloseSvg,
	FavoriteCircleSvg,
	PlayCircleSvg,
	ReadBookSvg,
	PlusCircleSvg,
	AdultLongVideoSvg,
	AdultShortVideoSvg,
	ComicSvg,
	MovieSvg,
	TvSvg,
	HotSvg,
	FavoriteHollowSvg,
	PlayHollowSvg,
	SliderArrowLeftSvg,
	SliderArrowRightSvg,
	ArrowToLeftSvg,
	ArrowToRightSvg,
	ArrowToUpSvg,
	PlaySvg,
	PauseSvg,
	Forward10Svg,
	Forward15Svg,
	Backward10Svg,
	Backward15Svg,
	FavoriteSvg,
	EpisodeListSvg,
	LongVideoMenuSvg,
	ShortVideoMenuSvg,
	MovieMenuSvg,
	TvMenuSvg,
	ComicMenuSvg,
	CopySvg,
	CdnMenuSvg,
	StatsReportMenuSvg,
	QuestionSvg,
};
