const CategoryEnum = {
	LONG_VIDEO: '5b73eb8886d00574944a9dc1',
	SHORT_VIDEO: '5b73eb8886d00574944a9dc2',
	MOVIE: '5b73eb8886d00574944a9dc3',
	TV: '5b73eb8886d00574944a9dc4',
	COMIC: '5b73eb8886d00574944a9dc5',
};

const {
	LONG_VIDEO,
	SHORT_VIDEO,
	MOVIE,
	TV,
	COMIC,
} = CategoryEnum;

const CategoryTextMap = {
	[LONG_VIDEO]: '成人長片',
	[SHORT_VIDEO]: '成人短片',
	[MOVIE]: '电影',
	[TV]: '电视剧',
	[COMIC]: '成人漫画',
};

export {
	CategoryEnum, CategoryTextMap,
};
