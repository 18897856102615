import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Breadcrumb } from 'ljit-react-components';
import { BrowserRouter } from 'react-router-dom';
import { initController } from 'ljit-store-connecter';
import { createStore, StoreProvider } from './repositories';
import { initApiFetcherWithoutAuthorization } from './lib/api-utils';
import App from './app';

const basename = '/v';
const store = createStore();

initController(store, connect);
initApiFetcherWithoutAuthorization();

ReactDOM.render(
	<StoreProvider store={store}>
		<BrowserRouter basename={basename}>
			<Breadcrumb.BreadcrumbProvider>
				<App/>
			</Breadcrumb.BreadcrumbProvider>
		</BrowserRouter>
	</StoreProvider>,
	document.getElementById('client-console-root'),
);
