import { ofType } from 'redux-observable';
import {
	map,
	switchMap,
	catchError,
} from 'rxjs/operators';

import { actionTypes, videoCategoryActions, notifyActions } from '../../../controller';
import { rxjsApiFetcher } from '../../../lib/api-utils';
import { catchErrorForEpics } from '../../../../lib/epic-utils';

const {
	START_FETCH_VIDEO_CATEGORIES,
} = actionTypes;
const {
	fetchVideoCategoriesSuccessAction, fetchVideoCategoriesFailedAction,
} = videoCategoryActions;
const {
	notifyErrorAction,
} = notifyActions;

// eslint-disable-next-line import/prefer-default-export
export function fetchVideoCategoriesEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_VIDEO_CATEGORIES),
		switchMap(() =>
			rxjsApiFetcher
				.get('/video-categories')
				.pipe(
					map(payload => fetchVideoCategoriesSuccessAction(payload.response)),
					catchError(error => catchErrorForEpics(error, fetchVideoCategoriesFailedAction, notifyErrorAction)),
				),
		),
	);
}
