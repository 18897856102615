import actionTypes from './action-types';
import { CategoryEnum } from '../../lib/category-enum';
import { SortEnum } from '../../lib/enum';

const {
	START_FETCH_ADULT_SHORT_VIDEOS_RECENTLY,
	FETCH_ADULT_SHORT_VIDEOS_RECENTLY_SUCCESS,
	FETCH_ADULT_SHORT_VIDEOS_RECENTLY_FAILED,

	START_FETCH_HOT_ADULT_SHORT_VIDEOS,
	FETCH_HOT_ADULT_SHORT_VIDEOS_SUCCESS,
	FETCH_HOT_ADULT_SHORT_VIDEOS_FAILED,

	START_FETCH_FAVORITE_ADULT_SHORT_VIDEOS,
	FETCH_FAVORITE_ADULT_SHORT_VIDEOS_SUCCESS,
	FETCH_FAVORITE_ADULT_SHORT_VIDEOS_FAILED,

	START_FETCH_ADULT_SHORT_VIDEOS_SEARCHED,
	FETCH_ADULT_SHORT_VIDEOS_SEARCHED_SUCCESS,
	FETCH_ADULT_SHORT_VIDEOS_SEARCHED_FAILED,

	START_FETCH_ADULT_SHORT_VIDEOS_RECOMMENDED,
	FETCH_ADULT_SHORT_VIDEOS_RECOMMENDED_SUCCESS,
	FETCH_ADULT_SHORT_VIDEOS_RECOMMENDED_FAILED,
} = actionTypes;

const { SHORT_VIDEO } = CategoryEnum;
const { MONTHLY } = SortEnum;

export function fetchAdultShortVideosRecentlyAction() {
	return {
		type: START_FETCH_ADULT_SHORT_VIDEOS_RECENTLY,
		categoryId: SHORT_VIDEO,
		limit: 20,
	};
}

export function fetchAdultShortVideosRecentlySuccessAction(videos) {
	return {
		type: FETCH_ADULT_SHORT_VIDEOS_RECENTLY_SUCCESS,
		videos,
	};
}

export function fetchAdultShortVideosRecentlyFailedAction(error, errorMessage) {
	return {
		type: FETCH_ADULT_SHORT_VIDEOS_RECENTLY_FAILED,
		error,
		errorMessage,
	};
}

export function fetchHotAdultShortVideosAction(sort) {
	return {
		type: START_FETCH_HOT_ADULT_SHORT_VIDEOS,
		categoryId: SHORT_VIDEO,
		sort: sort || MONTHLY,
		limit: 20,
	};
}

export function fetchHotAdultShortVideosSuccessAction(videos) {
	return {
		type: FETCH_HOT_ADULT_SHORT_VIDEOS_SUCCESS,
		videos,
	};
}

export function fetchHotAdultShortVideosFailedAction(error, errorMessage) {
	return {
		type: FETCH_HOT_ADULT_SHORT_VIDEOS_FAILED,
		error,
		errorMessage,
	};
}

export function fetchFavoriteAdultShortVideosAction() {
	return {
		type: START_FETCH_FAVORITE_ADULT_SHORT_VIDEOS,
		categoryId: SHORT_VIDEO,
	};
}

export function fetchFavoriteAdultShortVideosSuccessAction(videos) {
	return {
		type: FETCH_FAVORITE_ADULT_SHORT_VIDEOS_SUCCESS,
		videos,
	};
}

export function fetchFavoriteAdultShortVideosFailedAction(error, errorMessage) {
	return {
		type: FETCH_FAVORITE_ADULT_SHORT_VIDEOS_FAILED,
		error,
		errorMessage,
	};
}

export function fetchAdultShortVideosSearchedAction(query) {
	return {
		type: START_FETCH_ADULT_SHORT_VIDEOS_SEARCHED,
		categoryId: SHORT_VIDEO,
		query,
		limit: 32,
	};
}

export function fetchAdultShortVideosSearchedSuccessAction(videos, numOfItems) {
	return {
		type: FETCH_ADULT_SHORT_VIDEOS_SEARCHED_SUCCESS,
		videos,
		numOfItems,
	};
}

export function fetchAdultShortVideosSearchedFailedAction(error, errorMessage) {
	return {
		type: FETCH_ADULT_SHORT_VIDEOS_SEARCHED_FAILED,
		error,
		errorMessage,
	};
}

export function fetchAdultShortVideosRecommendedAction(videoId, limit, page) {
	return {
		type: START_FETCH_ADULT_SHORT_VIDEOS_RECOMMENDED,
		videoId,
		limit: limit || 12,
		page,
	};
}

export function fetchAdultShortVideosRecommendedSuccessAction(videos, numOfItems) {
	return {
		type: FETCH_ADULT_SHORT_VIDEOS_RECOMMENDED_SUCCESS,
		videos,
		numOfItems,
	};
}

export function fetchAdultShortVideosRecommendedFailedAction(error, errorMessage) {
	return {
		type: FETCH_ADULT_SHORT_VIDEOS_RECOMMENDED_FAILED,
		error,
		errorMessage,
	};
}
