import { useEffect } from 'react';

const useOnChangePage = (path, search) => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, [path, search]);
};

export {
	// eslint-disable-next-line import/prefer-default-export
	useOnChangePage,
};
