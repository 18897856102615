import actionTypes from './action-types';

const {
	START_FETCH_AREAS,
	FETCH_AREAS_SUCCESS,
	FETCH_AREAS_FAILED,
} = actionTypes;

export function fetchAreasAction() {
	return {
		type: START_FETCH_AREAS,
	};
}

export function fetchAreasSuccessAction(areas) {
	return {
		type: FETCH_AREAS_SUCCESS,
		areas,
	};
}

export function fetchAreasFailedAction(error, errorMessage) {
	return {
		type: FETCH_AREAS_FAILED,
		error,
		errorMessage,
	};
}
