import {
	isConfigRequired,
	merge,
} from './utils';

const defaultConfigs = {
	baseUrl: '',
	method: 'POST',
	fetcher: undefined,
};

function generateFileUploadFetcher(initialConfigs = {}) {
	const _defaults = merge(defaultConfigs, initialConfigs);
	const {
		fetcher: _fetcher = isConfigRequired('fetcher'),
	} = _defaults;

	const handlers = {};
	const _request = configs => {
		const finalConfigs = merge({}, _defaults, configs);

		finalConfigs.method = finalConfigs.method.toUpperCase();

		return _fetcher(finalConfigs);
	};

	handlers.post = (url, body, configs = {}) => _request(merge(configs, {
		url,
		body,
		method: 'POST',
	}));

	handlers.patch = (url, body, configs = {}) => _request(merge(configs, {
		url,
		body,
		method: 'PATCH',
	}));

	return handlers;
}

export default generateFileUploadFetcher;
