import { ofType } from 'redux-observable';
import {
	map,
	switchMap,
	catchError,
} from 'rxjs/operators';

import { actionTypes, tvActions, notifyActions } from '../../../controller';
import { rxjsApiFetcher } from '../../../lib/api-utils';
import { catchErrorForEpics } from '../../../../lib/epic-utils';
import { objectFilter, objectFilterOptionEnums } from '../../../../lib/object-utils';

const { SKIP_EMPTY_STRING } = objectFilterOptionEnums;

const {
	START_FETCH_TVS_RECENTLY,
	START_FETCH_HOT_TVS,
	START_FETCH_FAVORITE_TVS,
	START_FETCH_TVS_SEARCHED,
	START_FETCH_TVS_RECOMMENDED,
} = actionTypes;
const {
	fetchTvsRecentlySuccessAction, fetchTvsRecentlyFailedAction,
	fetchHotTvsSuccessAction, fetchHotTvsFailedAction,
	fetchFavoriteTvsSuccessAction, fetchFavoriteTvsFailedAction,
	fetchTvsSearchedSuccessAction, fetchTvsSearchedFailedAction,
	fetchTvsRecommendedSuccessAction, fetchTvsRecommendedFailedAction,
} = tvActions;
const {
	notifyErrorAction,
} = notifyActions;

export function fetchTvsRecentlyEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_TVS_RECENTLY),
		switchMap(({ categoryId, limit }) =>
			rxjsApiFetcher
				.get('/videos', { queries: {
					categoryId,
					limit,
				} })
				.pipe(
					map(payload => payload.response),
					map(({ data }) => fetchTvsRecentlySuccessAction(data)),
					catchError(error => catchErrorForEpics(error, fetchTvsRecentlyFailedAction, notifyErrorAction)),
				),
		),
	);
}

export function fetchHotTvsEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_HOT_TVS),
		switchMap(({ categoryId, sort, limit }) =>
			rxjsApiFetcher
				.get('/videos', { queries: {
					categoryId,
					sort,
					limit,
				} })
				.pipe(
					map(payload => payload.response),
					map(({ data }) => fetchHotTvsSuccessAction(data)),
					catchError(error => catchErrorForEpics(error, fetchHotTvsFailedAction, notifyErrorAction)),
				),
		),
	);
}

export function fetchFavoriteTvsEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_FAVORITE_TVS),
		switchMap(({ categoryId }) =>
			rxjsApiFetcher
				.get('/users/id=me/favorite-videos', { queries: {
					details: 1,
					categoryId,
				} })
				.pipe(
					map(payload => fetchFavoriteTvsSuccessAction(payload.response)),
					catchError(error => catchErrorForEpics(error, fetchFavoriteTvsFailedAction, notifyErrorAction)),
				),
		),
	);
}

export function fetchTvsSearchedEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_TVS_SEARCHED),
		switchMap(({ categoryId, query, limit }) => {
			const { sort, tagId, areaId, page } = query;

			return rxjsApiFetcher
				.get('/videos', {
					queries: objectFilter(
						{
							categoryId,
							sort,
							tagId,
							areaId,
							page,
							limit,
						},
						[SKIP_EMPTY_STRING],
					),
				})
				.pipe(
					map(payload => payload.response),
					map(({ data, numOfItems }) => fetchTvsSearchedSuccessAction(data, numOfItems)),
					catchError(error => catchErrorForEpics(error, fetchTvsSearchedFailedAction, notifyErrorAction)),
				);
		}),
	);
}

export function fetchTvsRecommendedEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_TVS_RECOMMENDED),
		switchMap(({ videoId, limit, page }) =>
			rxjsApiFetcher
				.get(`/videos/id=${videoId}/recommends`, { queries: {
					limit,
					page,
				} })
				.pipe(
					map(({ response }) => fetchTvsRecommendedSuccessAction(response.data, response.numOfItems)),
					catchError(error => catchErrorForEpics(error, fetchTvsRecommendedFailedAction, notifyErrorAction)),
				)),
	);
}
