import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { videoTagsActions } from '../../../controller';

const { fetchVideoTagsAction } = videoTagsActions;

const useVideoTags = videoCategoryId => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchVideoTagsAction());
	}, []);

	const videoTagReducer = useSelector(state => state.videoTag);
	const videoTags = videoTagReducer.get('videoTags').toJS();

	if (videoCategoryId) {
		return videoTags.filter(({ categoryId }) => categoryId === videoCategoryId);
	}

	return videoTags;
};

export default useVideoTags;
