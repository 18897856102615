import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { userActions } from '../../../controller';

const { fetchFavoriteVideosAction } = userActions;

const useFavoriteVideos = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchFavoriteVideosAction());
	}, []);
};

export default useFavoriteVideos;
