import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { videoDetailActions } from '../../../controller';

const { fetchVideoDetailAction, clearVideoDetailAction } = videoDetailActions;

const useVideoDetail = videoId => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchVideoDetailAction(videoId));

		return () => dispatch(clearVideoDetailAction());
	}, [videoId]);

	const videoDetailReducer = useSelector(state => state.videoDetail);
	const videoDetail = videoDetailReducer.get('videoDetail').toJS();

	return videoDetail;
};

export default useVideoDetail;
