import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './style.styl';

const propTypes = {
	className: PropTypes.string,
	renderNav: PropTypes.func,
};

const PREFIX_CLASS = 'nav-top-bar';

const NavTopBar = ({
	className,
	renderNav,
}) => (
	<div className={cx(PREFIX_CLASS, className)}>
		{renderNav(PREFIX_CLASS)}
	</div>
);

NavTopBar.propTypes = propTypes;

export default NavTopBar;
