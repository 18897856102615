import { Map } from 'immutable';
import { LoadingStatusEnum } from '../../../../../lib/enums';
import { actionTypes } from '../../../../controller';

const {
	NONE,
	LOADING,
	SUCCESS,
	FAILED,
} = LoadingStatusEnum;

const {
	START_CHECK_AUTH,
	CHECK_AUTH_SUCCESS,
	CHECK_AUTH_FAILED,
} = actionTypes;

const initialState = Map({
	token: '',
	loadingStatus: NONE,
	loadingStatusMessage: '',
});

export default function auth(state = initialState, action) {
	switch (action.type) {
		case START_CHECK_AUTH:
			return state.set('loadingStatus', LOADING);

		case CHECK_AUTH_SUCCESS:
			return state
				.set('token', action.token)
				.set('loadingStatus', SUCCESS);

		case CHECK_AUTH_FAILED:
			return state
				.set('loadingStatus', FAILED)
				.set('loadingStatusMessage', action.errorMessage);

		default:
			return state;
	}
}
