import actionTypes from './action-types';

const {
	START_FETCH_VIDEO_EPISODE,
	FETCH_VIDEO_EPISODE_SUCCESS,
	FETCH_VIDEO_EPISODE_FAILED,
	CLEAR_VIDEO_EPISODE,
} = actionTypes;

export function fetchVideoEpisodeAction(videoId, episodeId) {
	return {
		type: START_FETCH_VIDEO_EPISODE,
		videoId,
		episodeId,
	};
}

export function fetchVideoEpisodeSuccessAction(episode) {
	return {
		type: FETCH_VIDEO_EPISODE_SUCCESS,
		episode,
	};
}

export function fetchVideoEpisodeFailedAction(error, errorMessage) {
	return {
		type: FETCH_VIDEO_EPISODE_FAILED,
		error,
		errorMessage,
	};
}

export function clearVideoEpisodeAction() {
	return {
		type: CLEAR_VIDEO_EPISODE,
	};
}
