import actionTypes from './action-types';

const {
	START_FETCH_ME,
	FETCH_ME_SUCCESS,
	FETCH_ME_FAILED,

	START_PATCH_CDN_ENDPOINTS,
	PATCH_CDN_ENDPOINTS_SUCCESS,
	PATCH_CDN_ENDPOINTS_FAILED,

	START_FETCH_FAVORITE_VIDEOS,
	FETCH_FAVORITE_VIDEOS_SUCCESS,
	FETCH_FAVORITE_VIDEOS_FAILED,

	START_ADD_FAVORITE_VIDEO,
	ADD_FAVORITE_VIDEO_SUCCESS,
	ADD_FAVORITE_VIDEO_FAILED,

	START_REMOVE_FAVORITE_VIDEO,
	REMOVE_FAVORITE_VIDEO_SUCCESS,
	REMOVE_FAVORITE_VIDEO_FAILED,

	START_REMOVE_ALL_FAVORITE_VIDEO,
	REMOVE_ALL_FAVORITE_VIDEO_SUCCESS,
	REMOVE_ALL_FAVORITE_VIDEO_FAILED,

	START_FETCH_FAVORITE_DETAILED_VIDEOS,
	FETCH_FAVORITE_DETAILED_VIDEOS_SUCCESS,
	FETCH_FAVORITE_DETAILED_VIDEOS_FAILED,

	START_FETCH_VIEWED_VIDEO_HISTORY,
	FETCH_VIEWED_VIDEO_HISTORY_SUCCESS,
	FETCH_VIEWED_VIDEO_HISTORY_FAILED,

	START_FETCH_WATCH_COUNT_REMAINING,
	FETCH_WATCH_COUNT_REMAINING_SUCCESS,
	FETCH_WATCH_COUNT_REMAINING_FAILED,

	START_PATCH_VIDEO_WATCH_TIMES,
	PATCH_VIDEO_WATCH_TIMES_SUCCESS,
	PATCH_VIDEO_WATCH_TIMES_FAILED,
} = actionTypes;

const VIDEO_ID_LIST_TYPE = 0;
const DETAILED_VIDEO_LIST_TYPE = 1;

export function fetchMeAction() {
	return {
		type: START_FETCH_ME,
	};
}

export function fetchMeSuccessAction(me = {}) {
	return {
		type: FETCH_ME_SUCCESS,
		me,
	};
}

export function fetchMeFailedAction(error, errorMessage) {
	return {
		type: FETCH_ME_FAILED,
		error,
		errorMessage,
	};
}

export function patchCDNEndpointsAction(cdnEndpointId) {
	return {
		type: START_PATCH_CDN_ENDPOINTS,
		cdnEndpointId,
	};
}

export function patchCDNEndpointsSuccessAction() {
	return {
		type: PATCH_CDN_ENDPOINTS_SUCCESS,
	};
}

export function patchCDNEndpointsFailedAction(error, errorMessage) {
	return {
		type: PATCH_CDN_ENDPOINTS_FAILED,
		error,
		errorMessage,
	};
}

export function fetchFavoriteVideosAction() {
	return {
		type: START_FETCH_FAVORITE_VIDEOS,
		details: VIDEO_ID_LIST_TYPE,
	};
}

export function fetchFavoriteVideosSuccessAction(videos) {
	return {
		type: FETCH_FAVORITE_VIDEOS_SUCCESS,
		videos,
	};
}

export function fetchFavoriteVideosFailedAction(error, errorMessage) {
	return {
		type: FETCH_FAVORITE_VIDEOS_FAILED,
		error,
		errorMessage,
	};
}

export function addFavoriteVideoAction(videoId) {
	return {
		type: START_ADD_FAVORITE_VIDEO,
		videoId,
	};
}

export function addFavoriteVideoSuccessAction() {
	return {
		type: ADD_FAVORITE_VIDEO_SUCCESS,
	};
}

export function addFavoriteVideoFailedAction(error, errorMessage) {
	return {
		type: ADD_FAVORITE_VIDEO_FAILED,
		error,
		errorMessage,
	};
}

export function removeFavoriteVideoAction(videoId) {
	return {
		type: START_REMOVE_FAVORITE_VIDEO,
		videoId,
	};
}

export function removeFavoriteVideoSuccessAction() {
	return {
		type: REMOVE_FAVORITE_VIDEO_SUCCESS,
	};
}

export function removeFavoriteVideoFailedAction(error, errorMessage) {
	return {
		type: REMOVE_FAVORITE_VIDEO_FAILED,
		error,
		errorMessage,
	};
}

export function removeAllFavoriteVideoAction(categoryId) {
	return {
		type: START_REMOVE_ALL_FAVORITE_VIDEO,
		categoryId,
	};
}

export function removeAllFavoriteVideoSuccessAction() {
	return {
		type: REMOVE_ALL_FAVORITE_VIDEO_SUCCESS,
	};
}

export function removeAllFavoriteVideoFailedAction(error, errorMessage) {
	return {
		type: REMOVE_ALL_FAVORITE_VIDEO_FAILED,
		error,
		errorMessage,
	};
}

export function fetchFavoriteDetailedVideosAction(categoryId) {
	return {
		type: START_FETCH_FAVORITE_DETAILED_VIDEOS,
		details: DETAILED_VIDEO_LIST_TYPE,
		categoryId,
	};
}

export function fetchFavoriteDetailedVideosSuccessAction(videos) {
	return {
		type: FETCH_FAVORITE_DETAILED_VIDEOS_SUCCESS,
		videos,
	};
}

export function fetchFavoriteDetailedVideosFailedAction(error, errorMessage) {
	return {
		type: FETCH_FAVORITE_DETAILED_VIDEOS_FAILED,
		error,
		errorMessage,
	};
}

export function fetchViewedVideoHistoryAction(categoryId) {
	return {
		type: START_FETCH_VIEWED_VIDEO_HISTORY,
		categoryId,
	};
}

export function fetchViewedVideoHistorySuccessAction(history) {
	return {
		type: FETCH_VIEWED_VIDEO_HISTORY_SUCCESS,
		history,
	};
}

export function fetchViewedVideoHistoryFailedAction(error, errorMessage) {
	return {
		type: FETCH_VIEWED_VIDEO_HISTORY_FAILED,
		error,
		errorMessage,
	};
}

export function fetchWatchCountRemainingAction(categoryId) {
	return {
		type: START_FETCH_WATCH_COUNT_REMAINING,
		categoryId,
	};
}

export function fetchWatchCountRemainingSuccessAction(count) {
	return {
		type: FETCH_WATCH_COUNT_REMAINING_SUCCESS,
		count,
	};
}

export function fetchWatchCountRemainingFailedAction(error, errorMessage) {
	return {
		type: FETCH_WATCH_COUNT_REMAINING_FAILED,
		error,
		errorMessage,
	};
}

export function patchVideoWatchTimesAction(videoId, watchTime, episodeId) {
	return {
		type: START_PATCH_VIDEO_WATCH_TIMES,
		videoId,
		watchTime,
		episodeId,
	};
}

export function patchVideoWatchTimesSuccessAction() {
	return {
		type: PATCH_VIDEO_WATCH_TIMES_SUCCESS,
	};
}

export function patchVideoWatchTimesFailedAction(error, errorMessage) {
	return {
		type: PATCH_VIDEO_WATCH_TIMES_FAILED,
		error,
		errorMessage,
	};
}
