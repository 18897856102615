import actionTypes from './action-types';
import { CategoryEnum } from '../../lib/category-enum';
import { SortEnum } from '../../lib/enum';

const {
	START_FETCH_TVS_RECENTLY,
	FETCH_TVS_RECENTLY_SUCCESS,
	FETCH_TVS_RECENTLY_FAILED,

	START_FETCH_HOT_TVS,
	FETCH_HOT_TVS_SUCCESS,
	FETCH_HOT_TVS_FAILED,

	START_FETCH_FAVORITE_TVS,
	FETCH_FAVORITE_TVS_SUCCESS,
	FETCH_FAVORITE_TVS_FAILED,

	START_FETCH_TVS_SEARCHED,
	FETCH_TVS_SEARCHED_SUCCESS,
	FETCH_TVS_SEARCHED_FAILED,

	START_FETCH_TVS_RECOMMENDED,
	FETCH_TVS_RECOMMENDED_SUCCESS,
	FETCH_TVS_RECOMMENDED_FAILED,
} = actionTypes;

const { TV } = CategoryEnum;
const { WEEKLY } = SortEnum;

export function fetchTvsRecentlyAction() {
	return {
		type: START_FETCH_TVS_RECENTLY,
		categoryId: TV,
		limit: 10,
	};
}

export function fetchTvsRecentlySuccessAction(videos) {
	return {
		type: FETCH_TVS_RECENTLY_SUCCESS,
		videos,
	};
}

export function fetchTvsRecentlyFailedAction(error, errorMessage) {
	return {
		type: FETCH_TVS_RECENTLY_FAILED,
		error,
		errorMessage,
	};
}

export function fetchHotTvsAction() {
	return {
		type: START_FETCH_HOT_TVS,
		categoryId: TV,
		sort: WEEKLY,
		limit: 10,
	};
}

export function fetchHotTvsSuccessAction(videos) {
	return {
		type: FETCH_HOT_TVS_SUCCESS,
		videos,
	};
}

export function fetchHotTvsFailedAction(error, errorMessage) {
	return {
		type: FETCH_HOT_TVS_FAILED,
		error,
		errorMessage,
	};
}

export function fetchFavoriteTvsAction() {
	return {
		type: START_FETCH_FAVORITE_TVS,
		categoryId: TV,
	};
}

export function fetchFavoriteTvsSuccessAction(videos) {
	return {
		type: FETCH_FAVORITE_TVS_SUCCESS,
		videos,
	};
}

export function fetchFavoriteTvsFailedAction(error, errorMessage) {
	return {
		type: FETCH_FAVORITE_TVS_FAILED,
		error,
		errorMessage,
	};
}

export function fetchTvsSearchedAction(query) {
	return {
		type: START_FETCH_TVS_SEARCHED,
		categoryId: TV,
		query,
		limit: 30,
	};
}

export function fetchTvsSearchedSuccessAction(videos, numOfItems) {
	return {
		type: FETCH_TVS_SEARCHED_SUCCESS,
		videos,
		numOfItems,
	};
}

export function fetchTvsSearchedFailedAction(error, errorMessage) {
	return {
		type: FETCH_TVS_SEARCHED_FAILED,
		error,
		errorMessage,
	};
}

export function fetchTvsRecommendedAction(videoId, limit, page) {
	return {
		type: START_FETCH_TVS_RECOMMENDED,
		videoId,
		limit: limit || 10,
		page,
	};
}

export function fetchTvsRecommendedSuccessAction(videos, numOfItems) {
	return {
		type: FETCH_TVS_RECOMMENDED_SUCCESS,
		videos,
		numOfItems,
	};
}

export function fetchTvsRecommendedFailedAction(error, errorMessage) {
	return {
		type: FETCH_TVS_RECOMMENDED_FAILED,
		error,
		errorMessage,
	};
}
