import { ofType } from 'redux-observable';
import {
	map,
	switchMap,
	catchError,
} from 'rxjs/operators';

import { actionTypes, videoEpisodeActions, notifyActions } from '../../../controller';
import { rxjsApiFetcher } from '../../../lib/api-utils';
import { catchErrorForEpics } from '../../../../lib/epic-utils';

const {
	START_FETCH_VIDEO_EPISODE,
} = actionTypes;
const {
	fetchVideoEpisodeSuccessAction, fetchVideoEpisodeFailedAction,
} = videoEpisodeActions;
const {
	notifyErrorAction,
} = notifyActions;

// eslint-disable-next-line import/prefer-default-export
export function fetchVideoEpisodeEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_VIDEO_EPISODE),
		switchMap(({ videoId, episodeId }) =>
			rxjsApiFetcher
				.get(`/videos/id=${videoId}/episodes/id=${episodeId}`)
				.pipe(
					map(payload => fetchVideoEpisodeSuccessAction(payload.response)),
					catchError(error => catchErrorForEpics(error, fetchVideoEpisodeFailedAction, notifyErrorAction)),
				),
		),
	);
}
