import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './style.styl';

const propTypes = {
	className: PropTypes.string,
	render: PropTypes.func,
};

const PREFIX_CLASS = 'nav-top-sub-bar';

const NavTopSubBar = ({
	className,
	render,
}) => (
	<div className={cx(PREFIX_CLASS, className)}>
		{render(PREFIX_CLASS)}
	</div>
);

NavTopSubBar.propTypes = propTypes;

export default NavTopSubBar;
