import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { userActions, notifyActions } from '../../controller';
import { LoadingStatusEnum } from '../../../lib/enums';
import { usePrevious } from '../../../lib/react-utils';

const {
	fetchWatchCountRemainingAction,
} = userActions;
const { notifyInfoAction } = notifyActions;
const { SUCCESS, LOADING } = LoadingStatusEnum;
const propTypes = {
	onNavigate: PropTypes.func,
	videoId: PropTypes.string,
	comicId: PropTypes.string,
	episodeId: PropTypes.string,
};

const getDisplayName = WrappedComponent => WrappedComponent.displayName || WrappedComponent.name || 'Component';

const withWatchCountLimit = WrappedComponent => {
	const WithWatchCountLimitComponent = ({
		onNavigate,
		videoId,
		comicId,
		episodeId,
		...restProps
	}) => {
		const dispatch = useDispatch();

		useEffect(() => {
			dispatch(fetchWatchCountRemainingAction());
		}, [videoId, comicId, episodeId]);

		const userReducer = useSelector(state => state.user);
		const watchCountRemaining = userReducer.get('watchCountRemaining');
		const watchCountRemainingLoadingStatus = userReducer.get('watchCountRemainingLoadingStatus');
		const prevWatchCountRemainingLoadingStatus = usePrevious(watchCountRemainingLoadingStatus);

		useEffect(() => {
			if (
				watchCountRemainingLoadingStatus === SUCCESS
				&& prevWatchCountRemainingLoadingStatus === LOADING
			) {
				if (watchCountRemaining === 0) {
					dispatch(notifyInfoAction('无剩余观赏次数，请消费后继续观看。'));
					onNavigate('/');
				}
			}
		}, [watchCountRemaining, watchCountRemainingLoadingStatus]);

		if (watchCountRemaining === 0) {
			return null;
		}

		return (
			<WrappedComponent
				videoId={videoId}
				comicId={comicId}
				episodeId={episodeId}
				onNavigate={onNavigate}
				{...restProps}
			/>
		);
	};

	WithWatchCountLimitComponent.propTypes = propTypes;
	WithWatchCountLimitComponent.displayName = `withWatchCountLimit(${getDisplayName(WrappedComponent)})`;

	return WithWatchCountLimitComponent;
};

withWatchCountLimit.propTypes = propTypes;

export default withWatchCountLimit;
