import { ofType } from 'redux-observable';
import {
	map,
	switchMap,
	mergeMap,
	catchError,
} from 'rxjs/operators';

import { actionTypes, userActions, notifyActions } from '../../../controller';
import { rxjsApiFetcher } from '../../../lib/api-utils';
import { catchErrorForEpics } from '../../../../lib/epic-utils';
import { objectFilter, objectFilterOptionEnums } from '../../../../lib/object-utils';

const {
	SKIP_NULL,
	SKIP_UNDEFINED,
	SKIP_NAN,
	SKIP_EMPTY_STRING,
} = objectFilterOptionEnums;
const options = [SKIP_NULL, SKIP_UNDEFINED, SKIP_NAN, SKIP_EMPTY_STRING];

const {
	START_FETCH_ME,
	START_PATCH_CDN_ENDPOINTS,
	START_FETCH_FAVORITE_VIDEOS,
	START_ADD_FAVORITE_VIDEO,
	START_REMOVE_FAVORITE_VIDEO,
	START_REMOVE_ALL_FAVORITE_VIDEO,
	START_FETCH_FAVORITE_DETAILED_VIDEOS,
	START_FETCH_VIEWED_VIDEO_HISTORY,
	START_FETCH_WATCH_COUNT_REMAINING,
	START_PATCH_VIDEO_WATCH_TIMES,
} = actionTypes;
const {
	fetchMeSuccessAction, fetchMeFailedAction,
	patchCDNEndpointsSuccessAction, patchCDNEndpointsFailedAction,
	fetchFavoriteVideosAction, fetchFavoriteVideosSuccessAction, fetchFavoriteVideosFailedAction,
	addFavoriteVideoSuccessAction, addFavoriteVideoFailedAction,
	removeFavoriteVideoSuccessAction, removeFavoriteVideoFailedAction,
	removeAllFavoriteVideoSuccessAction, removeAllFavoriteVideoFailedAction,
	fetchFavoriteDetailedVideosSuccessAction, fetchFavoriteDetailedVideosFailedAction,
	fetchViewedVideoHistorySuccessAction, fetchViewedVideoHistoryFailedAction,
	fetchWatchCountRemainingSuccessAction, fetchWatchCountRemainingFailedAction,
	patchVideoWatchTimesSuccessAction, patchVideoWatchTimesFailedAction,
} = userActions;
const {
	notifyErrorAction,
} = notifyActions;

export function fetchMeEpic(action$) {
	return action$.pipe(
		ofType(START_FETCH_ME),
		switchMap(() =>
			rxjsApiFetcher
				.get('users/id=me')
				.pipe(
					map(payload => fetchMeSuccessAction(payload.response)),
					catchError(error => catchErrorForEpics(error, fetchMeFailedAction, notifyErrorAction)),
				),
		),
	);
}

export function patchCDNEndpointsEpic($action) {
	return $action.pipe(
		ofType(START_PATCH_CDN_ENDPOINTS),
		switchMap(({ cdnEndpointId }) =>
			rxjsApiFetcher
				.patch(`/users/id=me/cdn-endpoints/id=${cdnEndpointId}`)
				.pipe(
					map(() => patchCDNEndpointsSuccessAction()),
					catchError(error => catchErrorForEpics(error, patchCDNEndpointsFailedAction, notifyErrorAction)),
				),
		),
	);
}

export function fetchFavoriteVideosEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_FAVORITE_VIDEOS),
		switchMap(({ details }) =>
			rxjsApiFetcher
				.get('/users/id=me/favorite-videos', { queries: {
					details,
				} })
				.pipe(
					map(payload => fetchFavoriteVideosSuccessAction(payload.response)),
					catchError(error => catchErrorForEpics(error, fetchFavoriteVideosFailedAction, notifyErrorAction)),
				),
		),
	);
}

export function addFavoriteVideoEpic($action) {
	return $action.pipe(
		ofType(START_ADD_FAVORITE_VIDEO),
		switchMap(({ videoId }) =>
			rxjsApiFetcher
				.post('/users/id=me/favorite-videos', { videoId })
				.pipe(
					mergeMap(() => [
						addFavoriteVideoSuccessAction(),
						fetchFavoriteVideosAction(),
					]),
					catchError(error => catchErrorForEpics(error, addFavoriteVideoFailedAction, notifyErrorAction)),
				),
		),
	);
}

export function removeFavoriteVideoEpic($action) {
	return $action.pipe(
		ofType(START_REMOVE_FAVORITE_VIDEO),
		switchMap(({ videoId }) =>
			rxjsApiFetcher
				.delete(`/users/id=me/favorite-videos/video-id=${videoId}`)
				.pipe(
					mergeMap(() => [
						removeFavoriteVideoSuccessAction(),
						fetchFavoriteVideosAction(),
					]),
					catchError(error => catchErrorForEpics(error, removeFavoriteVideoFailedAction, notifyErrorAction)),
				),
		),
	);
}

export function removeAllFavoriteVideoEpic($action) {
	return $action.pipe(
		ofType(START_REMOVE_ALL_FAVORITE_VIDEO),
		switchMap(({ categoryId }) =>
			rxjsApiFetcher
				.delete(`/users/id=me/favorite-videos/category-id=${categoryId}`)
				.pipe(
					mergeMap(() => [
						removeAllFavoriteVideoSuccessAction(),
						fetchFavoriteVideosAction(),
					]),
					catchError(error => catchErrorForEpics(error, removeAllFavoriteVideoFailedAction, notifyErrorAction)),
				),
		),
	);
}

export function fetchFavoriteDetailedVideosEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_FAVORITE_DETAILED_VIDEOS),
		switchMap(({ categoryId, details }) =>
			rxjsApiFetcher
				.get('/users/id=me/favorite-videos', { queries: {
					details,
					categoryId,
				} })
				.pipe(
					map(payload => fetchFavoriteDetailedVideosSuccessAction(payload.response)),
					catchError(error => catchErrorForEpics(error, fetchFavoriteDetailedVideosFailedAction, notifyErrorAction)),
				),
		),
	);
}

export function fetchViewedVideoHistoryEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_VIEWED_VIDEO_HISTORY),
		switchMap(({ categoryId }) =>
			rxjsApiFetcher
				.get('/users/id=me/recently-viewed-videos', { queries: {
					categoryId,
				} })
				.pipe(
					map(payload => fetchViewedVideoHistorySuccessAction(payload.response)),
					catchError(error => catchErrorForEpics(error, fetchViewedVideoHistoryFailedAction, notifyErrorAction)),
				),
		),
	);
}

export function fetchWatchCountRemainingEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_WATCH_COUNT_REMAINING),
		switchMap(() =>
			rxjsApiFetcher
				.get('/users/id=me/watch-count-remaining')
				.pipe(
					map(({ response }) => fetchWatchCountRemainingSuccessAction(response.watchCountRemaining)),
					catchError(error => catchErrorForEpics(error, fetchWatchCountRemainingFailedAction, notifyErrorAction)),
				),
		),
	);
}

export function patchVideoWatchTimesEpic($action) {
	return $action.pipe(
		ofType(START_PATCH_VIDEO_WATCH_TIMES),
		switchMap(({ videoId, watchTime, episodeId }) =>
			rxjsApiFetcher
				.patch(`/users/id=me/video-watch-times/id=${videoId}`, objectFilter({
					watchTime,
					episodeId,
				}, options))
				.pipe(
					map(() => patchVideoWatchTimesSuccessAction()),
					catchError(error => catchErrorForEpics(error, patchVideoWatchTimesFailedAction, notifyErrorAction)),
				),
		),
	);
}
