import {
	createStore as reduxCreateStore,
	applyMiddleware,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
import {
	epics,
	reducer,
	middlewares as reduxMiddlewares,
} from './redux';
import { getMode } from '../config';

const {
	epicMiddleware,
	loggerMiddleWare,
	notifyHandlingMiddleware,
	jwtMiddleware,
} = reduxMiddlewares;

const mode = getMode();

export function createStore(initState = {}) {
	const applyMiddlewareList = [
		epicMiddleware,
		notifyHandlingMiddleware,
		jwtMiddleware,
	];
	let store;

	if (mode === 'production' || mode === 'pre-production') {
		store = reduxCreateStore(reducer, initState, applyMiddleware(...applyMiddlewareList));
	} else {
		applyMiddlewareList.push(loggerMiddleWare);

		store = reduxCreateStore(reducer, initState, composeWithDevTools(applyMiddleware(...applyMiddlewareList)));
	}

	epicMiddleware.run(epics);

	return store;
}

export const StoreProvider = Provider;
