import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bannerActions } from '../../../controller';

const { fetchBannersAction } = bannerActions;

const useBanners = categoryId => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchBannersAction(categoryId));
	}, []);

	const bannerReducer = useSelector(state => state.banner);
	const banners = bannerReducer.get('banners').toJS();

	return banners;
};

export default useBanners;
