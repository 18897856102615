import { ofType } from 'redux-observable';
import {
	map,
	switchMap,
	catchError,
} from 'rxjs/operators';

import { actionTypes, bannerActions, notifyActions } from '../../../controller';
import { rxjsApiFetcher } from '../../../lib/api-utils';
import { catchErrorForEpics } from '../../../../lib/epic-utils';

const {
	START_FETCH_BANNERS,
} = actionTypes;
const {
	fetchBannersSuccessAction, fetchBannersFailedAction,
} = bannerActions;
const {
	notifyErrorAction,
} = notifyActions;

// eslint-disable-next-line import/prefer-default-export
export function fetchBannersEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_BANNERS),
		switchMap(({ categoryId }) =>
			rxjsApiFetcher
				.get('/banners', { queries: {
					categoryId,
				} })
				.pipe(
					map(payload => fetchBannersSuccessAction(payload.response)),
					catchError(error => catchErrorForEpics(error, fetchBannersFailedAction, notifyErrorAction)),
				),
		),
	);
}
