import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Input } from 'ljit-react-components';
import { SearchSvg } from '../../../assets/icons';
import './style.styl';

const propTypes = {
	className: PropTypes.string,
	onSearch: PropTypes.func,
};

const PREFIX_CLASS = 'search-bar';

const SearchBar = ({
	className,
	onSearch,
	...inputProps
}) => {
	const [value, setValue] = useState('');
	const _handleChange = event => {
		setValue(event.target.value);
	};
	const _handleSearch = () => {
		onSearch(value);
		setValue('');
	};

	return (
		<Input
			className={cx(PREFIX_CLASS, className)}
			value={value}
			placeholder="请输入关键字..."
			suffix={<SearchSvg className={`${PREFIX_CLASS}__button`} onClick={_handleSearch}/>}
			onChange={_handleChange}
			onPressEnter={_handleSearch}
			{...inputProps}
		/>
	);
};

SearchBar.propTypes = propTypes;

export default SearchBar;
