import actionTypes from './action-types';

const {
	START_FETCH_VIDEO_CATEGORIES,
	FETCH_VIDEO_CATEGORIES_SUCCESS,
	FETCH_VIDEO_CATEGORIES_FAILED,
} = actionTypes;

export function fetchVideoCategoriesAction() {
	return {
		type: START_FETCH_VIDEO_CATEGORIES,
	};
}

export function fetchVideoCategoriesSuccessAction(categories) {
	return {
		type: FETCH_VIDEO_CATEGORIES_SUCCESS,
		categories,
	};
}

export function fetchVideoCategoriesFailedAction(error, errorMessage) {
	return {
		type: FETCH_VIDEO_CATEGORIES_FAILED,
		error,
		errorMessage,
	};
}
