import actionTypes from './action-types';

const {
	START_FETCH_CDN_ENDPOINTS,
	FETCH_CDN_ENDPOINTS_SUCCESS,
	FETCH_CDN_ENDPOINTS_FAILED,
} = actionTypes;

export function fetchCDNEndpointAction() {
	return {
		type: START_FETCH_CDN_ENDPOINTS,
	};
}

export function fetchCDNEndpointSuccessAction(cdnEndpoints) {
	return {
		type: FETCH_CDN_ENDPOINTS_SUCCESS,
		cdnEndpoints,
	};
}

export function fetchCDNEndpointFailedAction(error, errorMessage) {
	return {
		type: FETCH_CDN_ENDPOINTS_FAILED,
		error,
		errorMessage,
	};
}
