import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Menu } from 'ljit-react-components';
import { CloseSvg, FavoriteWhiteHollowSvg, HistorySvg } from '../../../../assets/icons';
import { RouteKeyEnums } from '../../../routes';
import { SortEnum } from '../../../lib/enum';

import './style.styl';

const { SubMenu, Item: MenuItem } = Menu;
const {
	LONG_VIDEO, LONG_VIDEO_SEARCH,
	SHORT_VIDEO, SHORT_VIDEO_SEARCH,
	MOVIE, MOVIE_SEARCH,
	TV, TV_SEARCH,
	COMIC, COMIC_SEARCH,
	FAVORITE,
	HISTORY,
} = RouteKeyEnums;
const { WEEKLY, MONTHLY, THREE_MONTHLY, YEAR } = SortEnum;

const propTypes = {
	className: PropTypes.string,
	isOpen: PropTypes.bool,
	user: PropTypes.node,
	location: PropTypes.object,
	onClose: PropTypes.func,
	onClickMenuItem: PropTypes.func,
};

const PREFIX_CLASS = 'nav-sider';

const NavSider = ({
	className,
	isOpen,
	user,
	location,
	onClose,
	onClickMenuItem,
}) => {
	const [openKeys, setOpenKeys] = useState([]);

	const _handleMenuItemSelect = ({ key }) => {
		onClickMenuItem(key);
	};

	const _handleSubMenuOpenChange = openKeys => {
		setOpenKeys(openKeys);
	};

	return (
		<aside className={cx(PREFIX_CLASS, className, { [`${PREFIX_CLASS}--active`]: isOpen })}>
			<div className={`${PREFIX_CLASS}__close-button`}>
				<CloseSvg onClick={onClose}/>
			</div>
			<Menu
				selectable
				className={`${PREFIX_CLASS}__menu`}
				openKeys={openKeys}
				selectedKeys={[location.pathname]}
				onMenuItemSelect={_handleMenuItemSelect}
				onSubMenuOpenChange={_handleSubMenuOpenChange}
			>
				<SubMenu
					key="personal-setting"
					title={user}
				>
					<MenuItem key={FAVORITE}>
						<div
							className={`${PREFIX_CLASS}__item`}
						>
							<FavoriteWhiteHollowSvg className={`${PREFIX_CLASS}__user__icon`}/>
							<span>我的收藏</span>
						</div>
					</MenuItem>
					<MenuItem key={HISTORY}>
						<div
							className={`${PREFIX_CLASS}__item`}
						>
							<HistorySvg className={`${PREFIX_CLASS}__user__icon`}/>
							<span>观看纪录</span>
						</div>
					</MenuItem>
				</SubMenu>
				<div className={`${PREFIX_CLASS}__separate-line`}/>
				<div className={`${PREFIX_CLASS}__category-title`}>类型</div>
				<SubMenu key={LONG_VIDEO} title="成人長片">
					<MenuItem key={`${LONG_VIDEO_SEARCH}/${WEEKLY}`}>
						<span>
							周排行
						</span>
					</MenuItem>
					<MenuItem key={`${LONG_VIDEO_SEARCH}/${MONTHLY}`}>
						<span>
							月排行
						</span>
					</MenuItem>
					<MenuItem key={`${LONG_VIDEO_SEARCH}/${THREE_MONTHLY}`}>
						<span>
							人氣排行
						</span>
					</MenuItem>
				</SubMenu>
				<SubMenu key={SHORT_VIDEO} title="成人短片">
					<MenuItem key={`${SHORT_VIDEO_SEARCH}/${WEEKLY}`}>
						<span>
							周排行
						</span>
					</MenuItem>
					<MenuItem key={`${SHORT_VIDEO_SEARCH}/${MONTHLY}`}>
						<span>
							月排行
						</span>
					</MenuItem>
					<MenuItem key={`${SHORT_VIDEO_SEARCH}/${THREE_MONTHLY}`}>
						<span>
							人氣排行
						</span>
					</MenuItem>
				</SubMenu>
				<SubMenu key={COMIC} title="成人漫画">
					<MenuItem key={`${COMIC_SEARCH}/${WEEKLY}`}>
						<span>
							周排行
						</span>
					</MenuItem>
					<MenuItem key={`${COMIC_SEARCH}/${MONTHLY}`}>
						<span>
							月排行
						</span>
					</MenuItem>
					<MenuItem key={`${COMIC_SEARCH}/${THREE_MONTHLY}`}>
						<span>
							人氣排行
						</span>
					</MenuItem>
				</SubMenu>
				<SubMenu key={TV} title="电视剧">
					<MenuItem key={`${TV_SEARCH}/${WEEKLY}`}>
						<span>
							周排行
						</span>
					</MenuItem>
					<MenuItem key={`${TV_SEARCH}/${MONTHLY}`}>
						<span>
							月排行
						</span>
					</MenuItem>
					<MenuItem key={`${TV_SEARCH}/${YEAR}`}>
						<span>
							人氣排行
						</span>
					</MenuItem>
				</SubMenu>
				<SubMenu key={MOVIE} title="电影">
					<MenuItem key={`${MOVIE_SEARCH}/${WEEKLY}`}>
						<span>
							周排行
						</span>
					</MenuItem>
					<MenuItem key={`${MOVIE_SEARCH}/${MONTHLY}`}>
						<span>
							月排行
						</span>
					</MenuItem>
					<MenuItem key={`${MOVIE_SEARCH}/${YEAR}`}>
						<span>
							人氣排行
						</span>
					</MenuItem>
				</SubMenu>
			</Menu>
		</aside>
	);
};

NavSider.propTypes = propTypes;

export default NavSider;
