import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './style.styl';

const propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	onClick: PropTypes.func,
};

const PREFIX_CLASS = 'nav-item';

const NavItem = ({
	className,
	children,
	onClick,
}) => (
	<div className={cx(PREFIX_CLASS, className)} onClick={onClick}>
		{children}
	</div>
);

NavItem.propTypes = propTypes;

export default NavItem;
