import actionTypes from './action-types';
import { CategoryEnum } from '../../lib/category-enum';
import { SortEnum } from '../../lib/enum';

const {
	START_FETCH_MOVIES_RECENTLY,
	FETCH_MOVIES_RECENTLY_SUCCESS,
	FETCH_MOVIES_RECENTLY_FAILED,

	START_FETCH_HOT_MOVIES,
	FETCH_HOT_MOVIES_SUCCESS,
	FETCH_HOT_MOVIES_FAILED,

	START_FETCH_FAVORITE_MOVIES,
	FETCH_FAVORITE_MOVIES_SUCCESS,
	FETCH_FAVORITE_MOVIES_FAILED,

	START_FETCH_MOVIES_SEARCHED,
	FETCH_MOVIES_SEARCHED_SUCCESS,
	FETCH_MOVIES_SEARCHED_FAILED,

	START_FETCH_MOVIES_RECOMMENDED,
	FETCH_MOVIES_RECOMMENDED_SUCCESS,
	FETCH_MOVIES_RECOMMENDED_FAILED,
} = actionTypes;

const { MOVIE } = CategoryEnum;
const { MONTHLY } = SortEnum;

export function fetchMoviesRecentlyAction() {
	return {
		type: START_FETCH_MOVIES_RECENTLY,
		categoryId: MOVIE,
		limit: 10,
	};
}

export function fetchMoviesRecentlySuccessAction(videos) {
	return {
		type: FETCH_MOVIES_RECENTLY_SUCCESS,
		videos,
	};
}

export function fetchMoviesRecentlyFailedAction(error, errorMessage) {
	return {
		type: FETCH_MOVIES_RECENTLY_FAILED,
		error,
		errorMessage,
	};
}

export function fetchHotMoviesAction() {
	return {
		type: START_FETCH_HOT_MOVIES,
		categoryId: MOVIE,
		sort: MONTHLY,
		limit: 10,
	};
}

export function fetchHotMoviesSuccessAction(videos) {
	return {
		type: FETCH_HOT_MOVIES_SUCCESS,
		videos,
	};
}

export function fetchHotMoviesFailedAction(error, errorMessage) {
	return {
		type: FETCH_HOT_MOVIES_FAILED,
		error,
		errorMessage,
	};
}

export function fetchFavoriteMoviesAction() {
	return {
		type: START_FETCH_FAVORITE_MOVIES,
		categoryId: MOVIE,
	};
}

export function fetchFavoriteMoviesSuccessAction(videos) {
	return {
		type: FETCH_FAVORITE_MOVIES_SUCCESS,
		videos,
	};
}

export function fetchFavoriteMoviesFailedAction(error, errorMessage) {
	return {
		type: FETCH_FAVORITE_MOVIES_FAILED,
		error,
		errorMessage,
	};
}

export function fetchMoviesSearchedAction(query) {
	return {
		type: START_FETCH_MOVIES_SEARCHED,
		categoryId: MOVIE,
		query,
		limit: 30,
	};
}

export function fetchMoviesSearchedSuccessAction(videos, numOfItems) {
	return {
		type: FETCH_MOVIES_SEARCHED_SUCCESS,
		videos,
		numOfItems,
	};
}

export function fetchMoviesSearchedFailedAction(error, errorMessage) {
	return {
		type: FETCH_MOVIES_SEARCHED_FAILED,
		error,
		errorMessage,
	};
}

export function fetchMoviesRecommendedAction(videoId, limit, page) {
	return {
		type: START_FETCH_MOVIES_RECOMMENDED,
		videoId,
		limit: limit || 10,
		page,
	};
}

export function fetchMoviesRecommendedSuccessAction(videos, numOfItems) {
	return {
		type: FETCH_MOVIES_RECOMMENDED_SUCCESS,
		videos,
		numOfItems,
	};
}

export function fetchMoviesRecommendedFailedAction(error, errorMessage) {
	return {
		type: FETCH_MOVIES_RECOMMENDED_FAILED,
		error,
		errorMessage,
	};
}
