import { Map, List } from 'immutable';
import { LoadingStatusEnum } from '../../../../../lib/enums';
import { actionTypes } from '../../../../controller';

const {
	START_FETCH_BANNERS,
	FETCH_BANNERS_SUCCESS,
	FETCH_BANNERS_FAILED,
} = actionTypes;

const {
	NONE,
	LOADING,
	SUCCESS,
	FAILED,
} = LoadingStatusEnum;

// Example
// Map({
// 	"banners": List([
// 		{
// 			"id": "5b73f6c133d3cdc4e42c94fb",
// 			"name": "航海王", //片名
// 			"bannerImage" : "/develop/comic/航海王/航海王.png", //banner路徑
// 			//(漫画/电视剧)集数资讯
// 			"videoEpisodeOrder": [
// 				"5c0e28661ee1f01bfb794ac2",
// 				"5c0e28661ee1f01bfb794ac3",
// 			],
// 		},
// 	]),
// });

const initalState = Map({
	banners: List([]),
	bannersLoadingStatus: NONE,
	bannersLoadingStatusMessage: '',
});

export default function banner(state = initalState, action) {
	switch (action.type) {
		case START_FETCH_BANNERS:
			return state.set('bannersLoadingStatus', LOADING);

		case FETCH_BANNERS_SUCCESS:
			return state
				.set('banners', List(action.banners))
				.set('bannersLoadingStatus', SUCCESS);

		case FETCH_BANNERS_FAILED:
			return state
				.set('bannersLoadingStatus', FAILED)
				.set('bannersLoadingStatusMessage', action.errorMessage);

		default:
			return state;
	}
}
