import { Map, List } from 'immutable';
import { LoadingStatusEnum } from '../../../../lib/enums';
import { actionTypes } from '../../../controller';

const {
	START_FETCH_ME,
	FETCH_ME_SUCCESS,
	FETCH_ME_FAILED,

	START_PATCH_CDN_ENDPOINTS,
	PATCH_CDN_ENDPOINTS_SUCCESS,
	PATCH_CDN_ENDPOINTS_FAILED,

	START_FETCH_FAVORITE_VIDEOS,
	FETCH_FAVORITE_VIDEOS_SUCCESS,
	FETCH_FAVORITE_VIDEOS_FAILED,

	START_ADD_FAVORITE_VIDEO,
	ADD_FAVORITE_VIDEO_SUCCESS,
	ADD_FAVORITE_VIDEO_FAILED,

	START_REMOVE_FAVORITE_VIDEO,
	REMOVE_FAVORITE_VIDEO_SUCCESS,
	REMOVE_FAVORITE_VIDEO_FAILED,

	START_FETCH_FAVORITE_DETAILED_VIDEOS,
	FETCH_FAVORITE_DETAILED_VIDEOS_SUCCESS,
	FETCH_FAVORITE_DETAILED_VIDEOS_FAILED,

	START_REMOVE_ALL_FAVORITE_VIDEO,
	REMOVE_ALL_FAVORITE_VIDEO_SUCCESS,
	REMOVE_ALL_FAVORITE_VIDEO_FAILED,

	START_FETCH_VIEWED_VIDEO_HISTORY,
	FETCH_VIEWED_VIDEO_HISTORY_SUCCESS,
	FETCH_VIEWED_VIDEO_HISTORY_FAILED,

	START_FETCH_WATCH_COUNT_REMAINING,
	FETCH_WATCH_COUNT_REMAINING_SUCCESS,
	FETCH_WATCH_COUNT_REMAINING_FAILED,

	START_PATCH_VIDEO_WATCH_TIMES,
	PATCH_VIDEO_WATCH_TIMES_SUCCESS,
	PATCH_VIDEO_WATCH_TIMES_FAILED,
} = actionTypes;

const {
	NONE,
	LOADING,
	SUCCESS,
	FAILED,
} = LoadingStatusEnum;

// Example

const initalState = Map({
	me: Map({}),
	meLoadingStatus: NONE,
	meLoadingStatusMessage: '',

	cdnEndpointsLoadingStatus: NONE,
	cdnEndpointsLoadingStatusMessage: '',

	favoriteVideos: List([]),
	favoriteVideosLoadingStatus: NONE,
	favoriteVideosLoadingStatusMessage: '',

	operateFavoriteVideosLoadingStatus: NONE,
	operateFavoriteVideosLoadingStatusMessage: '',

	favoriteDetailedVideos: List([]),
	favoriteDetailedVideosLoadingStatus: NONE,
	favoriteDetailedVideosLoadingStatusMessage: '',

	viewedVideoHistory: List([]),
	viewedVideoHistoryLoadingStatus: NONE,
	viewedVideoHistoryLoadingStatusMessage: '',

	watchCountRemaining: 0,
	watchCountRemainingLoadingStatus: NONE,
	watchCountRemainingLoadingStatusMessage: '',

	patchVideoWatchTimesLoadingStatus: NONE,
	patchVideoWatchTimesLoadingStatusMessage: '',
});

export default function user(state = initalState, action) {
	switch (action.type) {
		case START_FETCH_ME:
			return state.set('meLoadingStatus', LOADING);

		case FETCH_ME_SUCCESS:
			return state
				.set('me', Map(action.me))
				.set('meLoadingStatus', SUCCESS);

		case FETCH_ME_FAILED:
			return state
				.set('meLoadingStatus', FAILED)
				.set('meLoadingStatusMessage', action.errorMessage);

		case START_PATCH_CDN_ENDPOINTS:
			return state.set('cdnEndpointsLoadingStatus', LOADING);

		case PATCH_CDN_ENDPOINTS_SUCCESS:
			return state.set('cdnEndpointsLoadingStatus', SUCCESS);

		case PATCH_CDN_ENDPOINTS_FAILED:
			return state
				.set('cdnEndpointsLoadingStatus', FAILED)
				.set('cdnEndpointsLoadingStatusMessage', action.errorMessage);

		case START_FETCH_FAVORITE_VIDEOS:
			return state.set('favoriteVideosLoadingStatus', LOADING);

		case FETCH_FAVORITE_VIDEOS_SUCCESS:
			return state
				.set('favoriteVideos', List(action.videos))
				.set('favoriteVideosLoadingStatus', SUCCESS);

		case FETCH_FAVORITE_VIDEOS_FAILED:
			return state
				.set('favoriteVideosLoadingStatus', FAILED)
				.set('favoriteVideosLoadingStatusMessage', action.errorMessage);

		case START_ADD_FAVORITE_VIDEO:
		case START_REMOVE_FAVORITE_VIDEO:
		case START_REMOVE_ALL_FAVORITE_VIDEO:
			return state.set('operateFavoriteVideosLoadingStatus', LOADING);

		case ADD_FAVORITE_VIDEO_SUCCESS:
		case REMOVE_FAVORITE_VIDEO_SUCCESS:
		case REMOVE_ALL_FAVORITE_VIDEO_SUCCESS:
			return state.set('operateFavoriteVideosLoadingStatus', SUCCESS);

		case ADD_FAVORITE_VIDEO_FAILED:
		case REMOVE_FAVORITE_VIDEO_FAILED:
		case REMOVE_ALL_FAVORITE_VIDEO_FAILED:
			return state
				.set('operateFavoriteVideosLoadingStatus', FAILED)
				.set('operateFavoriteVideosLoadingStatusMessage', action.errorMessage);

		case START_FETCH_FAVORITE_DETAILED_VIDEOS:
			return state.set('favoriteDetailedVideosLoadingStatus', LOADING);

		case FETCH_FAVORITE_DETAILED_VIDEOS_SUCCESS:
			return state
				.set('favoriteDetailedVideos', List(action.videos))
				.set('favoriteDetailedVideosLoadingStatus', SUCCESS);

		case FETCH_FAVORITE_DETAILED_VIDEOS_FAILED:
			return state
				.set('favoriteDetailedVideosLoadingStatus', FAILED)
				.set('favoriteDetailedVideosLoadingStatusMessage', action.errorMessage);

		case START_FETCH_VIEWED_VIDEO_HISTORY:
			return state.set('viewedVideoHistoryLoadingStatus', LOADING);

		case FETCH_VIEWED_VIDEO_HISTORY_SUCCESS:
			return state
				.set('viewedVideoHistory', List(action.history))
				.set('viewedVideoHistoryLoadingStatus', SUCCESS);

		case FETCH_VIEWED_VIDEO_HISTORY_FAILED:
			return state
				.set('viewedVideoHistoryLoadingStatus', FAILED)
				.set('viewedVideoHistoryLoadingStatusMessage', action.errorMessage);

		case START_FETCH_WATCH_COUNT_REMAINING:
			return state.set('watchCountRemainingLoadingStatus', LOADING);

		case FETCH_WATCH_COUNT_REMAINING_SUCCESS:
			return state
				.set('watchCountRemaining', action.count)
				.set('watchCountRemainingLoadingStatus', SUCCESS);

		case FETCH_WATCH_COUNT_REMAINING_FAILED:
			return state
				.set('watchCountRemainingLoadingStatus', FAILED)
				.set('watchCountRemainingLoadingStatusMessage', action.errorMessage);

		case START_PATCH_VIDEO_WATCH_TIMES:
			return state.set('patchVideoWatchTimesLoadingStatus', LOADING);

		case PATCH_VIDEO_WATCH_TIMES_SUCCESS:
			return state
				.set('patchVideoWatchTimesLoadingStatus', SUCCESS);

		case PATCH_VIDEO_WATCH_TIMES_FAILED:
			return state
				.set('patchVideoWatchTimesLoadingStatus', FAILED)
				.set('patchVideoWatchTimesLoadingStatusMessage', action.errorMessage);

		default:
			return state;
	}
}
