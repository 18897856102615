import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { cdnEndpointActions } from '../../../controller';

const { fetchCDNEndpointAction } = cdnEndpointActions;

const useCDNEndpoints = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchCDNEndpointAction());
	}, []);

	const cdnEndpointReducer = useSelector(state => state.cdnEndpoint);
	const cdnEndpoints = cdnEndpointReducer.get('cdnEndpoints').toJS();

	return cdnEndpoints;
};

export default useCDNEndpoints;
