import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import {
	Layout,
	Menu,
	Dropdown,
	UserAvatar,
} from 'ljit-react-components';
import { connect } from 'ljit-store-connecter';
import { renderSwitches } from './route-utils';
import routes, { RouteKeyEnums } from '../routes';
import { NavTopBar, NavTopSubBar, NavSider, NavItem, NavLogo } from '../components/nav';
import SearchBar from '../components/search-bar';

import { CategoryEnum } from '../lib/category-enum';
import { useOnChangePage } from '../lib/hooks';
import { useMediaQuery } from '../../lib/hooks';
import { useFavoriteVideos } from '../features/store-hooks';
import GoToTop from '../components/go-to-top';

import {
	HamburgerSvg,
	SearchSvg,
	FavoriteWhiteHollowSvg,
	HistorySvg,
} from '../../assets/icons';
import UserSvgPath from '../../assets/icons/user.svg';

import './style.styl';

const { Header, Content } = Layout;
const { Item: MenuItem } = Menu;
const {
	ROOT, SEARCH,
	LONG_VIDEO, LONG_VIDEO_SEARCH,
	SHORT_VIDEO, SHORT_VIDEO_SEARCH,
	MOVIE, MOVIE_SEARCH,
	TV, TV_SEARCH,
	COMIC, COMIC_READ, COMIC_SEARCH,
	FAVORITE, HISTORY,
} = RouteKeyEnums;

const propTypes = {
	location: PropTypes.shape({
		pathname: PropTypes.string.isRequired,
		search: PropTypes.string,
	}).isRequired,
	history: PropTypes.object,
	auth: PropTypes.shape({
		isAuthed: PropTypes.bool.isRequired,
	}).isRequired,
	me: PropTypes.object,
};

const LayoutRoute = ({
	me,
	location,
	history,
	auth,
}) => {
	const { isAuthed } = auth;
	const isLaptopDeviceUp = useMediaQuery('(min-width: 901px)');
	const isMatchPath = keyPath => location.pathname.match(`^${keyPath}`);
	const isComicReadPage = location.pathname.match(`^${COMIC_READ}`);
	const queryParams = new URLSearchParams(location.search);
	const { username = '' } = me;

	const isMatchCategoryId = categoryId => queryParams.getAll('categoryId').indexOf(categoryId) > -1;

	const getCategoryId = () => {
		const path = location.pathname;

		if (path.match(`^${SHORT_VIDEO}`)) {
			return CategoryEnum.SHORT_VIDEO;
		}

		if (path.match(`^${MOVIE}`)) {
			return CategoryEnum.MOVIE;
		}

		if (path.match(`^${TV}`)) {
			return CategoryEnum.TV;
		}

		if (path.match(`^${COMIC}`)) {
			return CategoryEnum.COMIC;
		}

		if (location.search.match('categoryId')) {
			return queryParams.get('categoryId');
		}

		return CategoryEnum.LONG_VIDEO;
	};

	const [isDisplaySearch, setIsDisplaySearch] = useState(false);
	const [isSiderOpen, setIsSiderOpen] = useState(false);

	useFavoriteVideos();

	useOnChangePage(location.pathname, location.search);

	const onNavigate = (uri, options = { passProps: {}, search: '' }) => {
		history.push({
			pathname: uri,
			passProps: options.passProps,
			search: options.search,
		});
	};

	const _handleKeywordSearch = value => {
		const path = location.pathname;
		let searchPath = LONG_VIDEO_SEARCH;

		if (path === ROOT || path.match(`^${SEARCH}`)) {
			searchPath = SEARCH;
		}

		if (path.match(`^${SHORT_VIDEO}`) || isMatchCategoryId(CategoryEnum.SHORT_VIDEO)) {
			searchPath = SHORT_VIDEO_SEARCH;
		}

		if (path.match(`^${MOVIE}`) || isMatchCategoryId(CategoryEnum.MOVIE)) {
			searchPath = MOVIE_SEARCH;
		}

		if (path.match(`^${TV}`) || isMatchCategoryId(CategoryEnum.TV)) {
			searchPath = TV_SEARCH;
		}

		if (path.match(`^${COMIC}`) || isMatchCategoryId(CategoryEnum.COMIC)) {
			searchPath = COMIC_SEARCH;
		}

		onNavigate(searchPath, {
			search: `?keyword=${value}`,
		});
	};

	const _handleClickSiderMenuItem = path => {
		const isClickFavoriteOrHistory = path === FAVORITE || path === HISTORY;

		if (isClickFavoriteOrHistory) {
			onNavigate(path, {
				search: `?categoryId=${getCategoryId()}`,
			});
		} else {
			onNavigate(path);
		}

		setIsSiderOpen(false);
	};

	const _renderCategoryItems = prefixClass => (
		<>
			<NavItem
				key={ROOT}
				className={cx(`${prefixClass}__item`, {
					[`${prefixClass}__item--active`]: location.pathname === ROOT || isMatchPath(SEARCH),
				})}
				onClick={() => onNavigate(ROOT)}
			>
				首页
			</NavItem>
			<NavItem
				key={LONG_VIDEO}
				className={cx(`${prefixClass}__item`, {
					[`${prefixClass}__item--active`]: isMatchPath(LONG_VIDEO) || isMatchCategoryId(CategoryEnum.LONG_VIDEO),
				})}
				onClick={() => onNavigate(LONG_VIDEO)}
			>
				成人長片
			</NavItem>
			<NavItem
				key={SHORT_VIDEO}
				className={cx(`${prefixClass}__item`, {
					[`${prefixClass}__item--active`]: isMatchPath(SHORT_VIDEO) || isMatchCategoryId(CategoryEnum.SHORT_VIDEO),
				})}
				onClick={() => onNavigate(SHORT_VIDEO)}
			>
				成人短片
			</NavItem>
			<NavItem
				key={COMIC}
				className={cx(`${prefixClass}__item`, {
					[`${prefixClass}__item--active`]: isMatchPath(COMIC) || isMatchCategoryId(CategoryEnum.COMIC),
				})}
				onClick={() => onNavigate(COMIC)}
			>
				成人漫画
			</NavItem>
			<NavItem
				key={TV}
				className={cx(`${prefixClass}__item`, {
					[`${prefixClass}__item--active`]: isMatchPath(TV) || isMatchCategoryId(CategoryEnum.TV),
				})}
				onClick={() => onNavigate(TV)}
			>
				电视剧
			</NavItem>
			<NavItem
				key={MOVIE}
				className={cx(`${prefixClass}__item`, {
					[`${prefixClass}__item--active`]: isMatchPath(MOVIE) || isMatchCategoryId(CategoryEnum.MOVIE),
				})}
				onClick={() => onNavigate(MOVIE)}
			>
				电影
			</NavItem>
		</>
	);

	function _renderPublicLayout() {
		return (
			<Content>
				{renderSwitches(routes)}
			</Content>
		);
	}

	function _renderPrivateLayout() {
		return (
			<>
				<Header className={cx('layout__header', {
					'layout__header--hidden': isComicReadPage,
				})}
				>
					<NavTopBar
						renderNav={prefixClass => (
							isLaptopDeviceUp
								? (
									<>
										<div className={`${prefixClass}__categories`}>
											{_renderCategoryItems(`${prefixClass}__categories`)}
										</div>
										<NavLogo key="logo" className={`${prefixClass}__logo`} onClick={() => onNavigate('/')}/>
										<div className={`${prefixClass}__search-bar`}>
											<SearchBar onSearch={_handleKeywordSearch}/>
										</div>
										<Dropdown
											className={`${prefixClass}__personal-setting-dropdown`}
											dropdownContent={(
												<Menu>
													<MenuItem key={FAVORITE}>
														<FavoriteWhiteHollowSvg/>
														<Link
															to={{
																pathname: FAVORITE,
																search: `?categoryId=${getCategoryId()}`,
															}}
														>
															我的收藏
														</Link>
													</MenuItem>
													<MenuItem key={HISTORY}>
														<HistorySvg/>
														<Link
															to={{
																pathname: HISTORY,
																search: `?categoryId=${getCategoryId()}`,
															}}
														>
															观看纪录
														</Link>
													</MenuItem>
												</Menu>
											)}
											trigger={['click', 'hover']}
										>
											<div className={`${prefixClass}__user`}>
												<UserAvatar
													src={UserSvgPath}
													userName={username}
													size={UserAvatar.SizeEnums.DEFAULT}
												/>
											</div>
										</Dropdown>
									</>
								) : (
									<>
										<HamburgerSvg className={`${prefixClass}__sider-trigger`} onClick={() => setIsSiderOpen(true)}/>
										<NavLogo key="logo" className={`${prefixClass}__logo`} onClick={() => onNavigate('/')}/>
										<SearchSvg className={`${prefixClass}__search-bar-trigger`} onClick={() => setIsDisplaySearch(!isDisplaySearch)}/>
									</>
								)
						)}
					/>
					{
						!isLaptopDeviceUp
							&&	(
								<>
									<NavTopSubBar
										render={prefixClass => (
											isDisplaySearch
												? <SearchBar className={`${prefixClass}__search-bar`} onSearch={_handleKeywordSearch}/>
												: _renderCategoryItems(prefixClass)
										)}
									/>
									<NavSider
										isOpen={isSiderOpen}
										location={location}
										user={
											<UserAvatar
												className="nav-sider__user"
												src={UserSvgPath}
												userName={username}
												size={UserAvatar.SizeEnums.DEFAULT}
											/>
										}
										onClose={() => setIsSiderOpen(false)}
										onClickMenuItem={_handleClickSiderMenuItem}
									/>
								</>
							)
					}
				</Header>
				<Content className={cx('layout__content', {
					'layout__comic-content': isComicReadPage,
				})}
				>
					{renderSwitches(routes)}
				</Content>
				<GoToTop/>
			</>
		);
	}

	return (
		<Layout className="layout">
			{isAuthed ? _renderPrivateLayout() : _renderPublicLayout()}
		</Layout>
	);
};

LayoutRoute.propTypes = propTypes;

function mapStateToProps(state) {
	return {
		me: state.user.get('me').toObject(),
	};
}

function mapDispatchToProps(dispatch) {
	return {
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LayoutRoute));
