import React, { useRef, useEffect, useState } from 'react';
import * as loadable from 'react-loadable';
import { Loading } from 'ljit-react-components';

export function loadComponent(options) {
	return loadable({ loading: LoadingWrapper, ...options });
}

const LoadingWrapper = prop => {
	const { error } = prop;

	if (process.env.NODE_ENV !== 'production' && error) {
		console.error(error);
	}

	return <Loading/>;
};

export function usePrevious(value) {
	const ref = useRef();

	useEffect(() => {
		ref.current = value;
	});

	return ref.current;
}

export function useHover() {
	const [value, setValue] = useState(false);
	const ref = useRef(null);
	const handleMouseOver = () => setValue(true);
	const handleMouseOut = () => setValue(false);

	useEffect(() => {
		const node = ref.current;

		if (node) {
			node.addEventListener('mouseover', handleMouseOver);
			node.addEventListener('mouseout', handleMouseOut);

			return () => {
				node.removeEventListener('mouseover', handleMouseOver);
				node.removeEventListener('mouseout', handleMouseOut);
			};
		}
	});

	return [ref, value];
}

export function useKeyPress(targetKey) {
	const [keyPressed, setKeyPressed] = useState(false);

	function downHandler({ key }) {
		if (key === targetKey) {
			setKeyPressed(true);
		}
	}

	const upHandler = ({ key }) => {
		if (key === targetKey) {
			setKeyPressed(false);
		}
	};

	useEffect(() => {
		window.addEventListener('keydown', downHandler);
		window.addEventListener('keyup', upHandler);

		return () => {
			window.removeEventListener('keydown', downHandler);
			window.removeEventListener('keyup', upHandler);
		};
	});

	return keyPressed;
}
