import { Map } from 'immutable';
import { LoadingStatusEnum } from '../../../../../lib/enums';
import { actionTypes } from '../../../../controller';

const {
	START_FETCH_VIDEO_DETAIL,
	FETCH_VIDEO_DETAIL_SUCCESS,
	FETCH_VIDEO_DETAIL_FAILED,
	CLEAR_VIDEO_DETAIL,
} = actionTypes;

const {
	NONE,
	LOADING,
	SUCCESS,
	FAILED,
} = LoadingStatusEnum;

// Example
// Map({
// 	"videoDetail": Map({
// 		"id": "5b73f6c133d3cdc4e42c94fb",
// 		"categoryId": "5b73eb8886d00574944a9dc5",
// 		"name": "航海王", //片名
// 		"actorNames": ["魯夫"], //主演
// 		//封面路徑
// 		"coverImage1" : "http://cdn3.aiz2t.cn/develop/comic/航海王/航海王.png?sign=xxxx&t=xxxx",
// 		"tagIds": ["5b73f2cc33d3cdc4e42c9519"],
// 		"manufacturer": "集英社", //廠商
// 		//簡介
// 		"description": "財富、名聲、勢力、擁有世界上的一切的「海賊王」哥爾·D·羅傑在即將遭到處刑前說了一段話：「想要我的財寶嗎？想要的話可以全部給你。去找吧！我把所有的財寶都放在那裡」！後來世界上的人們將這個寶藏稱作「大秘寶」（ONE PIECE）",
// 		//導演
// 		"directors": ["李不安"],
// 		"videoPath": "/AV/A65773/A65773.mp4", //影片路徑
// 		//(漫画/电视剧)集数资讯
// 		"videoEpisodeOrder": [{
// 			"id": "5c0e28661ee1f01bfb794ac2",
// 			"name": "第一集",
// 		}, {
// 			"id": "5c0e28661ee1f01bfb794ac3",
// 			"name": "第二集"
// 		}],
// 		// 漫画/电视剧)是否完結: finished / unfinished
// 		"finishStatus": "finished",
// 		// 觀看時間(秒)
// 		"lastViewedEpisodeId": "5c0e28661ee1f01bfb794ac3"
// 		"watchTime": 10,
// 		"launchedAt": "2018-07-24 05:36:30.957Z", //上架時間
// 	}),
// });

const initalState = Map({
	videoDetail: Map({}),
	videoDetailLoadingStatus: NONE,
	videoDetailLoadingStatusMessage: '',
});

export default function videoDetail(state = initalState, action) {
	switch (action.type) {
		case START_FETCH_VIDEO_DETAIL:
			return state.set('videoDetailLoadingStatus', LOADING);

		case FETCH_VIDEO_DETAIL_SUCCESS:
			return state
				.set('videoDetail', Map(action.detail))
				.set('videoDetailLoadingStatus', SUCCESS);

		case FETCH_VIDEO_DETAIL_FAILED:
			return state
				.set('videoDetailLoadingStatus', FAILED)
				.set('videoDetailLoadingStatusMessage', action.errorMessage);

		case CLEAR_VIDEO_DETAIL:
			return state
				.set('videoDetail', Map({}))
				.set('videoDetailLoadingStatus', NONE)
				.set('videoDetailLoadingStatusMessage', '');

		default:
			return state;
	}
}
