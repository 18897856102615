import actionTypes from './action-types';
import { CategoryEnum } from '../../lib/category-enum';
import { SortEnum } from '../../lib/enum';

const {
	START_FETCH_COMICS_RECENTLY,
	FETCH_COMICS_RECENTLY_SUCCESS,
	FETCH_COMICS_RECENTLY_FAILED,

	START_FETCH_HOT_COMICS,
	FETCH_HOT_COMICS_SUCCESS,
	FETCH_HOT_COMICS_FAILED,

	START_FETCH_FAVORITE_COMICS,
	FETCH_FAVORITE_COMICS_SUCCESS,
	FETCH_FAVORITE_COMICS_FAILED,

	START_FETCH_COMICS_SEARCHED,
	FETCH_COMICS_SEARCHED_SUCCESS,
	FETCH_COMICS_SEARCHED_FAILED,
} = actionTypes;

const { COMIC } = CategoryEnum;
const { MONTHLY } = SortEnum;

export function fetchComicsRecentlyAction() {
	return {
		type: START_FETCH_COMICS_RECENTLY,
		categoryId: COMIC,
		limit: 20,
	};
}

export function fetchComicsRecentlySuccessAction(comics) {
	return {
		type: FETCH_COMICS_RECENTLY_SUCCESS,
		comics,
	};
}

export function fetchComicsRecentlyFailedAction(error, errorMessage) {
	return {
		type: FETCH_COMICS_RECENTLY_FAILED,
		error,
		errorMessage,
	};
}

export function fetchHotComicsAction() {
	return {
		type: START_FETCH_HOT_COMICS,
		categoryId: COMIC,
		sort: MONTHLY,
		limit: 20,
	};
}

export function fetchHotComicsSuccessAction(comics) {
	return {
		type: FETCH_HOT_COMICS_SUCCESS,
		comics,
	};
}

export function fetchHotComicsFailedAction(error, errorMessage) {
	return {
		type: FETCH_HOT_COMICS_FAILED,
		error,
		errorMessage,
	};
}

export function fetchFavoriteComicsAction() {
	return {
		type: START_FETCH_FAVORITE_COMICS,
		categoryId: COMIC,
	};
}

export function fetchFavoriteComicsSuccessAction(comics) {
	return {
		type: FETCH_FAVORITE_COMICS_SUCCESS,
		comics,
	};
}

export function fetchFavoriteComicsFailedAction(error, errorMessage) {
	return {
		type: FETCH_FAVORITE_COMICS_FAILED,
		error,
		errorMessage,
	};
}

export function fetchComicsSearchedAction(query) {
	return {
		type: START_FETCH_COMICS_SEARCHED,
		categoryId: COMIC,
		query,
		limit: 30,
	};
}

export function fetchComicsSearchedSuccessAction(comics, numOfItems) {
	return {
		type: FETCH_COMICS_SEARCHED_SUCCESS,
		comics,
		numOfItems,
	};
}

export function fetchComicsSearchedFailedAction(error, errorMessage) {
	return {
		type: FETCH_COMICS_SEARCHED_FAILED,
		error,
		errorMessage,
	};
}
