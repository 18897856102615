import { actionTypes } from '../../../controller';
import { setJWT } from '../../../lib/jwt';

const { SET_JWT } = actionTypes;

// eslint-disable-next-line import/prefer-default-export
export function jwtMiddleware() {
	return next => action => {
		const { type, token } = action;

		if (type === SET_JWT) {
			setJWT(token);
		}

		next(action);
	};
}
