import actionTypes from './action-types';
import { CategoryEnum } from '../../lib/category-enum';
import { SortEnum } from '../../lib/enum';

const {
	START_FETCH_ADULT_LONG_VIDEOS_RECENTLY,
	FETCH_ADULT_LONG_VIDEOS_RECENTLY_SUCCESS,
	FETCH_ADULT_LONG_VIDEOS_RECENTLY_FAILED,

	START_FETCH_HOT_ADULT_LONG_VIDEOS,
	FETCH_HOT_ADULT_LONG_VIDEOS_SUCCESS,
	FETCH_HOT_ADULT_LONG_VIDEOS_FAILED,

	START_FETCH_FAVORITE_ADULT_LONG_VIDEOS,
	FETCH_FAVORITE_ADULT_LONG_VIDEOS_SUCCESS,
	FETCH_FAVORITE_ADULT_LONG_VIDEOS_FAILED,

	START_FETCH_ADULT_LONG_VIDEOS_SEARCHED,
	FETCH_ADULT_LONG_VIDEOS_SEARCHED_SUCCESS,
	FETCH_ADULT_LONG_VIDEOS_SEARCHED_FAILED,

	START_FETCH_ADULT_LONG_VIDEOS_RELATED,
	FETCH_ADULT_LONG_VIDEOS_RELATED_SUCCESS,
	FETCH_ADULT_LONG_VIDEOS_RELATED_FAILED,

	START_FETCH_ADULT_LONG_VIDEOS_RECOMMENDED,
	FETCH_ADULT_LONG_VIDEOS_RECOMMENDED_SUCCESS,
	FETCH_ADULT_LONG_VIDEOS_RECOMMENDED_FAILED,
} = actionTypes;

const { LONG_VIDEO } = CategoryEnum;
const { MONTHLY } = SortEnum;

export function fetchAdultLongVideosRecentlyAction() {
	return {
		type: START_FETCH_ADULT_LONG_VIDEOS_RECENTLY,
		categoryId: LONG_VIDEO,
		limit: 20,
	};
}

export function fetchAdultLongVideosRecentlySuccessAction(videos) {
	return {
		type: FETCH_ADULT_LONG_VIDEOS_RECENTLY_SUCCESS,
		videos,
	};
}

export function fetchAdultLongVideosRecentlyFailedAction(error, errorMessage) {
	return {
		type: FETCH_ADULT_LONG_VIDEOS_RECENTLY_FAILED,
		error,
		errorMessage,
	};
}

export function fetchHotAdultLongVideosAction() {
	return {
		type: START_FETCH_HOT_ADULT_LONG_VIDEOS,
		categoryId: LONG_VIDEO,
		sort: MONTHLY,
		limit: 20,
	};
}

export function fetchHotAdultLongVideosSuccessAction(videos) {
	return {
		type: FETCH_HOT_ADULT_LONG_VIDEOS_SUCCESS,
		videos,
	};
}

export function fetchHotAdultLongVideosFailedAction(error, errorMessage) {
	return {
		type: FETCH_HOT_ADULT_LONG_VIDEOS_FAILED,
		error,
		errorMessage,
	};
}

export function fetchFavoriteAdultLongVideosAction() {
	return {
		type: START_FETCH_FAVORITE_ADULT_LONG_VIDEOS,
		categoryId: LONG_VIDEO,
	};
}

export function fetchFavoriteAdultLongVideosSuccessAction(videos) {
	return {
		type: FETCH_FAVORITE_ADULT_LONG_VIDEOS_SUCCESS,
		videos,
	};
}

export function fetchFavoriteAdultLongVideosFailedAction(error, errorMessage) {
	return {
		type: FETCH_FAVORITE_ADULT_LONG_VIDEOS_FAILED,
		error,
		errorMessage,
	};
}

export function fetchAdultLongVideosSearchedAction(query) {
	return {
		type: START_FETCH_ADULT_LONG_VIDEOS_SEARCHED,
		categoryId: LONG_VIDEO,
		query,
		limit: 30,
	};
}

export function fetchAdultLongVideosSearchedSuccessAction(videos, numOfItems) {
	return {
		type: FETCH_ADULT_LONG_VIDEOS_SEARCHED_SUCCESS,
		videos,
		numOfItems,
	};
}

export function fetchAdultLongVideosSearchedFailedAction(error, errorMessage) {
	return {
		type: FETCH_ADULT_LONG_VIDEOS_SEARCHED_FAILED,
		error,
		errorMessage,
	};
}

export function fetchAdultLongVideosRelatedAction(actorName) {
	return {
		type: START_FETCH_ADULT_LONG_VIDEOS_RELATED,
		categoryId: LONG_VIDEO,
		limit: 10,
		actorName,
	};
}

export function fetchAdultLongVideosRelatedSuccessAction(videos) {
	return {
		type: FETCH_ADULT_LONG_VIDEOS_RELATED_SUCCESS,
		videos,
	};
}

export function fetchAdultLongVideosRelatedFailedAction(error, errorMessage) {
	return {
		type: FETCH_ADULT_LONG_VIDEOS_RELATED_FAILED,
		error,
		errorMessage,
	};
}

export function fetchAdultLongVideosRecommendedAction(videoId, limit, page) {
	return {
		type: START_FETCH_ADULT_LONG_VIDEOS_RECOMMENDED,
		videoId,
		limit: limit || 10,
		page,
	};
}

export function fetchAdultLongVideosRecommendedSuccessAction(videos, numOfItems) {
	return {
		type: FETCH_ADULT_LONG_VIDEOS_RECOMMENDED_SUCCESS,
		videos,
		numOfItems,
	};
}

export function fetchAdultLongVideosRecommendedFailedAction(error, errorMessage) {
	return {
		type: FETCH_ADULT_LONG_VIDEOS_RECOMMENDED_FAILED,
		error,
		errorMessage,
	};
}
