import { combineEpics } from 'redux-observable';
import * as authEpics from './auth-epics';
import * as userEpics from './user-epics';
import * as homeEpics from './home-epics';
import * as adultLongVideoEpics from './adult-long-video-epics';
import * as adultShortVideoEpics from './adult-short-video-epics';
import * as comicEpics from './comic-epics';
import * as tvEpics from './tv-epics';
import * as movieEpics from './movie-epics';
import * as videoTagEpic from './video-tag-epics';
import * as areaEpic from './area-epics';
import * as videoDetailEpic from './video-detail-epics';
import * as cdnEndpointEpic from './cdn-endpoint-epics';
import * as videoEpisodeEpic from './video-episode-epics';
import * as bannerEpic from './banner-epics';
import * as videoCategoryEpic from './video-category-epics';
import * as searchEpic from './search-epics';

const epics = combineEpics(
	...Object.values(authEpics),
	...Object.values(userEpics),
	...Object.values(homeEpics),
	...Object.values(adultLongVideoEpics),
	...Object.values(adultShortVideoEpics),
	...Object.values(comicEpics),
	...Object.values(tvEpics),
	...Object.values(movieEpics),
	...Object.values(videoTagEpic),
	...Object.values(areaEpic),
	...Object.values(videoDetailEpic),
	...Object.values(cdnEndpointEpic),
	...Object.values(videoEpisodeEpic),
	...Object.values(bannerEpic),
	...Object.values(videoCategoryEpic),
	...Object.values(searchEpic),
);

export default epics;
