const FinishStatusEnum = {
	FINISH: 'finished',
	UNFINISH: 'unfinished',
};

const OrderEnum = {
	ASC: 'asc',
	DESC: 'desc',
};

const SortEnum = {
	LAUNCHED: 'launchedAt',	// 最新更新
	DAILY: 'dailyViews',
	WEEKLY: 'weeklyViews',
	MONTHLY: 'monthlyViews',
	THREE_MONTHLY: 'treeMonthlyViews',	// 90日排行
	YEAR: 'yearViews',
};

const SortOptions = [
	{ name: '最近更新', value: SortEnum.LAUNCHED },
	{ name: '周排行', value: SortEnum.WEEKLY },
	{ name: '月排行', value: SortEnum.MONTHLY },
];

export {
	FinishStatusEnum,
	OrderEnum,
	SortEnum,
	SortOptions,
};
