import { ofType } from 'redux-observable';
import {
	switchMap,
	map,
	catchError,
	mergeMap,
} from 'rxjs/operators';
import {
	rxjsApiFetcherWithoutAuthorization,
} from '../../../lib/api-utils';
import {
	actionTypes,
	authActions,
	notifyActions,
} from '../../../controller';
import {
	catchErrorForEpics,
} from '../../../../lib/epic-utils';

const {
	START_CHECK_AUTH,
} = actionTypes;
const {
	checkAuthSuccessAction,
	checkAuthFailedAction,
	setJWTAction,
} = authActions;
const { notifyErrorAction } = notifyActions;

// eslint-disable-next-line import/prefer-default-export
export function checkAuthEpic(action$) {
	return action$.pipe(
		ofType(START_CHECK_AUTH),
		switchMap(({ body }) =>
			rxjsApiFetcherWithoutAuthorization
				.post('/login', body)
				.pipe(
					map(payload => payload.response),
					mergeMap(({ token }) => [
						setJWTAction(token),
						checkAuthSuccessAction(token),
					]),
					catchError(error => catchErrorForEpics(error, checkAuthFailedAction, notifyErrorAction)),
				),
		),
	);
}
