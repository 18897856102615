class Success {
	constructor(message) {
		this.message = message;
		this.name = 'Success';
	}
}

module.exports = {
	Success,
};
