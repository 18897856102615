import React from 'react';
import 'antd/dist/antd.min.css';
import 'ljit-react-components/dest/index.css';

import LayoutRoute from './layout-route';
import AuthRoute from './auth-route';
import './styling/layout/style.styl';

const App = () => (
	<AuthRoute
		render={auth => <LayoutRoute auth={auth}/>}
	/>
);

export default App;
