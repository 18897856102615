import { ofType } from 'redux-observable';
import {
	map,
	switchMap,
	catchError,
} from 'rxjs/operators';

import { actionTypes, comicActions, notifyActions } from '../../../controller';
import { rxjsApiFetcher } from '../../../lib/api-utils';
import { catchErrorForEpics } from '../../../../lib/epic-utils';
import { objectFilter, objectFilterOptionEnums } from '../../../../lib/object-utils';

const { SKIP_EMPTY_STRING } = objectFilterOptionEnums;

const {
	START_FETCH_COMICS_RECENTLY,
	START_FETCH_HOT_COMICS,
	START_FETCH_FAVORITE_COMICS,
	START_FETCH_COMICS_SEARCHED,
} = actionTypes;
const {
	fetchComicsRecentlySuccessAction, fetchComicsRecentlyFailedAction,
	fetchHotComicsSuccessAction, fetchHotComicsFailedAction,
	fetchFavoriteComicsSuccessAction, fetchFavoriteComicsFailedAction,
	fetchComicsSearchedSuccessAction, fetchComicsSearchedFailedAction,
} = comicActions;
const {
	notifyErrorAction,
} = notifyActions;

export function fetchComicsRecentlyEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_COMICS_RECENTLY),
		switchMap(({ categoryId, limit }) =>
			rxjsApiFetcher
				.get('/videos', { queries: {
					categoryId,
					limit,
				} })
				.pipe(
					map(payload => payload.response),
					map(({ data }) => fetchComicsRecentlySuccessAction(data)),
					catchError(error => catchErrorForEpics(error, fetchComicsRecentlyFailedAction, notifyErrorAction)),
				),
		),
	);
}

export function fetchHotComicsEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_HOT_COMICS),
		switchMap(({ categoryId, sort, limit }) =>
			rxjsApiFetcher
				.get('/videos', { queries: {
					categoryId,
					sort,
					limit,
				} })
				.pipe(
					map(payload => payload.response),
					map(({ data }) => fetchHotComicsSuccessAction(data)),
					catchError(error => catchErrorForEpics(error, fetchHotComicsFailedAction, notifyErrorAction)),
				),
		),
	);
}

export function fetchFavoriteComicsEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_FAVORITE_COMICS),
		switchMap(({ categoryId }) =>
			rxjsApiFetcher
				.get('/users/id=me/favorite-videos', { queries: {
					details: 1,
					categoryId,
				} })
				.pipe(
					map(payload => fetchFavoriteComicsSuccessAction(payload.response)),
					catchError(error => catchErrorForEpics(error, fetchFavoriteComicsFailedAction, notifyErrorAction)),
				),
		),
	);
}

export function fetchComicsSearchedEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_COMICS_SEARCHED),
		switchMap(({ categoryId, query, limit }) => {
			const { sort, tagId, page } = query;

			return rxjsApiFetcher
				.get('/videos', {
					queries: objectFilter(
						{
							categoryId,
							sort,
							tagId,
							page,
							limit,
						},
						[SKIP_EMPTY_STRING],
					),
				})
				.pipe(
					map(payload => payload.response),
					map(({ data, numOfItems }) => fetchComicsSearchedSuccessAction(data, numOfItems)),
					catchError(error => catchErrorForEpics(error, fetchComicsSearchedFailedAction, notifyErrorAction)),
				);
		}),
	);
}
