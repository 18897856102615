import actionTypes from './action-types';
import { CategoryEnum } from '../../lib/category-enum';

const {
	START_FETCH_HOME_LONG_VIDEOS,
	FETCH_HOME_LONG_VIDEOS_SUCCESS,
	FETCH_HOME_LONG_VIDEOS_FAILED,

	START_FETCH_HOME_SHORT_VIDEOS,
	FETCH_HOME_SHORT_VIDEOS_SUCCESS,
	FETCH_HOME_SHORT_VIDEOS_FAILED,

	START_FETCH_HOME_COMICS,
	FETCH_HOME_COMICS_SUCCESS,
	FETCH_HOME_COMICS_FAILED,

	START_FETCH_HOME_TVS,
	FETCH_HOME_TVS_SUCCESS,
	FETCH_HOME_TVS_FAILED,

	START_FETCH_HOME_MOVIES,
	FETCH_HOME_MOVIES_SUCCESS,
	FETCH_HOME_MOVIES_FAILED,
} = actionTypes;

const {
	LONG_VIDEO,
	SHORT_VIDEO,
	MOVIE,
	TV,
	COMIC,
} = CategoryEnum;

export function fetchHomeLongVideosAction() {
	return {
		type: START_FETCH_HOME_LONG_VIDEOS,
		categoryId: LONG_VIDEO,
		limit: 10,
	};
}

export function fetchHomeLongVideosSuccessAction(videos) {
	return {
		type: FETCH_HOME_LONG_VIDEOS_SUCCESS,
		videos,
	};
}

export function fetchHomeLongVideosFailedAction(error, errorMessage) {
	return {
		type: FETCH_HOME_LONG_VIDEOS_FAILED,
		error,
		errorMessage,
	};
}

export function fetchHomeShortVideosAction() {
	return {
		type: START_FETCH_HOME_SHORT_VIDEOS,
		categoryId: SHORT_VIDEO,
		limit: 10,
	};
}

export function fetchHomeShortVideosSuccessAction(videos) {
	return {
		type: FETCH_HOME_SHORT_VIDEOS_SUCCESS,
		videos,
	};
}

export function fetchHomeShortVideosFailedAction(error, errorMessage) {
	return {
		type: FETCH_HOME_SHORT_VIDEOS_FAILED,
		error,
		errorMessage,
	};
}

export function fetchHomeComicsAction() {
	return {
		type: START_FETCH_HOME_COMICS,
		categoryId: COMIC,
		limit: 5,
	};
}

export function fetchHomeComicsSuccessAction(comics) {
	return {
		type: FETCH_HOME_COMICS_SUCCESS,
		comics,
	};
}

export function fetchHomeComicsFailedAction(error, errorMessage) {
	return {
		type: FETCH_HOME_COMICS_FAILED,
		error,
		errorMessage,
	};
}

export function fetchHomeTvsAction() {
	return {
		type: START_FETCH_HOME_TVS,
		categoryId: TV,
		limit: 10,
	};
}

export function fetchHomeTvsSuccessAction(tvs) {
	return {
		type: FETCH_HOME_TVS_SUCCESS,
		tvs,
	};
}

export function fetchHomeTvsFailedAction(error, errorMessage) {
	return {
		type: FETCH_HOME_TVS_FAILED,
		error,
		errorMessage,
	};
}

export function fetchHomeMoviesAction() {
	return {
		type: START_FETCH_HOME_MOVIES,
		categoryId: MOVIE,
		limit: 10,
	};
}

export function fetchHomeMoviesSuccessAction(movies) {
	return {
		type: FETCH_HOME_MOVIES_SUCCESS,
		movies,
	};
}

export function fetchHomeMoviesFailedAction(error, errorMessage) {
	return {
		type: FETCH_HOME_MOVIES_FAILED,
		error,
		errorMessage,
	};
}
