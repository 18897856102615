import { ofType } from 'redux-observable';
import {
	map,
	switchMap,
	catchError,
} from 'rxjs/operators';

import { actionTypes, movieActions, notifyActions } from '../../../controller';
import { rxjsApiFetcher } from '../../../lib/api-utils';
import { catchErrorForEpics } from '../../../../lib/epic-utils';
import { objectFilter, objectFilterOptionEnums } from '../../../../lib/object-utils';

const { SKIP_EMPTY_STRING } = objectFilterOptionEnums;

const {
	START_FETCH_MOVIES_RECENTLY,
	START_FETCH_HOT_MOVIES,
	START_FETCH_FAVORITE_MOVIES,
	START_FETCH_MOVIES_SEARCHED,
	START_FETCH_MOVIES_RECOMMENDED,
} = actionTypes;
const {
	fetchMoviesRecentlySuccessAction, fetchMoviesRecentlyFailedAction,
	fetchHotMoviesSuccessAction, fetchHotMoviesFailedAction,
	fetchFavoriteMoviesSuccessAction, fetchFavoriteMoviesFailedAction,
	fetchMoviesSearchedSuccessAction, fetchMoviesSearchedFailedAction,
	fetchMoviesRecommendedSuccessAction, fetchMoviesRecommendedFailedAction,
} = movieActions;
const {
	notifyErrorAction,
} = notifyActions;

export function fetchMoviesRecentlyEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_MOVIES_RECENTLY),
		switchMap(({ categoryId, limit }) =>
			rxjsApiFetcher
				.get('/videos', { queries: {
					categoryId,
					limit,
				} })
				.pipe(
					map(payload => payload.response),
					map(({ data }) => fetchMoviesRecentlySuccessAction(data)),
					catchError(error => catchErrorForEpics(error, fetchMoviesRecentlyFailedAction, notifyErrorAction)),
				),
		),
	);
}

export function fetchHotMoviesEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_HOT_MOVIES),
		switchMap(({ categoryId, sort, limit }) =>
			rxjsApiFetcher
				.get('/videos', { queries: {
					categoryId,
					sort,
					limit,
				} })
				.pipe(
					map(payload => payload.response),
					map(({ data }) => fetchHotMoviesSuccessAction(data)),
					catchError(error => catchErrorForEpics(error, fetchHotMoviesFailedAction, notifyErrorAction)),
				),
		),
	);
}

export function fetchFavoriteMoviesEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_FAVORITE_MOVIES),
		switchMap(({ categoryId }) =>
			rxjsApiFetcher
				.get('/users/id=me/favorite-videos', { queries: {
					details: 1,
					categoryId,
				} })
				.pipe(
					map(payload => fetchFavoriteMoviesSuccessAction(payload.response)),
					catchError(error => catchErrorForEpics(error, fetchFavoriteMoviesFailedAction, notifyErrorAction)),
				),
		),
	);
}

export function fetchMoviesSearchedEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_MOVIES_SEARCHED),
		switchMap(({ categoryId, query, limit }) => {
			const { sort, tagId, areaId, page } = query;

			return rxjsApiFetcher
				.get('/videos', {
					queries: objectFilter(
						{
							categoryId,
							sort,
							tagId,
							areaId,
							page,
							limit,
						},
						[SKIP_EMPTY_STRING],
					),
				})
				.pipe(
					map(payload => payload.response),
					map(({ data, numOfItems }) => fetchMoviesSearchedSuccessAction(data, numOfItems)),
					catchError(error => catchErrorForEpics(error, fetchMoviesSearchedFailedAction, notifyErrorAction)),
				);
		}),
	);
}

export function fetchMoviesRecommendedEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_MOVIES_RECOMMENDED),
		switchMap(({ videoId, limit, page }) =>
			rxjsApiFetcher
				.get(`/videos/id=${videoId}/recommends`, { queries: {
					limit,
					page,
				} })
				.pipe(
					map(({ response }) => fetchMoviesRecommendedSuccessAction(response.data, response.numOfItems)),
					catchError(error => catchErrorForEpics(error, fetchMoviesRecommendedFailedAction, notifyErrorAction)),
				)),
	);
}
