import actionTypes from './action-types';

const {
	RESET_AUTH,

	START_CHECK_AUTH,
	CHECK_AUTH_SUCCESS,
	CHECK_AUTH_FAILED,

	SET_JWT,
} = actionTypes;

export function resetAuthAction() {
	return {
		type: RESET_AUTH,
	};
}

export function checkAuthAction({ userProfile, username, signature }) {
	return {
		type: START_CHECK_AUTH,
		body: {
			u: userProfile,
			n: username,
			s: signature,
		},
	};
}

export function checkAuthSuccessAction(token) {
	return {
		type: CHECK_AUTH_SUCCESS,
		token,
	};
}

export function checkAuthFailedAction(error, errorMessage) {
	return {
		type: CHECK_AUTH_FAILED,
		error,
		errorMessage,
	};
}

export function setJWTAction(token) {
	return {
		type: SET_JWT,
		token,
	};
}
