import { List, Map } from 'immutable';
import { LoadingStatusEnum } from '../../../../../lib/enums';
import { actionTypes } from '../../../../controller';

const {
	START_FETCH_CDN_ENDPOINTS,
	FETCH_CDN_ENDPOINTS_SUCCESS,
	FETCH_CDN_ENDPOINTS_FAILED,
} = actionTypes;

const {
	NONE,
	LOADING,
	SUCCESS,
	FAILED,
} = LoadingStatusEnum;

// Example
// Map({
// 	cdnEndpoints: List([
// 		{
// 			"id": "5c209377284225c33556be50",
// 			"displyaName": "線路1",
// 			"name": "亚马逊",
// 			"code": "aws",
// 			"url": "http://cdn2.eve568.com",
// 			"status" : "default"
// 		}, {
// 			"id": "5c209377284225c33556be51",
// 			"displyaName": "",
// 			"name": "创宇云",
// 			"code": "yunaq",
// 			"url": "http://cdn3.eve568.com",
// 			"status" : "online",
// 		},
// 	]),
// });

const initalState = Map({
	cdnEndpoints: List([]),
	cdnEndpointsLoadingStatus: NONE,
	cdnEndpointsLoadingStatusMessage: '',
});

export default function cdnEndpoint(state = initalState, action) {
	switch (action.type) {
		case START_FETCH_CDN_ENDPOINTS:
			return state.set('cdnEndpointsLoadingStatus', LOADING);

		case FETCH_CDN_ENDPOINTS_SUCCESS:
			return state
				.set('cdnEndpoints', List(action.cdnEndpoints))
				.set('cdnEndpointsLoadingStatus', SUCCESS);

		case FETCH_CDN_ENDPOINTS_FAILED:
			return state
				.set('cdnEndpointsLoadingStatus', FAILED)
				.set('cdnEndpointsLoadingStatusMessage', action.errorMessage);

		default:
			return state;
	}
}
