import { ofType } from 'redux-observable';
import {
	map,
	switchMap,
	catchError,
} from 'rxjs/operators';

import { actionTypes, cdnEndpointActions, notifyActions } from '../../../controller';
import { rxjsApiFetcher } from '../../../lib/api-utils';
import { catchErrorForEpics } from '../../../../lib/epic-utils';

const {
	START_FETCH_CDN_ENDPOINTS,
} = actionTypes;
const {
	fetchCDNEndpointSuccessAction, fetchCDNEndpointFailedAction,
} = cdnEndpointActions;
const {
	notifyErrorAction,
} = notifyActions;

// eslint-disable-next-line import/prefer-default-export
export function fetchCdnEndpointEpic($action) {
	return $action.pipe(
		ofType(START_FETCH_CDN_ENDPOINTS),
		switchMap(() =>
			rxjsApiFetcher
				.get('/cdn-endpoints')
				.pipe(
					map(payload => fetchCDNEndpointSuccessAction(payload.response)),
					catchError(error => catchErrorForEpics(error, fetchCDNEndpointFailedAction, notifyErrorAction)),
				),
		),
	);
}
