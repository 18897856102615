import actionTypes from './action-types';

const {
	START_FETCH_VIDEO_DETAIL,
	FETCH_VIDEO_DETAIL_SUCCESS,
	FETCH_VIDEO_DETAIL_FAILED,
	CLEAR_VIDEO_DETAIL,
} = actionTypes;

export function fetchVideoDetailAction(videoId) {
	return {
		type: START_FETCH_VIDEO_DETAIL,
		videoId,
	};
}

export function fetchVideoDetailSuccessAction(detail) {
	return {
		type: FETCH_VIDEO_DETAIL_SUCCESS,
		detail,
	};
}

export function fetchVideoDetailFailedAction(error, errorMessage) {
	return {
		type: FETCH_VIDEO_DETAIL_FAILED,
		error,
		errorMessage,
	};
}

export function clearVideoDetailAction() {
	return {
		type: CLEAR_VIDEO_DETAIL,
	};
}
