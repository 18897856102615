import React from 'react';
import PropTypes from 'prop-types';
import VideoHubLogo from '../../../../assets/logo/logo.png';
import VideoHubLogo2x from '../../../../assets/logo/logo@2x.png';
import VideoHubLogo3x from '../../../../assets/logo/logo@3x.png';

const propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,
};

const NavLogo = ({
	className,
	onClick,
	...restProps
}) => (
	<div className={className} onClick={onClick}>
		<img
			src={VideoHubLogo}
			srcSet={`${VideoHubLogo} 1x, ${VideoHubLogo2x} 2x, ${VideoHubLogo3x} 3x,`}
			alt="logo"
			{...restProps}
		/>
	</div>
);

NavLogo.propTypes = propTypes;

export default NavLogo;
